export default {
    meau: {
      nav1: 'Домашняя страница',
      nav2: 'Сообщество',
      nav3: 'Скачать',
      nav4: 'Награда узла',
      nav5: 'NFT',
      nav6: 'Internet Cafe',
      nav7: 'GameFi',
    },
    cont1: {
      text1: 'Blockchain на основе программного обеспечения для удаленного управления',
      text2: 'Возможность подключения нескольких экранов для работы и игр в любое время и в любом месте',
      btn: 'Скачать сейчас',
      text3_1: 'Присоединиться',
      text3_2: 'сообщество',
      text3_3: 'чтобы получить награды DeepLink Coin!'
    },
    cont2: {
      text1: 'DeepLink',
      text2: 'Положитесь на возможности игровых технологий с малой задержкой, чтобы все устройства были в пределах досягаемости.',
      text3: 'Основные компетенции и технические преимущества',
      text4: 'Основываясь на основных возможностях облачных игр, опыт намного превосходит традиционное дистанционное управление!',
      leaf1: {
        text1: 'Очень плавный, высокая производительность',
        text2: 'Бюджетный',
        text3: 'Ведущее в отрасли решение для кодеков с минимальным потреблением производительности и ресурсов при той же скорости кодирования',
        text4: 'одноранговая связь: поддерживает ipv6 с пропускной способностью NAT до 95% и нулевым использованием полосы пропускания.'
      },
      leaf2: {
        text1: 'Высокая частота кадров, низкая задержка',
        text2: '',
        text3: "С задержкой LAN <15 мс и поддержкой 4K 240 Гц обзор WAN также находится на высшем уровне в мире."
      },
      leaf3: {
        text1: 'Высокое разрешение',
        text2: '',
        text3: 'Интеллектуальные алгоритмы улучшают просмотр с той же скоростью передачи данных и восстанавливают наиболее реалистичные цветовые характеристики.'
      },
      leaf4: {
        text1: 'Высокая стабильность',
        text2: '',
        text3: 'Стратегии предотвращения потери пакетов и предотвращения перегрузки, поддерживаемые большими объемами данных, позволяют быстро реагировать на потерю пакетов данных и перегрузку.'
      },
      leaf5: {
        text1: 'Высокая безопасность',
        text2: '',
        text3: 'Все данные шифруются DTLS1.2 с AES256.'
      }
    },
    cont3: {
      text1: "Так гладко, как будто управляешь локальным устройством",
      text2: 'Применяя десятки методов оптимизации в трех основных областях, DeepLink способен поддерживать высокую частоту кадров, несмотря на 5%-ную потерю пакетов и задержку в сети 100 мс*. Соответствие требованиям всех типов сетевых сред.',
      li1: {
        text1: 'Слабая технология противодействия сети',
        text2: 'Стратегия FEC против потери упаковки',
        text3: 'Прогноз пропускной способности',
        text4: 'Стратегия кодирования SVC'
      },
      li2: {
        text1: 'Методы оптимизации передачи',
        text2: 'Прогнозирование отпечатков портов',
        text3: 'Поддерживает IPV6',
        text4: 'UPNP',
        text5: 'Интеллектуальная маршрутизация'
      },
      li3: {
        text1: 'Технология обработки дисплея',
        text2: 'Стратегия адаптации частоты кадров',
        text3: 'Предсказание кино',
        text4: 'Стратегия обработки джиттера',
        text5: 'Рендеринг HD с низким кодированием AI',
        text6: 'Динамическое программное обеспечение виртуальной реальности'
      },
      desc3: 'LAN < 5 мс - Междугородная сеть < 10 мс - Межобластная сеть < 30 мс'
    },
    cont4: {
      text1: 'Супер четкое качество графики',
      text2: 'Ультра HD-разрешение',
      text3: 'Поддерживается 1,07 миллиарда цветов',
      text4: '4:4:4 неиспорченное качество изображения',
      text5: "DeepLink всегда использует более требовательные показатели качества изображения для достижения качества изображения, которое намного превосходит другие традиционные удаленные технологии, с удаленным искажением изображения ниже предела распознавания человеком, что полностью поддерживает потребность творческой индустрии в точном отображении изображения.",
      text6: '*PSNR относится к пиковому отношению сигнал-шум, ниже 20 дБ искажение изображения высокое, 20–30 дБ — искажение изображения среднее, 30–40 дБ — искажение изображения низкое, выше 40 дБ — искажение изображения чрезвычайно низкое и незаметное для невооруженного глаза.'
    },
    cont5: {
      text1: 'Широкая периферийная поддержка',
      text2: 'DeepLink обеспечивает удаленную поддержку профессиональных аксессуаров, таких как цифровые доски и джойстики, для творческой индустрии, а также оптимизирует использование программного обеспечения для творчества, такого как PS, Zbrush и Unity, чтобы обеспечить единообразное использование инструментов творческого производства как в удаленных, так и в местных штатах.'
    },
    cont6: {
      text1: 'Множественные гарантии - минималистский подход',
      block1: {
        title1: 'Безопасные учетные записи',
        desc1: 'Многоуровневый механизм защиты аккаунта для вашего спокойствия',
        title2: 'Безопасное соединение',
        desc2: 'Защита протокола DTLS1.2, доступ только с авторизацией',
        title3: 'Безопасные данные',
        desc3: '256-битное шифрование AES, безопасное во время передачи',
        title4: 'Безопасные устройства',
        desc4: 'Только соответствующий человек увидит соответствующее устройство в соответствии с вашими настройками'
      },
      block2: {
        title1: 'Многочисленные гарантии безопасности',
        desc1: 'Защита каждого соединения',
        title2: 'Легко использовать',
        desc2: 'Легко начать'
      },
      block3: {
        title1: 'Легкий для скачивания',
        desc1: "Для клиентской части DeepLink это всего около 10 млн.",
        title2: 'Легко учить',
        desc2: 'Процесс настройки можно завершить, следуя инструкциям',
        title3: 'Легко использовать',
        desc3: 'Удаленное управление любым устройством в пределах ваших прав с помощью мыши'
      }
    },
    cont7: {
      text1: 'Предлагает решения для удаленного управления',
      block1: {
        word1: 'Образовательная индустрия',
        word2: 'Игровая индустрия',
        word3: 'Креативная и дизайнерская индустрия',
        word4: 'ИТ-индустрия',
        word5: 'Бизнесмены и женщины в пути',
        word6: 'Соавторы по всему миру',
        word7: 'Удаленное обслуживание IT O&M',
        word8: 'Отдельные пользователи и геймеры'
      },
      block2: {
        text1: 'Поддержка сценариев удаленного управления в различных отраслях',
        text2: 'Основываясь на реальных потребностях компаний в различных отраслях, мы помогаем им достичь своих бизнес-целей энергоэффективным способом.',
        text3: 'Предлагает политики персонализации и шифрования, гибкую систему безопасности',
        text4: "Сотрудники компании могут получить доступ к ресурсам компании в любое время и из любого места на основе их фактических разрешений."
      },
      block3: {
        text1: 'Охватывает широкий спектр потребностей пользователей',
        text2: 'Независимо от того, решите ли вы использовать его для своего внутреннего персонала,',
        text3: 'или для внешних клиентов, сотрудников или даже для личного использования.',
        text4: 'Наши возможности дистанционного управления могут служить вам на основных платформах и устройствах,',
        text5: 'предлагая альтернативный, простой и умный способ решения проблем.'
      }
    },
    footer: {
      link: 'Узнать больше о',
      community: 'Следите за нашим сообществом',
      tip: 'Наши сообщества в: Twitter, Telegram, Reddit, Medium, Discord, TikTok, YouTube.'
    },
    reward: {
      title1: 'Узел (узлы) DeepLink',
      desc1: 'Иметь доступ к наградам, например. Токен DeepLink, Crown NFT, сила управления сообществом.',
      title2: 'Получить Genesis Node NFT',
      text1: "Чем больше пользователей в сети DeepLink, тем больше требуется вычислений и трафика. Узлы Genesis предоставляют инфраструктуру для сети DeepLink, тем самым децентрализуя и помогая DeepLink расти быстрее.",
      text2: 'Приобретая Genesis Node NFT, вы получаете возможность получить вознаграждение за свой вклад в сеть.',
      text3: 'Это может включать в себя токен DeepLink, Crown NFT и возможности управления сообществом.',
      text4_1: 'Есть вопросы? Приходите поговорить с нами на',
      text4_2: 'и',
      text4_3: '',
      btntext: 'Купи сейчас',
      text5: 'На каждые 1000 проданных Genesis Nodes NFT цена увеличивается на 1000 долларов США.',
      text6_1: 'Количество узлов',
      text6_2: 'каждый NFT поддерживает в настоящее время',
      text7: 'Общее количество узлов Genesis NFT',
      text8: 'Выпуск ограничен навсегда',
      text9: 'Начальная цена',
      text10_1: 'Максимальное количество узлов',
      text10_2: 'Поддерживается по NFT',
      text11: 'Максимальная цена',
      text12: 'Что такое узел Genesis?',
      text13: 'Узлы Genesis выполняют основные задачи сетевых вычислений и переадресации трафика DeepLink, а DeepLink позволяет большему количеству людей участвовать в получении дохода с помощью модели майнинга.',
      text14: 'Сколько всего токенов было дано узлам Genesis в качестве вознаграждения?',
      text15: '30% от общего количества токенов, т.е. 30 миллиардов токенов DeepLink',
      text16: 'Что такое Genesis Node NFT',
      text17: 'Участвовать в майнинге могут только те, у кого есть Genesis Node NFT.',
      text18: 'Как распределяется доход от продажи Genesis Node NFT?',
      text19: '0% выручки инвестируются в децентрализованные пулы вознаграждения за ликвидность (например, токен DeepLink/USDC для Uniswap, Sushiswap), а 50% поступает в операционную компанию DeepLink в качестве выручки.',
      text20: 'Каков конкретный механизм майнинга?',
      text21: 'Общее вознаграждение составляет 5 миллиардов долларов в год, уменьшается вдвое каждые 3 года, 10% дохода DeepLink Crown NFT используется для выкупа токенов DeepLink, которые добавляются к общему пулу вознаграждений перед каждым делением пополам в рамках 50% вознаграждения. Токен, присуждаемый на все узлы за 3 года. Например, через 3 года 10% доходов DeepLink Crown NFT могут выкупить 12 миллиардов токенов DeepLink, тогда общий оставшийся пул вознаграждений станет: 12 миллиардов + 15 миллиардов, всего 27 миллиардов, и общее годовое вознаграждение от вторых 3 -лет составляет 27 миллиардов * 50% * 1/3 = 4,5 миллиарда.',
      text22: 'Каковы требования к узлам Genesis?',
      text23: "Каждая машина уже должна быть в сети DeepBrain Chain. DeepBrain Chain — это децентрализованная высокопроизводительная вычислительная сеть, и машины в DeepBrain Chain заложили DBC, что может гарантировать стабильность машин. Для получения дополнительной информации о цепочке DeepBrain:",
      text24: 'Каковы другие преимущества владения Genesis Node NFT?',
      details: {
        text1: "1. Владение определенным количеством NFT Genesis Node дает вам общее право агентства продавать NFT DeepLink в стране (1 NFT необходим на каждые 3 миллиона человек в соответствии с долей населения страны);",
        text2: '2. Раздача монет DeepLink;',
        text3: '3. Раздача токенов инкубируемого игрового проекта DeepLink;',
        text4: '4. Право голосовать за то, какие игры будут запущены онлайн;',
        text5: '5. Скидка на покупку игры NFT;',
        text6: '6. Скидка на покупку прав и часов на облачную игру;',
        text7: '7. Другие права на участие в управлении сообществом.'
      },
      question: {
        text1_1: 'Что такое узел Genesis?',
        text1_2: 'Узлы Genesis выполняют основные задачи сетевых вычислений и переадресации трафика DeepLink, а DeepLink позволяет большему количеству людей участвовать в получении дохода с помощью модели майнинга.',
        text2_1: 'Сколько всего токенов было дано узлам Genesis в качестве вознаграждения?',
        text2_2: '10% от общего количества токенов, т.е. 10 миллиардов токенов DeepLink',
        text3_1: 'Что такое Genesis Node NFT',
        text3_2: 'Участвовать в майнинге могут только те, у кого есть Genesis Node NFT.',
        text4_1: 'Как распределяется доход от продажи Genesis Node NFT?',
        text4_2: '0% выручки инвестируются в децентрализованные пулы вознаграждения за ликвидность (например, токен DeepLink/USDC для Uniswap, Sushiswap), а 50% поступает в операционную компанию DeepLink в качестве выручки.',
        text5_1: 'Каков конкретный механизм майнинга?',
        text5_2: 'Общее вознаграждение составляет 5 миллиардов долларов в год, уменьшается вдвое каждые 3 года, 10% дохода DeepLink Crown NFT используется для выкупа токенов DeepLink, которые добавляются к общему пулу вознаграждений перед каждым делением пополам в рамках 50% вознаграждения. Токен, присуждаемый на все узлы за 3 года. Например, через 3 года 10% доходов DeepLink Crown NFT могут выкупить 12 миллиардов токенов DeepLink, тогда общий оставшийся пул вознаграждений станет: 12 миллиардов + 15 миллиардов, всего 27 миллиардов, и общее годовое вознаграждение от вторых 3 -лет составляет 27 миллиардов * 50% * 1/3 = 4,5 миллиарда.',
        text6_1: 'Каковы требования к узлам Genesis?',
        text6_2: "Каждая машина уже должна быть в сети DeepBrain Chain. DeepBrain Chain — это децентрализованная высокопроизводительная вычислительная сеть, и машины в DeepBrain Chain заложили DBC, что может гарантировать стабильность машин. Для получения дополнительной информации о цепочке DeepBrain:",
        text7_1: 'Каковы другие преимущества владения Genesis Node NFT?',
        text7_2: `1. Владение определенным количеством NFT Genesis Node дает вам общее право агентства продавать NFT DeepLink в стране (1 NFT необходим на каждые 3 миллиона человек в соответствии с долей населения страны);2. Раздача монет DeepLink;3. Раздача токенов инкубируемого игрового проекта DeepLink;
        4. Право голосовать за то, какие игры будут запущены онлайн;5. Скидка на покупку игры NFT;6. Скидка на покупку прав и часов на облачную игру;7. Другие права на участие в управлении сообществом.`,
      }
    },
    nft: {
      cont: {
        title1: 'Корона NFT',
        desc1: 'С этим NFT вы можете получить 1 год доступа ко всем корпоративным функциям программного обеспечения DeepLink.',
        desc2: 'NFT неактивен после покупки, когда этот NFT необходимо использовать, его можно активировать в программном обеспечении DeepLink, когда NFT активирован',
        desc3: 'Начинается отсчет 1 года использования. 10% дохода NFT от программного обеспечения DeepLink инвестируется в вознаграждение за узлы,',
        // desc4: '40% выручки инвестируется в пул вознаграждений за ликвидность, а 50% — выручка операционной компании DeepLink.'
        desc4: '40% доходов будут уничтожены для увеличения стоимости токена DLC.'
      },
      enabl: {
        title1: 'NFT Права',
        // desc1: '1 год доступа ко всем корпоративным функциям программного обеспечения DeepLink',
        desc1: 'Иметь доступ к расширенным функциям программного обеспечения DeepLink',
        desc2: 'Раздача монет DeepLink',
        desc3: 'Раздача токенов инкубируемого игрового проекта DeepLink',
        desc4: 'Право голоса за то, какие игры будут запущены онлайн',
        desc5: 'Скидка на покупку игры NFT',
        desc6: 'Скидка при покупке прав на облачную игру и часов',
        desc7: 'Другие права на участие в управлении сообществом'
  
      },
      edition: {
        title1: 'Профессиональная версия',
        title2: 'Командное версия',
        tip: 'Корона NFT',
        btn: 'Купи сейчас',
        title3: 'Профессиональный VS Команды версия',
        desc: 'Доступ к определенным функциям',
        list: {
          li1_1: 'Содержание',
          li1_2: 'Базовая версия',
          li1_3: 'Профессиональная версия',
          li1_4: 'Команды версия',
          li2_1: 'Лучшее качество изображения',
          li3_1: 'Серверная комната переадресации',
          li3_2: 'Небольшое количество',
          li3_3: 'Множество',
          li4_1: 'Пропускная способность пересылки',
          li4_2: 'Максимум 4М',
          li4_3: 'Максимум 50M',
          li4_4: 'Неограниченный',
          li5_1: 'Сквозная задержка',
          li5_2: 'Минимум<40ms',
          li5_3: 'Минимум <20ms',
          li6_1:'Количество устройств, которые могут инициировать удаленное управление одновременно',
          li7_1:'Количество устройств',
          li8_1:'Удаленный рабочий стол',
          li9_1:'Удаленная камера',
          li10_1:'Удаленный SSH',
          li11_1:'Экран конфиденциальности',
          li12_1:'Виртуальный монитор',
          li13_1:'Игровая клавиатура',
          li14_1:'Многопоточность',
          li15_1:'444 настоящих цвета',
          li16_1:'Запись скриншотов',
          li17_1:'Двойной экран на двойной экран',
          li18_1:'Ручка',
          li19_1:'Управление группами пользователей/устройств',
          li20_1:'Единый вход SSO',
          li21_1:'Единая авторизация управления',
          li22_1:'Массовое развертывание',
          li23_1:'Аудит поведения сотрудников',
          li24_1:'Водяной знак',
          li25_1:'Цена',
          li26_1: 'Сеансы рабочего стола на одном компьютере',
          li27_1: 'передача файла',

          new_text1: 'Если один и тот же адрес кошелька вошел в систему сразу на нескольких устройствах, только одно из устройств может инициировать удаленное управление',
          new_text2: 'Машина может открыть только два окна с дистанционным управлением',
        }
      }
    },
    cloudInternet: {
      title: 'Как стать поставщиком облачного решения для интернет-кафе',
      text: 'В настоящее время основные страны распределения интернет-кафе по всему миру включают Южную Корею (6 000), Китай (40 000), Бразилию (10 000), Индонезию (20 000), Филиппины (20 000), Вьетнам (15 000), Турцию (5 000) и другие. Кроме того, есть такие страны, как Индия, Мексика, Ближний Восток, Южная Америка, Африка, Европа и другие. В мире более 150 000 интернет-кафе. Однако отрасль интернет-кафе имеет историю 20 лет и стала традиционной отраслью. Период окупаемости увеличился до 3-4 лет, и он сталкивается с различными рисками. Например, пандемия COVID-19 привела к закрытию большого числа интернет-кафе. Поэтому отрасль интернет-кафе нуждается в новом решении для сокращения периода окупаемости, снижения рисков и повышения эффективности управления. Таким образом, возникает облачное решение для интернет-кафе.',
      cont2: {
        text1: 'Что такое ',
        text2: 'решение облачного интернет-кафе?',
        text3: 'Решение облачного интернет-кафе - это новый тип модели обслуживания интернет-кафе с технологией рендеринга с ультранизкой задержкой. В облачном интернет-кафе игры и приложения не устанавливаются непосредственно на локальные компьютеры, а размещаются на облачных серверах с GPU. Пользователи получают доступ и играют в игры через облачные услуги, не требуя при этом высокопроизводительного локального оборудования.',
        block1: {
          text1: 'Снижение требований к локальному оборудованию',
          text2: 'Поскольку игры и приложения работают на облачных серверах с GPU, локальным компьютерам не требуется высокопроизводительных GPU или больших объемов хранения. Это снижает инвестиции в оборудование интернет-кафе и затраты на его обслуживание.'
        },
        block2: {
          text1: 'Мгновенный доступ к последним играм',
          text2: 'Пользователи могут мгновенно получать доступ к последним играм без ожидания загрузки и установки. Все обновления и обслуживание игр проводятся в облаке, обеспечивая пользователям удобство и эффективность.'
        },
        block3: {
          text1: 'Игровой опыт высокой производительности',
          text2: 'Локальные мониторы нужно только оборудовать мини-ПК (около 100 долларов), и облачные интернет-кафе могут обеспечить высокопроизводительный игровой опыт. Это потому, что вычислительная работа игры выполняется на удаленных высокопроизводительных серверах с GPU.'
        },
        block4: {
          text1: 'Экономия места и энергии',
          text2: 'Компьютеры с низкой конфигурацией занимают меньше места и потребляют меньше энергии, что помогает снизить операционные расходы интернет-кафе.'
        },
        block5: {
          text1: 'Простое управление и обслуживание',
          text2: 'Администраторы интернет-кафе могут легко управлять программным обеспечением и играми, потому что вся информация централизованно управляется в облаке. Это снижает локальную обслуживание и потенциальные технические проблемы.'
        }
      },
      cont3: {
        text1: 'Cloud Cybercafe Glowsteam в Южной Корее',
        text2: 'Фактическая рабочая среда облачного киберкафе'
      },
      cont4: {
        text1: 'Решение облачного киберкафе может помочь инвесторам киберкафе',
        text2: 'Увеличить чистую прибыль в 3 раза и сократить период окупаемости на 40%',
        text3: 'Нажмите, чтобы скачать сравнительные данные'
      },
      cont5: {
        text1: 'Какова прибыль для поставщиков решений облачного киберкафе?',
        text2: 'Если прибыль рассчитывается как 12% от доходов с одной киберкафе-машины, обслуживая в общей сложности 1000 киберкафе с 100 000 машинами, каждая машина используется в среднем 10 часов в день, и средняя стоимость одной машины в час составляет 0,8 доллара, то 12% примерно равно 0,1 доллара.',
        text3: 'Годовая прибыль поставщика решений облачного киберкафе составляет',
        text4: '100 000 * 10 * 0,1 * 365 = 36,5 миллиона долларов',
        text5: 'Как стать поставщиком решений облачного киберкафе',
        text6: 'На основе технологий DeepBrainChain и DeepLink южнокорейская компания HYCONS разработала полное решение для облачного киберкафе. DeepBrainChain предоставляет вычислительную мощность GPU, DeepLink предлагает технологию рендеринга с ультранизкой задержкой, а HYCONS предоставляет всё решение. Аналогично, если сравнить с автомобилем, DeepBrainChain предоставляет двигатель, DeepLink предоставляет автономную систему вождения, а HYCONS предоставляет полное решение для автомобиля. Местные поставщики решений в разных странах должны только интегрировать свои локальные платежные системы в HYCONS, чтобы предоставить услуги местным киберкафе, и поставщики решений могут устанавливать собственные бренды. HYCONS действует только как технологический сервис-провайдер без раскрытия своего бренда.',
        text7: 'Для получения дополнительной информации, пожалуйста, свяжитесь с:',
        text8: "service{'@'}deepbrainchain.org"
      },
      cont6: {
        title: 'Скачать связанные с облачным киберкафе материалы',
        text1: 'Введение в HYCONS',
        text2: 'Анализ прибыли от решения облачного киберкафе',
        text3: 'Диаграмма архитектуры центра обработки данных облачного киберкафе',
        text4: 'Руководство по тестированию и установке решения облачного киберкафе',
        text5: 'Введение в протокол DeepLink'
      }
    },
    reward2: {
      desc: {
        title1: 'Узлы DeepLink',
        text1: 'Только владельцы узлов могут участвовать в майнинге трафика CPU и майнинге на персональных GPU машинах. Общая награда составляет 12% токенов DLC, из которых 4% предназначено для узлов трафика CPU, и 8% для узлов персональных GPU. Общее количество токенов составляет 12 миллиардов. Производительность каждого узла зависит от фактического количества проданных узлов. Если все узлы проданы, каждый узел произведет 100,000 DLC. Если продано только 10% узлов, каждый узел произведет 1 миллион DLC.',
        title2: 'Общее количество узлов: 120,000 дополнительная эмиссия не предусмотрена',
        text2: 'Каждый узел получает дополнительный аирдроп',
        text3: 'Минимальное количество DLC, добываемое каждым узлом',
        text4: 'Начальная цена',
        text5: 'Максимальная цена'
      },
      table: {
        tier: 'Уровень',
        license: 'Цена лицензии на узел (USDT)',
        total_num: 'Всего лицензий',
        total_usdt: 'Всего USDT',
        buy: 'Купить'
      },
      intro: {
        title: 'Введение в майнинг узлов',
        text1_1: 'Чем больше пользователей сети DeepLink, тем больше требуется сетевого трафика и вычислительных мощностей, что приводит к необходимости большего количества машин для трафика CPU и машин для вычислений на GPU, чтобы обеспечить инфраструктуру для сети DeepLink, достигая децентрализации и способствуя более быстрому росту DeepLink. Приобретая лицензию на узел, вы получаете возможность участвовать в майнинге и зарабатывать вознаграждения. Есть вопросы? Пожалуйста,',
        text1_2: 'и',
        text1_3: 'общайтесь с нами выше.',
        text2: 'Каждый узел выдается в форме NFT и дополнительно получает 30,000 DLC через аирдроп. Аирдроп начинается до листинга на бирже, при этом 20% аирдропа DLC разблокируется сразу, а 80% разблокируется в течение 8 месяцев. Скачайте программное обеспечение DeeLink, чтобы создать адрес кошелька DLC:',
        text3: 'Узлы не могут быть перепроданы другим людям до 31 октября 2024 года.',
        text4: '25% добытых токенов разблокируются сразу, остальные 75% разблокируются линейно в течение 150 дней.',
        text5: 'Если майнинг не начнется в течение 10 дней после листинга, награды будут распределяться через аирдропы, которые будут проводиться каждые 7 дней. Правила аирдропа такие же, как и правила добычи, с общим ежедневным выпуском 4,1 миллиона DLC, равномерно распределенными в зависимости от общего количества проданных узлов. 25% разблокируется сразу, а 75% разблокируется линейно в течение 150 дней. Если майнинг уже начался, для получения вознаграждений потребуются соответствующие машины.'
      },
      question: {
        ques1: 'Сколько всего узловых NFT продается?',
        ans1: 'Всего 120,000',
        ques2: 'Сколько стоит каждый узловой NFT?',
        ans2: 'Всего 10 уровней, с ценами от 30U до 155U, продается на официальном сайте до распродажи',
        ques3: 'Покупка узлового NFT является одноразовой?',
        ans3: 'Да, покупка узла является одноразовой, дополнительных платежей в будущем не требуется',
        ques4: 'Есть ли дополнительные аирдропы при покупке узлового NFT?',
        ans4: 'Да, каждый владелец узла получает аирдроп в размере 30,000 DLC. Аирдроп начинается перед листингом на бирже, при этом 20% DLC разблокируются сразу, а 80% разблокируются в течение 8 месяцев',
        ques5: 'Каков конкретный механизм майнинга?',
        ans5: 'Общая награда составляет 12 миллиардов DLC. Общий доход для трафик-узлов составляет 4 миллиарда DLC, и общий доход для узлов персональных GPU машин составляет 8 миллиардов DLC. Приобретя узловой NFT, вы можете одновременно участвовать в обоих видах майнинга. В течение первых 4 лет награда составляет 1,5 миллиарда DLC в год, с уменьшением вдвое каждые 4 года. Общий дневной бонус составляет 4,11 миллиона DLC, распределяемых только среди владельцев узловых NFT. 25% добытых токенов разблокируются сразу, а 75% разблокируются линейно в течение 150 дней',
        ques6: 'Может ли приобретенный узловой NFT участвовать в майнинге на машинах с трафиком CPU и на персональных машинах с GPU?',
        ans6: 'Да',
        ques7: 'Что такое узел машины с трафиком CPU?',
        ans7: 'Узел машины с трафиком CPU выполняет основную задачу по вычислениям и передаче трафика в сети DeepLink. С помощью модели майнинга DeepLink позволяет большему количеству людей участвовать и получать вознаграждения. Общий доход для трафик-узлов составляет 4 миллиарда DLC',
        ques8: 'Какова общая награда для машин с трафиком в первый год?',
        ans8: 'Награда за первый год составляет 500 миллионов DLC, с ежедневной наградой около 1,37 миллиона DLC',
        ques9: 'Какое соотношение между доходами каждого узла машины с трафиком CPU и его пропускной способностью?',
        ans9: 'Базовая пропускная способность составляет 10Мб восходящего трафика. Значение пропускной способности для 10Мб восходящего трафика составляет 10. Пропускная способность действительна только в кратных 10, например, пропускная способность 45Мб восходящего трафика рассчитывается как 40. Пропускная способность увеличивается на 30% в зависимости от фактического использования. В конечном итоге доходы распределяются в зависимости от фактического значения пропускной способности',
        ques10: 'Требования к конфигурации узловых машин с трафиком высоки? Есть ли информация о конфигурации? Как участвовать в майнинге?',
        ans10: 'Требования к конфигурации невысокие. vCPU 1C + 2Гб памяти + 50Гб жесткого диска. Аренда на AWS стоит около 10 долларов в месяц, пропускная способность оплачивается в зависимости от фактического использования. После аренды виртуальной машины на AWS установите программу майнинга узлового трафика DeepLink, создайте адрес кошелька, переведите узловой NFT в кошелек и активируйте функцию майнинга, чтобы участвовать в майнинге',
        ques11: 'Что такое узел персональной машины с GPU?',
        ans11: 'Индивидуальные пользователи могут предложить свои GPU компьютеры для участия в майнинге. Машины с GPU предоставляют инфраструктурные услуги для облачного гейминга в рамках протокола DeepLink. Общий доход для узлов персональных машин с GPU составляет 8 миллиардов DLC',
        ques12: 'Какова общая награда для узлов персональных машин с GPU в первый год?',
        ans12: 'Награда за первый год составляет 1 миллиард DLC, с ежедневной наградой около 2,74 миллиона DLC',
        ques13: 'Каковы требования к конфигурации узлов персональных машин с GPU? Как участвовать в майнинге?',
        ans13: 'Требования к конфигурации машин с GPU: частота CPU не ниже 3.0ГГц, GPU из серии Nvidia Geforce: 20xx, 30xx, 40xx, и не менее 16Гб памяти'
      }
    }
  }