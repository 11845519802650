// 土耳其语
export default {
  reward2: {
    desc: {
      title1: 'DeepLink Düğümleri',
      text1: 'Yalnızca Node sahipleri CPU trafik madenciliği ve kişisel GPU makine madenciliğine katılabilir. Toplam ödül, %4’ü CPU trafik node’ları ve %8’i kişisel GPU node’ları için olmak üzere %12 DLC Token’dır. Toplam token miktarı 12 milyar. Her node’un üretimi, satılan node’ların sayısına bağlıdır. Eğer hepsi satılırsa, her node 100,000 DLC üretir. Eğer yalnızca %10’u satılırsa, her node 1 milyon DLC üretir.',
      title2: 'Toplam Node sayısı: 120,000 ek ihraç yok',
      text2: 'Her Node için ek airdrop',
      text3: 'Her Node tarafından çıkarılan minimum DLC miktarı',
      text4: 'Başlangıç fiyatı',
      text5: 'Maksimum fiyat'
    },
    table: {
      tier: 'Katman',
      license: 'Node Lisans Fiyatı (USDT)',
      total_num: 'Toplam Lisans Sayısı',
      total_usdt: 'Toplam USDT',
      buy: 'Satın Al'
    },
    intro: {
      title: 'Node Madenciliği Tanıtımı',
      text1_1: "DeepLink ağının kullanıcı sayısı arttıkça, daha fazla ağ trafiği ve hesaplama gücü gerekecek, bu da DeepLink ağına altyapı sağlamak için daha fazla CPU trafiği makinesi ve daha fazla GPU hesaplama makinesi gerektirecek. Bu, merkeziyetsizliği sağlar ve DeepLink'in daha hızlı gelişmesine yardımcı olur. Bir Node lisansı satın alarak madenciliğe katılma ve ödül kazanma fırsatına sahip olursunuz. Sorularınız mı var? Gelin",
      text1_2: 've',
      text1_3: 'bizimle yukarıda sohbet edin.',
      text2: "Her Node, bir NFT şeklinde verilir ve ek olarak 30.000 DLC airdrop alır. Airdrop, borsada listelenmeden önce başlar ve DLC'nin %20'si hemen kilidi açılır ve %80'i 8 ay boyunca kilidi açılır. Bir DLC cüzdan adresi oluşturmak için DeeLink yazılımını indirin:",
      text3: 'Nodlar 31 Ekim 2024 tarihinden önce başkalarına satılamaz.',
      text4: "Kazılan tokenlerin %25'i hemen kilidi açılır, %75'i 150 gün boyunca lineer olarak kilidi açılır.",
      text5: "Listeleme sonrası 10 gün içinde madencilik başlamazsa, ödüller her 7 günde bir yapılan airdroplar şeklinde dağıtılır. Airdrop kuralları, madencilik üretim kuralları ile aynıdır ve günlük toplam üretim 4,1 milyon DLC'dir, satılan node sayısına eşit olarak dağıtılır. %25 hemen kilidi açılır, %75 150 gün boyunca lineer olarak kilidi açılır. Madencilik zaten başladıysa, ödül almak için ilgili makineler gereklidir."
    },
    question: {
      ques1: 'Toplamda kaç Node NFT satılıyor?',
      ans1: 'Toplamda 120,000',
      ques2: 'Her Node NFT’nin maliyeti nedir?',
      ans2: '10 seviyeye bölünmüştür, fiyatlar 30U ile 155U arasında değişmekte olup, resmi web sitesinde satılmaktadır, satılana kadar',
      ques3: 'Node NFT satın almak tek seferlik bir ödeme midir?',
      ans3: 'Evet, Node satın almak tek seferlik bir ödemedir ve gelecekte ek ücret gerekmez',
      ques4: 'Node NFT satın alırken ek airdrop var mı?',
      ans4: 'Evet, her Node sahibi 30,000 DLC airdrop alır. Airdrop, borsada listelemeden önce başlar ve airdrop DLC’nin %20’si hemen kilitsiz hale gelir ve %80’i 8 ay boyunca kilitlenir',
      ques5: 'Belirli madencilik mekanizması nedir?',
      ans5: 'Toplam ödül 12 milyar DLC’dir. Trafik nodelarının toplam kazancı 4 milyar DLC, kişisel GPU makinelerinin nodelarının toplam kazancı ise 8 milyar DLC’dir. Bir Node NFT satın alarak her iki madencilik türüne de aynı anda katılabilirsiniz. İlk 4 yıl için ödül yılda 1.5 milyar DLC’dir, her 4 yılda bir yarıya iner. Günlük toplam ödül 4.11 milyon DLC’dir ve sadece Node NFT sahiplerine dağıtılır. Kazılan tokenların %25’i hemen kilitsiz hale gelir, %75’i ise 150 gün boyunca lineer olarak kilitsiz hale gelir',
      ques6: 'Satın alınan bir Node NFT, CPU trafik makinesi madenciliğine ve kişisel GPU makinesi madenciliğine aynı anda katılabilir mi?',
      ans6: 'Evet',
      ques7: 'CPU bant genişliği trafik makinesi nodu nedir?',
      ans7: 'CPU bant genişliği trafik makinesi nodu, DeepLink ağında hesaplama ve trafik yönlendirme görevlerini üstlenir. Madencilik modeli aracılığıyla DeepLink, daha fazla kişinin katılmasını ve ödül kazanmasını sağlar. Trafik nodelarının toplam kazancı 4 milyar DLC’dir',
      ques8: 'İlk yıl için bant genişliği trafik makineleri için toplam ödül nedir?',
      ans8: 'İlk yıl için ödül 500 milyon DLC’dir, günlük yaklaşık 1.37 milyon DLC ödüllendirilir',
      ques9: 'Her CPU bant genişliği trafik makinesi nodunun bant genişliği ile kazancı arasındaki ilişki nedir?',
      ans9: 'Temel bant genişliği, 10Mb uplink bant genişliğidir. Bant genişliği değeri, 10Mb uplink bant genişliği için 10’dur. Bant genişliği sadece 10’un katları halinde geçerlidir, örneğin, 45Mb uplink bant genişliği bant genişliği değeri olarak 40 olarak hesaplanır. Bant genişliği, gerçek kullanımına göre %30 artar. Son olarak, kazançlar gerçek bant genişliği değerine göre paylaşılır',
      ques10: 'Bant genişliği trafik makineleri nodları için konfigürasyon gereksinimleri yüksek mi? Konfigürasyon bilgileri var mı? Madenciliğe nasıl katılabilirsiniz?',
      ans10: 'Konfigürasyon gereksinimleri yüksek değil. vCPU 1C + 2G bellek + 50G sabit disk. AWS’de kiralama aylık yaklaşık 10 USD’dir, bant genişliği gerçek kullanımına göre ücretlendirilir. AWS’de bir CPU sanal makinesi kiraladıktan sonra, DeepLink trafik nodu madencilik programını yükleyin, bir cüzdan adresi oluşturun, Node NFT’yi cüzdana aktarın ve madencilik fonksiyonunu etkinleştirerek madenciliğe katılın',
      ques11: 'Kişisel GPU makinesi nodu nedir?',
      ans11: 'Bireyler, madenciliğe katılmak için kendi GPU bilgisayarlarını sunabilirler. GPU makineleri, DeepLink Protokolü için bulut oyun altyapı hizmetleri sağlar. Kişisel GPU makineleri nodelarının toplam kazancı 8 milyar DLC’dir',
      ques12: 'İlk yıl için kişisel GPU makineleri nodeları için toplam ödül nedir?',
      ans12: 'İlk yıl için ödül 1 milyar DLC’dir, günlük yaklaşık 2.74 milyon DLC ödüllendirilir',
      ques13: 'Kişisel GPU makineleri nodeları için konfigürasyon gereksinimleri nelerdir? Madenciliğe nasıl katılabilirsiniz?',
      ans13: 'GPU makineleri için konfigürasyon gereksinimleri: en az 3.0GHz CPU frekansı, Nvidia Geforce serisi GPU: 20xx, 30xx, 40xx ve en az 16G bellek'
    }
  }
}