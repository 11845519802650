<template>
  <div class="nft" :class="{lan_En : lan != 'zh'}">
    <!-- <img class="bg" src="../assets/nftbg.png" alt=""> -->
    <div class="content">
      <div class="nft_title">{{$t('nft.cont.title1')}}</div>
      <div class="desc hide">
        <p>{{$t('nft.cont.desc1')}}</p>
        <p>{{$t('nft.cont.desc2')}}</p>
        <p>{{$t('nft.cont.desc3')}}</p>
        <p>{{$t('nft.cont.desc4')}}</p>
      </div>
      <div class="desc show">
        <!-- <p>{{$t('nft.cont.desc1_1')}}</p>
        <p>{{$t('nft.cont.desc2_1')}}</p>
        <p>{{$t('nft.cont.desc3_1')}}</p> -->
        <p>{{$t('nft.cont.desc1')}}{{$t('nft.cont.desc2')}}{{$t('nft.cont.desc3')}}{{$t('nft.cont.desc4')}}</p>
      </div>
      <div class="enabling">
        <img class="img" src="../assets/nft_bg.png" alt="">
        <div class="enabl">
          <div class="title">{{$t('nft.enabl.title1')}}</div>
          <div class="list">
            <p><span class="icon"></span><span class="text">{{$t('nft.enabl.desc2')}}</span></p>
            <p><span class="icon"></span><span class="text">{{$t('nft.enabl.desc3')}}</span></p>
            <p><span class="icon"></span><span class="text">{{$t('nft.enabl.desc4')}}</span></p>
            <p><span class="icon"></span><span class="text">{{$t('nft.enabl.desc5')}}</span></p>
            <p><span class="icon"></span><span class="text">{{$t('nft.enabl.desc6')}}</span></p>
            <p><span class="icon"></span><span class="text">{{$t('nft.enabl.desc7')}}</span></p>
            <p><span class="icon"></span><span class="text">{{$t('nft.enabl.desc1')}}</span></p>
          </div>
        </div>
      </div>
      <div class="edition">
        <div class="ver">
          <div class="ver_desc">
            <img src="../assets/nft_img1.png" alt="">
            <div class="info">
              <p class="title">{{$t('nft.edition.title1')}}</p>
              <p class="tip">{{$t('nft.edition.tip')}}</p>
            </div>
            <p class="price"><span>$</span>72</p>
          </div>
          <!-- <div class="ver_btn">
            <a href="https://deeplink.myslash.io/nfts/list?album=391">{{$t('nft.edition.btn')}}</a>
          </div> -->
        </div>
        <div class="ver">
          <div class="ver_desc">
            <img src="../assets/nft_img2.png" alt="">
            <div class="info">
              <p class="title">{{$t('nft.edition.title2')}}</p>
              <p class="tip">{{$t('nft.edition.tip')}}</p>
            </div>
            <p class="price"><span>$</span>360</p>
          </div>
          <!-- <div class="ver_btn">
            <a href="https://deeplink.myslash.io/nfts/list?album=395">{{$t('nft.edition.btn')}}</a>
          </div> -->
        </div>
      </div>
      <div class="contrast">
        <div class="title">{{$t('nft.edition.title3')}}</div>
        <div class="desc">{{$t('nft.edition.desc')}}</div>
        <div class="cont">
          <div class="list" v-for="(el, index) in data" :key="index">
            <div class="li">{{el.li1}}</div>
            <div class="li" v-if="!el.li2"><span class="icon"></span></div>
            <!-- <div class="li" v-if="el.li2 == ('1' || '2')">{{el.li2}}</div> -->
            <div class="li" v-else-if="el.li2 == '1' && el.li5">1 <br/> {{el.li5}}</div>
            <div class="li" v-else-if="el.li2 == '2' && el.li5">2 <br/> {{el.li5}}</div>
            <div class="li" v-else>{{el.li2}}</div>
            <div class="li" v-if="!el.li3"><span class="icon"></span></div>
            <div class="li" v-else>{{el.li3}}</div>
            <div class="li" v-if="!el.li4"><span class="icon"></span></div>
            <div class="li" v-else>{{el.li4}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { defineComponent, ref, computed, watch } from "vue";
  import { useI18n } from "vue-i18n";
  import { useStore } from "vuex"
  export default defineComponent({
    name: 'NFT',
    setup() {
      const { t, locale } = useI18n();
      const store = useStore()
      let lan = computed(() => {
        return store.state.lan
      })
      const data = ref([
        {
          li1: t('nft.edition.list.li1_1'),
          li2: t('nft.edition.list.li1_2'),
          li3: t('nft.edition.list.li1_3'),
          li4: t('nft.edition.list.li1_4')
        },
        {
          li1: t('nft.edition.list.li2_1'),
          li2: '1080p 60fps',
          li3: '4k 240fps',
          li4: '4k 240fps'
        },
        // {
        //   li1: t('nft.edition.list.li3_1'),
        //   li2: t('nft.edition.list.li3_2'),
        //   li3: t('nft.edition.list.li3_3'),
        //   li4: t('nft.edition.list.li3_3'),
        // },
        // {
        //   li1: t('nft.edition.list.li4_1'),
        //   li2: t('nft.edition.list.li4_2'),
        //   li3: t('nft.edition.list.li4_3'),
        //   li4: t('nft.edition.list.li4_4'),
        // },
        // {
        //   li1: t('nft.edition.list.li5_1'),
        //   li2: t('nft.edition.list.li5_2'),
        //   li3: t('nft.edition.list.li5_3'),
        //   li4: t('nft.edition.list.li5_3'),
        // },
        {
          li1: t('nft.edition.list.li6_1'),
          li2: '1',
          li3: '1',
          li4: '5',
          li5: t('nft.edition.list.new_text1')
        },
        {
          li1: t('nft.edition.list.li26_1'),
          li2: '2',
          li3: '20',
          li4: '25',
          li5: t('nft.edition.list.new_text2')
        },
        {
          li1: t('nft.edition.list.li7_1'),
          li2: '200',
          li3: '300',
          li4: '1000'
        },
        {
          li1: t('nft.edition.list.li8_1'),
          li2: false,
          li3: false,
          li4: false
        },
        // {
        //   li1: t('nft.edition.list.li9_1'),
        //   li2: false,
        //   li3: false,
        //   li4: false
        // },
        // {
        //   li1: t('nft.edition.list.li10_1'),
        //   li2: false,
        //   li3: false,
        //   li4: false
        // },
        {
          li1: t('nft.edition.list.li12_1'),
          li2: false,
          li3: false,
          li4: false
        },
        {
          li1: t('nft.edition.list.li14_1'),
          li2: false,
          li3: false,
          li4: false
        },
        {
          li1: t('nft.edition.list.li15_1'),
          li2: false,
          li3: false,
          li4: false
        },
        {
          li1: t('nft.edition.list.li27_1'),
          li2: false,
          li3: false,
          li4: false
        },
        {
          li1: t('nft.edition.list.li13_1'),
          li2: '-',
          li3: false,
          li4: false
        },
        {
          li1: t('nft.edition.list.li16_1'),
          li2: '-',
          li3: false,
          li4: false
        },
        {
          li1: t('nft.edition.list.li11_1'),
          li2: '-',
          li3: false,
          li4: false
        },
        {
          li1: t('nft.edition.list.li17_1'),
          li2: '-',
          li3: false,
          li4: false
        },
        {
          li1: t('nft.edition.list.li18_1'),
          li2: '-',
          li3: false,
          li4: false
        },
        // {
        //   li1: t('nft.edition.list.li19_1'),
        //   li2: '-',
        //   li3: '-',
        //   li4: false
        // },
        // {
        //   li1: t('nft.edition.list.li20_1'),
        //   li2: '-',
        //   li3: '-',
        //   li4: false
        // },
        // {
        //   li1: t('nft.edition.list.li21_1'),
        //   li2: '-',
        //   li3: '-',
        //   li4: false
        // },
        // {
        //   li1: t('nft.edition.list.li22_1'),
        //   li2: '-',
        //   li3: '-',
        //   li4: false
        // },
        // {
        //   li1: t('nft.edition.list.li23_1'),
        //   li2: '-',
        //   li3: '-',
        //   li4: false
        // },
        // {
        //   li1: t('nft.edition.list.li24_1'),
        //   li2: '-',
        //   li3: '-',
        //   li4: false
        // },
        // {
        //   li1: t('nft.edition.list.li25_1'),
        //   li2: '-',
        //   li3: '-',
        //   li4: '-'
        // }
      ])
      watch(
        () => locale.value,
        () => {
          data.value = [
            {
              li1: t('nft.edition.list.li1_1'),
              li2: t('nft.edition.list.li1_2'),
              li3: t('nft.edition.list.li1_3'),
              li4: t('nft.edition.list.li1_4')
            },
            {
              li1: t('nft.edition.list.li2_1'),
              li2: '1080p 60fps',
              li3: '4k 240fps',
              li4: '4k 240fps'
            },
            // {
            //   li1: t('nft.edition.list.li3_1'),
            //   li2: t('nft.edition.list.li3_2'),
            //   li3: t('nft.edition.list.li3_3'),
            //   li4: t('nft.edition.list.li3_3'),
            // },
            // {
            //   li1: t('nft.edition.list.li4_1'),
            //   li2: t('nft.edition.list.li4_2'),
            //   li3: t('nft.edition.list.li4_3'),
            //   li4: t('nft.edition.list.li4_4'),
            // },
            // {
            //   li1: t('nft.edition.list.li5_1'),
            //   li2: t('nft.edition.list.li5_2'),
            //   li3: t('nft.edition.list.li5_3'),
            //   li4: t('nft.edition.list.li5_3'),
            // },
            {
              li1: t('nft.edition.list.li6_1'),
              li2: '1',
              li3: '1',
              li4: '5',
              li5: t('nft.edition.list.new_text1')
            },
            {
              li1: t('nft.edition.list.li26_1'),
              li2: '2',
              li3: '20',
              li4: '25',
              li5: t('nft.edition.list.new_text2')
            },
            {
              li1: t('nft.edition.list.li7_1'),
              li2: '200',
              li3: '300',
              li4: '1000'
            },
            {
              li1: t('nft.edition.list.li8_1'),
              li2: false,
              li3: false,
              li4: false
            },
            // {
            //   li1: t('nft.edition.list.li9_1'),
            //   li2: false,
            //   li3: false,
            //   li4: false
            // },
            // {
            //   li1: t('nft.edition.list.li10_1'),
            //   li2: false,
            //   li3: false,
            //   li4: false
            // },
            {
              li1: t('nft.edition.list.li12_1'),
              li2: false,
              li3: false,
              li4: false
            },
            {
              li1: t('nft.edition.list.li14_1'),
              li2: false,
              li3: false,
              li4: false
            },
            {
              li1: t('nft.edition.list.li15_1'),
              li2: false,
              li3: false,
              li4: false
            },
            {
              li1: t('nft.edition.list.li27_1'),
              li2: false,
              li3: false,
              li4: false
            },
            {
              li1: t('nft.edition.list.li13_1'),
              li2: '-',
              li3: false,
              li4: false
            },
            {
              li1: t('nft.edition.list.li16_1'),
              li2: '-',
              li3: false,
              li4: false
            },
            {
              li1: t('nft.edition.list.li11_1'),
              li2: '-',
              li3: false,
              li4: false
            },
            {
              li1: t('nft.edition.list.li17_1'),
              li2: '-',
              li3: false,
              li4: false
            },
            {
              li1: t('nft.edition.list.li18_1'),
              li2: '-',
              li3: false,
              li4: false
            },
            // {
            //   li1: t('nft.edition.list.li19_1'),
            //   li2: '-',
            //   li3: '-',
            //   li4: false
            // },
            // {
            //   li1: t('nft.edition.list.li20_1'),
            //   li2: '-',
            //   li3: '-',
            //   li4: false
            // },
            // {
            //   li1: t('nft.edition.list.li21_1'),
            //   li2: '-',
            //   li3: '-',
            //   li4: false
            // },
            // {
            //   li1: t('nft.edition.list.li22_1'),
            //   li2: '-',
            //   li3: '-',
            //   li4: false
            // },
            // {
            //   li1: t('nft.edition.list.li23_1'),
            //   li2: '-',
            //   li3: '-',
            //   li4: false
            // },
            // {
            //   li1: t('nft.edition.list.li24_1'),
            //   li2: '-',
            //   li3: '-',
            //   li4: false
            // },
            // {
            //   li1: t('nft.edition.list.li25_1'),
            //   li2: '-',
            //   li3: '-',
            //   li4: '-'
            // }
          ]
        }
      )
      return {
        lan,
        data
      };
    }
  })
</script>

<style lang="scss" scoped>
.nft {
  width: 100%;
  min-width: 1200px;
  font-style: normal;
  background: #EEEFF2;
  // font-family: 'Rubik, sans-serif';
  position: relative;
  height: 1800px;
  background: url("../assets/reward_bg1.png") no-repeat center;
  background-size: 100% 2134px;
  .content {
    margin: auto;
    width: 1100px;
    padding: 65px 0 40px;
    .show {
      display: none;
    }
    .hide {
      display: block;
    }
    .nft_title {
      font-size: 34px;
      font-weight: 600;
      color: #0B132A;
      line-height: 48px;
      text-align: center;
      margin-bottom: 15px;
    }
    .desc {
      width: 100%;
      font-size: 14px;
      font-weight: 500;
      color: #4F5665;
      line-height: 20px;
      text-align: center;
      margin-bottom: 40px;
    }
    .enabling {
      width: 100%;
      display: flex;
      padding: 5px 10px;
      align-items: center;
      border-radius: 30px;
      box-sizing: border-box;
      justify-content: space-around;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.18) 0%, rgba(255, 255, 255, 0.3) 67.57%);
      .img {
        width: 345px;
        height: 280px;
      }
      .enabl {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: rgba(54, 63, 94, 0.7);
        .title {
          font-size: 18px;
          font-weight: 600;
          color: #363F5E;
          line-height: 22px;
          margin-bottom: 10px;
        }
        p {
          display: flex;
          margin-bottom: 5px;
          align-items: center;
        }
        .icon {
          width: 18px;
          height: 18px;
          margin-right: 8px;
          display: inline-block;
          background: url(../assets/nft_icon.png) no-repeat 100%/100%;
        }
      }
    }
    .edition {
      width: 100%;
      display: flex;
      margin-top: 40px;
      align-items: center;
      justify-content: space-between;
      .ver {
        width: 530px;
        // height: 230px;
        padding: 30px;
        border-radius: 30px;
        box-sizing: border-box;
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.48) 0%, rgba(255, 255, 255, 0.8) 67.57%);
        .ver_desc {
          display: flex;
          align-items: center;
          // margin-bottom: 25px;
          img {
            width: 86px;
            height: 86px;
            margin-right: 20px;
          }
          .info {
            flex: 1;
            .title {
              font-size: 30px;
              font-weight: 600;
              color: #363F5E;
              line-height: 42px;
            }
            .tip {
              font-size: 16px;
              font-weight: 500;
              line-height: 22px;
              color: rgba(54, 63, 94, 0.5);
            }
          }
          .price {
            display: flex;
            font-size: 36px;
            color: #363F5E;
            font-weight: 600;
            line-height: 50px;
            align-items: center;
            span {
              font-size: 24px;
              font-weight: 500;
              margin-right: 5px;
              line-height: 34px;
              color: rgba(54, 63, 94, 0.5);
            }
          }
        }
        .ver_btn {
          text-align: center;
          a {
            color: #FFF;
            cursor: pointer;
            font-size: 18px;
            font-weight: 500;
            padding: 14px 40px;
            border-radius: 58px;
            display: inline-block;
            text-decoration: none;
            background: linear-gradient(271.98deg, #2AB36A 4.13%, rgba(104, 204, 79, 0.8) 114.86%);
          }
        }
      }
    }
    .contrast {
      width: 100%;
      // opacity: 0.5;
      margin-top: 40px;
      padding: 40px 52px;
      text-align: center;
      border-radius: 30px;
      box-sizing: border-box;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.408) 0%, rgba(255, 255, 255, 0.68) 67.57%);
      .title {
        font-size: 26px;
        color: #363F5E;
        font-weight: 500;
        line-height: 36px;
        margin-bottom: 5px;
      }
      .desc {
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        margin-bottom: 30px;
        color: rgba(54, 63, 94, 0.6);
      }
      .cont {
        width: 100%;
        display: flex;
        flex-direction: column;
        .list {
          width: 100%;
          display: flex;
          justify-content: space-between;
          .li {
            width: 240px;
            padding: 12px 5px;
            box-sizing: border-box;
            line-height: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            font-weight: 500;
            // line-height: 42px;
            background: #FFF;
            text-align: center;
            color: rgba(54, 63, 94, 0.6);
            box-shadow: inset 0px 1px 0px rgba(54, 63, 94, 0.16);
            .icon {
              width: 18px;
              height: 18px;
              display: inline-block;
              vertical-align: middle;
              background: url(../assets/nft_icon.png) no-repeat 100%/100%;
            }
          }
          &:first-child {
            .li {
              height: 54px;
              font-size: 16px;
              box-shadow: none;
              line-height: 54px;
              font-weight: 600;
              color: #363F5E;
              border-radius: 8px 8px 0px 0px;
              background: rgba(54, 63, 94, 0.08);
            }
          }
          &:last-child {
            .li {
              border-radius: 0px 0px 8px 8px;
            }
          }
        }
      }
    }
  }
}

.lan_En.nft {
  width: 100%;
}

@media screen and (max-width: 1200px){
  .nft {
    width: 100%;
    min-width: 600px;
    height: inherit;
    background-size: 100% 100%;
    background-position-y: -10px;
    .content {
      position: relative;
      margin-top: -2px;
      width: 100%;
      font-size: 0;
      line-height: 0;
      &.content1 {
        height: inherit;
        background: url('../assets/reward_bg1.png') no-repeat center;
        background-size: 100% 100%;
      }
      .show {
        display: block;
      }
      .hide {
        display: none;
      }
      .nft_title {
        font-size: 26px;
      }
      .desc {
        width: 85%;
        // text-align: left;
        margin: 0 auto 40px;
      }
      .enabling {
        width: 90%;
        margin: auto;
        display: flex;
        flex-direction: column-reverse;
        padding: 50px 20px 0;
        align-items: center;
        border-radius: 30px;
        box-sizing: border-box;
        justify-content: space-around;
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.24) 0%, rgba(255, 255, 255, 0.4) 73.01%);
        .img {
          width: 230px;
          height: inherit;
        }
        .enabl {
          width: 100%;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          text-align: center;
          color: rgba(54, 63, 94, 0.7);
          .title {
            font-size: 26px;
            font-weight: 600;
            color: #363F5E;
            line-height: 34px;
            margin-bottom: 20px;
          }
          .list {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            p {
              width: 50%;
              display: flex;
              margin-bottom: 5px;
              align-items: center;
              &:last-child {
                width: 100%;
              }
            }
            .icon {
              width: 18px;
              height: 18px;
              margin-right: 8px;
              display: inline-block;
              background: url(../assets/nft_icon.png) no-repeat 100%/100%;
            }
            .text {
              flex: 1;
              line-height: 1;
              text-align: left;
            }
          }
        }
      }
      .edition {
        width: 90%;
        margin: auto;
        display: block;
        margin-top: 40px;
        .ver {
          width: 100%;
          display: flex;
          height: inherit;
          padding: 20px 40px;
          border-radius: 30px;
          align-items: center;
          margin-bottom: 20px;
          box-sizing: border-box;
          justify-content: space-between;
          background: linear-gradient(0deg, rgba(255, 255, 255, 0.48) 0%, rgba(255, 255, 255, 0.8) 67.57%);
          .ver_desc {
            flex: 1;
            display: flex;
            align-items: center;
            margin-bottom: 0;
            margin-right: 30px;
            img {
              width: 60px;
              height: 60px;
              margin-right: 20px;
            }
            .info {
              flex: 1;
              .title {
                font-size: 20px;
                font-weight: 600;
                color: #363F5E;
                line-height: 42px;
              }
              .tip {
                font-size: 14px;
                font-weight: 500;
                line-height: 22px;
                color: rgba(54, 63, 94, 0.5);
              }
            }
            .price {
              display: flex;
              font-size: 36px;
              color: #363F5E;
              font-weight: 600;
              line-height: 50px;
              align-items: center;
              span {
                font-size: 24px;
                font-weight: 500;
                margin-right: 5px;
                line-height: 34px;
                color: rgba(54, 63, 94, 0.5);
              }
            }
          }
          .ver_btn {
            text-align: center;
            a {
              color: #FFF;
              cursor: pointer;
              font-size: 18px;
              font-weight: 500;
              line-height: 20px;
              padding: 10px 20px;
              border-radius: 58px;
              display: inline-block;
              text-decoration: none;
              background: linear-gradient(271.98deg, #2AB36A 4.13%, rgba(104, 204, 79, 0.8) 114.86%);
            }
          }
        }
      }
      .contrast {
        width: 90%;
        // opacity: 0.5;
        padding: 40px 10px;
        text-align: center;
        border-radius: 30px;
        margin: 40px auto 0;
        box-sizing: border-box;
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.408) 0%, rgba(255, 255, 255, 0.68) 67.57%);
        .title {
          font-size: 26px;
          font-weight: 600;
          color: #363F5E;
          line-height: 36px;
          margin-bottom: 5px;
        }
        .desc {
          font-size: 16px;
          font-weight: 500;
          line-height: 22px;
          margin-bottom: 30px;
          color: rgba(54, 63, 94, 0.6);
        }
        .cont {
          width: 100%;
          display: flex;
          flex-direction: column;
          .list {
            width: 100%;
            display: flex;
            justify-content: space-between;
            .li {
              width: 24%;
              display: flex;
              line-height: 1;
              height: inherit;
              font-size: 14px;
              font-weight: 500;
              padding: 10px 5px;
              background: #FFF;
              align-items: center;
              box-sizing: border-box;
              justify-content: center;
              color: rgba(54, 63, 94, 0.6);
              box-shadow: inset 0px 1px 0px rgba(54, 63, 94, 0.16);
              .icon {
                width: 18px;
                height: 18px;
                display: inline-block;
                vertical-align: middle;
                background: url(../assets/nft_icon.png) no-repeat 100%/100%;
              }
            }
            &:first-child {
              .li {
                height: 54px;
                font-size: 16px;
                box-shadow: none;
                line-height: 1;
                font-weight: 600;
                color: #363F5E;
                border-radius: 8px 8px 0px 0px;
                background: rgba(54, 63, 94, 0.08);
              }
            }
            &:last-child {
              .li {
                border-radius: 0px 0px 8px 8px;
              }
            }
          }
        }
      }
    }
  }
  .lan_En.nft {
    .content {
      .edition {
        .ver {
          .ver_desc {
            .info {
              .title {
                line-height: 1;
                margin: 5px 0 10px;
              }
            }
          }
        }
      }
    }
  }
}


</style>

