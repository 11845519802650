<template>
  <Header></Header>
  <router-view/>
  <Footer></Footer>
</template>

<script>
  // @ is an alias to /src
  import { defineComponent, ref } from "vue";
  import Header from "@/components/header.vue";
  import Footer from "@/components/footer.vue";
  export default defineComponent({
    name: 'App',
    components: {
      Header,
      Footer
    }
  })
</script>

<style lang="scss">
#app {
  font-family: "Sharp Grotesk DB Cyr Book 20",Arial,sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fff;
}
body, p, div, span, li {
  margin: 0;
}


.demo-dropdown {
  border: none !important;
  border-radius: 12px !important;
  background: #000 !important;
  .el-dropdown-menu {
    background: #000 !important;
  }
  .el-dropdown-menu__item {
    color: rgba(255, 255, 255, 0.6);
    padding: 10px 20px;
    .img {
      width: 24px;
      height: 24px;
      margin-right: 10px;
      display: inline-block;
      &.twitter {
        background: url(./assets/twitter.png) no-repeat 100%/100%;
      }
      &.telegram {
        background: url(./assets/telegram.png) no-repeat 100%/100%;
      }
      &.discord {
        background: url(./assets/discord.png) no-repeat 100%/100%;
      }
      &.tiktok {
        background: url(./assets/tiktok.png) no-repeat 100%/100%;
      }
      &.medium {
        background: url(./assets/medium.png) no-repeat 100%/100%;
      }
      &.youtube {
        background: url(./assets/youtube.png) no-repeat 100%/100%;
      }
      &.instagram {
        background: url(./assets/instagram.png) no-repeat 100%/100%;
      }
      &.facebook {
        background: url(./assets/facebook.png) no-repeat 100%/100%;
      }
    }
    .flag {
      width: 20px;
      margin-left: 4px;
    }
  }
  .el-dropdown-menu__item:not(.is-disabled):focus {
    background: rgba(255, 255, 255, 0.1);
    color: #FFFFFF;
    .img {
      &.twitter {
        background: url(./assets/twitter1.png) no-repeat 100%/100%;
      }
      &.telegram {
        background: url(./assets/telegram1.png) no-repeat 100%/100%;
      }
      &.discord {
        background: url(./assets/discord1.png) no-repeat 100%/100%;
      }
      &.tiktok {
        background: url(./assets/tiktok1.png) no-repeat 100%/100%;
      }
      &.medium {
        background: url(./assets/medium1.png) no-repeat 100%/100%;
      }
      &.youtube {
        background: url(./assets/youtube1.png) no-repeat 100%/100%;
      }
      &.instagram {
        background: url(./assets/instagram1.png) no-repeat 100%/100%;
      }
      &.facebook {
        background: url(./assets/facebook1.png) no-repeat 100%/100%;
      }
    }
  }
  .el-dropdown-menu__item:not(.is-disabled):hover {
    background: rgba(255, 255, 255, 0.1);
    color: #FFFFFF;
    .img {
      &.twitter {
        background: url(./assets/twitter1.png) no-repeat 100%/100%;
      }
      &.telegram {
        background: url(./assets/telegram1.png) no-repeat 100%/100%;
      }
      &.discord {
        background: url(./assets/discord1.png) no-repeat 100%/100%;
      }
      &.tiktok {
        background: url(./assets/tiktok1.png) no-repeat 100%/100%;
      }
      &.medium {
        background: url(./assets/medium1.png) no-repeat 100%/100%;
      }
      &.youtube {
        background: url(./assets/youtube1.png) no-repeat 100%/100%;
      }
      &.instagram {
        background: url(./assets/instagram1.png) no-repeat 100%/100%;
      }
      &.facebook {
        background: url(./assets/facebook1.png) no-repeat 100%/100%;
      }
    }
  }
}
.el-popper__arrow {
  display: none;
}
.el-dialog__body {
  padding-top: 0 !important;
}

</style>
