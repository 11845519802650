export default {
  meau: {
    nav1: 'Homepage',
    nav2: 'Community',
    nav3: 'Download',
    nav4: 'Node Reward',
    nav5: 'NFT',
    nav6: 'Internet Cafe',
    nav7: 'GameFi',
  },
  cont1: {
    text1: 'Remote Control Software Focused on Gaming',
    text2: 'Cloud Game Platform',
    btn: 'Download Now',
    btn1: 'PC Download',
    btn2: 'WEB Remote Control',
    btn3: 'Android (Beta) Download',
    btn4: 'Google Play',
    version: 'Version {ver}',
    text3_1: 'Join',
    text3_2: 'the community',
    text3_3: 'to get DeepLink Coin rewards!'
  },
  cont2: {
    text1: 'DeepLink',
    text2: 'Relying on e-sports-level low-latency technical capabilities, you can play games smoothly',
    text3: 'Core competencies and technical strengths',
    text4: 'Based on the core capabilities of cloud gaming, the experience is far superior to traditional remote control!',
    leaf1: {
      text1: 'Highly smooth, high performance',
      text2: 'Low cost',
      text3: 'Industry-leading codec solution with minimal performance consumption and resource usage at the same code rate',
      text4: 'peer-to-peer:Supports ipv6 with up to 95% NAT throughput and zero bandwidth usage.'
    },
    leaf2: {
      text1: 'High frame rate, low latency',
      text2: '',
      text3: "With LAN latency <15ms and 4K 240Hz capability, the WAN review is also in the world's top tier."
    },
    leaf3: {
      text1: 'High definition',
      text2: '',
      text3: 'Intelligent algorithms enhance viewing at the same bit rate and restore the most realistic colour performance.'
    },
    leaf4: {
      text1: 'Highly stable',
      text2: '',
      text3: 'Anti-packaging loss and anti-congestion strategies supported by large amounts of data, allowing for rapid adjustment to data packaging loss and congestion.'
    },
    leaf5: {
      text1: 'Highly secure',
      text2: '',
      text3: 'All data is encrypted by DTLS1.2 with AES256.'
    }
  },
  cont3: {
    text1: "So smooth it's like controlling a local device",
    text2: 'Applying dozens of optimisation techniques in three main areas, DeepLink is able to maintain a high frame rate despite a 5% packaging loss rate and 100ms network latency*. Meeting the requirements of all types of network environments.',
    li1: {
      text1: 'Weak network countermeasure technology',
      text2: 'FEC anti-packaging loss strategy',
      text3: 'Bandwidth Forecast',
      text4: 'SVC coding strategy'
    },
    li2: {
      text1: 'Transmission optimisation techniques',
      text2: 'Port fingerprint prediction',
      text3: 'Supports IPV6',
      text4: 'UPNP',
      text5: 'Intelligent routing'
    },
    li3: {
      text1: 'Display processing technology',
      text2: 'Frame rate adaptation strategy',
      text3: 'Motion picture prediction',
      text4: 'Jitter processing strategy',
      text5: 'AI low-coding HD rendering',
      text6: 'Dynamic virtual reality ware'
    },
    desc3: 'LAN < 5 ms - Intercity network < 10 ms - Interprovincial network < 30 ms'
  },
  cont4: {
    text1: 'Super clear graphic quality',
    text2: 'Ultra HD resolution',
    text3: '1.07 billion colours supported',
    text4: '4:4:4 undamaged picture quality',
    text5: "DeepLink always uses more demanding image quality metrics to achieve an image experience that far exceeds other traditional remote technologies, with remote image distortion below the limit of human recognition, fully supporting the creative industry's demand for precise picture display.",
    text6: '*PSNR refers to Peak Signal to Noise Ratio, below 20dB the picture distortion is high, 20-30dB the picture distortion is average, 30-40dB the picture distortion is low, above 40dB the picture distortion is extremely low and undetectable to the naked eye.'
  },
  cont5: {
    text1: 'Extensive peripheral support',
    text2: 'DeepLink provides remote support for professional accessories such as digital boards and joysticks for the creative industry, and optimises the use of creative software such as PS, Zbrush and Unity to ensure a consistent experience of creative production tools in both remote and local states.'
  },
  cont6: {
    text1: 'Multiple guarantees - minimalist approach',
    block1: {
      title1: 'Secure accounts',
      desc1: 'Multi-layer account protection mechanism for peace of mind',
      title2: 'Secure connection',
      desc2: 'DTLS1.2 protocol protection, access only with authorisation',
      title3: 'Secure data',
      desc3: '256-bit AES encryption, secure throughout transmission',
      title4: 'Secure devices',
      desc4: 'Only the corresponding person will see the corresponding device according to your settings'
    },
    block2: {
      title1: 'Multiple security guarantees',
      desc1: 'Safeguarding every connection',
      title2: 'Easy to use',
      desc2: 'Easy to start'
    },
    block3: {
      title1: 'Lightweight for downloading',
      desc1: "For DeepLink's client-end it's only about 10M",
      title2: 'Easy to learn',
      desc2: 'The configuration process can be completed by following the guidelines',
      title3: 'Easy to use',
      desc3: 'Remote control of any device within your rights via the mouse'
    }
  },
  cont7: {
    text1: 'Dedicated to offer remote control solutions',
    block1: {
      word1: 'Education industry',
      word2: 'Game industry',
      word3: 'Creative & design industry',
      word4: 'IT industry',
      word5: 'Businessmen and women on the go',
      word6: 'Co-contributors around the world',
      word7: 'IT O&M Remote Maintenance',
      word8: 'Individual users and gamers'
    },
    block2: {
      text1: 'Support for remote control scenarios in various industries',
      text2: 'Based on the actual needs of companies in various industries, we help them to achieve their business goals in an energy-efficient manner.',
      text3: 'Offers personalisation and encryption policies, flexible security',
      text4: "The company's employees are able to access the company's resources at any time and from anywhere based on their actual permissions."
    },
    block3: {
      text1: 'Covering a wide range of user needs',
      text2: 'Whether you choose to use it for your in-house staff,',
      text3: 'or for external customers, collaborators, or even personal use.',
      text4: 'Our remote control capabilities can serve you on major platforms and devices,',
      text5: 'offering an alternative, simple and clever way of solving problems.'
    }
  },
  cont9: {
    block1: {
      text1: 'Multiple security guarantees',
      text2: 'Protects your every connection',
    },
    block2: { 
      title1: 'Internet Cafes sharing',
      text1_1: '256-bit AES encryption',
      text1_2: 'The whole transmission process is kept confidential',
      title2: 'Safe connection',
      text2_1: 'DTLS1.2 protocol protection',
      text2_2: 'Authorized access only',
      title3: 'Devices secured',
      text3_1: 'According to your settings, only the corresponding user can see the corresponding equipment',
      title4: 'Account secured',
      text4_1: 'Multi-layer account protection mechanism based on blockchain technology, device information, point-to-point encrypted transmission without going through a centralized server',
    },
    block3: {
      text1: 'Internet Cafes sharing',
      text2: 'Internet cafe owners can rent out their Internet cafe machines through DeepLink to earn income, and users can play games in the Internet cafes within 50 kilometers of their homes through DeepLink without actually going to the Internet Cafe',
    },
  },
  cont10: {
    title: 'DeepLink provides Cloud game solution',
    desc: 'Maximize profit margins for Internet cafes with reduced costs and unlimited scalability',
    block1: {
      title1: '4 major problems for Internet Cafes currently',
      text1: 'Electricity bills rising again?',
      text1_1: 'Electricity bills',
      text1_2: 'rising due to government policies',
      text2: 'How many RTX-40XX can an Internet Cafe afford?',
      text2_1: 'GPU/CPU upgrading burden',
      text2_2: 'Every time a new GPU is released, it comes with the burden of expensive GPU upgrade purchases.',
      text3: 'Can we change how the consumers consume?',
      text3_1: 'Payment method',
      text3_2: 'In order to change the fixed knowledge of payment limited to 1,000 won, a reason is needed to change the status quo.',
      text4: 'How to face the impact of external environmental factors?',
      text4_1: 'Low utilization rate of PCs in Internet cafes',
      text4_2: 'Due to the pandemic, weather, etc., there are fewer users going to Internet cafes, and there is a problem of disposing of idle computers.',
    },
    block2: {
      title1: 'Cloud Internet Cafe Chain Store',
      desc1: 'The value of cloud Internet cafes: reduce start-up costs and maintenance costs, without additional upgrade costs, and provide optimal economic benefits for Internet cafe owners.',
      text1_1: 'cost reduced by 61%',
      text1_2: 'Lighten and reduce start-up costs and PC procurement costs',
      text2_1: 'Super low-latency',
      text2_2: 'Provide high-performance computing power and improve game response speed',
      text3_1: '"Zero" maintenance costs',
      text3_2: 'No desktop management costs',
      text4_1: 'Increase in turnover',
      text4_2: 'According to the GPU model, set the payment standard separately, and all seats can be set as "advanced area"',
      text5_1: 'Save on electricity bills',
      text5_2: 'Energy saving and emission reduction, carbon emission reduction',
      text4: 'How to face the impact of external environmental factors?',
      text6_1: '"Zero" maintenance costs',
      text6_2: 'GPU/CPU upgrades are done in the cloud center',
    }
  },
  footer: {
    link: 'Learn more About',
    community: 'Follow our community',
    tip: 'Our communities on: Twitter、telegram、reddit、medium、discord、tiktok、YouTube'
  },
  reward: {
    title1: 'DeepLink node(s)',
    desc1: 'Have access to rewards, e.g. DeepLink Token, Crown NFT, power of community governance.',
    title2: 'Get Genesis Node NFT',
    text1: "The more users in DeepLink's network, the more computation and traffic are needed. Genesis Nodes provide infrastructure for DeepLink network, thus decentralizing and helping DeepLink grow faster.",
    text2: 'By purchasing a Genesis Node NFT, you get the opportunity to be rewarded for your contribution to the network.',
    text3: 'This may include DeepLink Token, Crown NFT, and the power of community governance.',
    text4_1: 'Have questions? Come talk to us on',
    text4_2: 'and',
    text4_3: '',
    btntext: 'Buy now',
    text5: 'For every 1,000 Genesis Nodes NFT sold, the price increases by 1,000USD',
    text6_1: 'The number of nodes',
    text6_2: 'each NFT supports currently',
    text7: 'Total number of Genesis nodes NFT',
    text8: 'Issuance capped forever',
    text9: 'Initial price',
    text10_1: 'Maximum number of nodes',
    text10_2: 'Supported per NFT',
    text11: 'Maximum price',
    text12: 'What is a Genesis node?',
    text13: 'Genesis nodes undertake the core tasks of DeepLink network computing and traffic forwarding, and DeepLink enables more people to participate in gaining revenue through the mining model.',
    text14: 'How many Tokens in total have been given to Genesis nodes for rewards?',
    text15: '30% of the total number of tokens, i.e.30 billion DeepLink Token',
    text16: 'What is the Genesis Node NFT',
    text17: 'Only those who have Genesis Node NFT can participate in mining.',
    text18: 'How is the revenue from the sale of Genesis Node NFT distributed?',
    text19: '50% of the proceeds are invested in decentralized liquidity reward pools (e.g. DeepLink Token/USDC for Uniswap, Sushiswap) and 50% goes to DeepLink operating company as proceeds.',
    text20: 'What is the specific mining mechanism?',
    text21: 'The total reward is 5 billion a year, halved every 3 years, 10% of DeepLink Crown NFT revenue is used to buy back DeepLink Token to be added to the total reward pool before each halving, as part of the 50% reward Token, awarded to all nodes in 3 years. For example, after 3 years, 10% of DeepLink Crown NFT earnings can repurchase 12 billion DeepLink Token, then the total remaining reward pool becomes: 12 billion + 15 billion, a total of 27 billion, and the total annual reward from the second 3-years is 27 billion * 50% * 1/3 = 4.5 billion.',
    text22: 'What are the requirements for Genesis node machines?',
    text23: "Each machine must already be in DeepBrain Chain's network. DeepBrain Chain is a decentralized high-performance computing network, and the machines in DeepBrain Chain have pledged DBC, which can guarantee the stability of the machines. For more information about DeepBrain Chain:",
    text24: 'What are the other benefits of holding a Genesis Node NFT?',
    details: {
      text1: "1. Holding a certain number of Genesis Node NFTs gives you the general agency right to sell DeepLink NFTs in a country (1 NFT is needed for every 3 million people according to the country's population ratio);",
      text2: '2.DeepLink Coin airdrop;',
      text3: '3.DeepLink incubated gaming project token airdrop;',
      text4: '4. Right to vote for which games to be launched online;',
      text5: '5. Game NFT purchase discount;',
      text6: '6. Cloud game rights and hours purchase discount;',
      text7: '7. Other rights to participate in community governance.'
    },
    question: {
      text1_1: 'What is a Genesis node?',
      text1_2: 'Genesis nodes undertake the core tasks of DeepLink network computing and traffic forwarding, and DeepLink enables more people to participate in gaining revenue through the mining model.',
      text2_1: 'How many Tokens in total have been given to Genesis nodes for rewards?',
      text2_2: '10% of the total number of tokens, i.e.10 billion DeepLink Token', 
      text3_1: 'What is the Genesis Node NFT',
      text3_2: 'Only those who have Genesis Node NFT can participate in mining.',
      text4_1: 'How is the revenue from the sale of Genesis Node NFT distributed?',
      text4_2: '50% of the proceeds are invested in decentralized liquidity reward pools (e.g. DeepLink Token/USDC for Uniswap, Sushiswap) and 50% goes to DeepLink operating company as proceeds.',
      text5_1: 'What is the specific mining mechanism?',
      text5_2: 'The total reward is 5 billion a year, halved every 3 years, 10% of DeepLink Crown NFT revenue is used to buy back DeepLink Token to be added to the total reward pool before each halving, as part of the 50% reward Token, awarded to all nodes in 3 years. For example, after 3 years, 10% of DeepLink Crown NFT earnings can repurchase 12 billion DeepLink Token, then the total remaining reward pool becomes: 12 billion + 15 billion, a total of 27 billion, and the total annual reward from the second 3-years is 27 billion * 50% * 1/3 = 4.5 billion.',
      text6_1: 'What are the requirements for Genesis node machines?',
      text6_2: "Each machine must already be in DeepBrain Chain's network. DeepBrain Chain is a decentralized high-performance computing network, and the machines in DeepBrain Chain have pledged DBC, which can guarantee the stability of the machines. For more information about DeepBrain Chain:",
      text7_1: 'What are the other benefits of holding a Genesis Node NFT?',
      text7_2: `1. Holding a certain number of Genesis Node NFTs gives you the general agency right to sell DeepLink NFTs in a country (1 NFT is needed for every 3 million people according to the country's population ratio);
      2.DeepLink Coin airdrop;3.DeepLink incubated gaming project token airdrop;4. Right to vote for which games to be launched online;5. Game NFT purchase discount;
      6. Cloud game rights and hours purchase discount;7. Other rights to participate in community governance.`
    }
  },
  nft: {
    cont: {
      title1: 'Crown NFT',
      desc1: 'With this NFT, you can have 1 year access to all enterprise features of DeepLink software.',
      desc2: 'NFT is dormant after being purchased, when this NFT needs to be used, it can be activated in DeepLink software, when NFT is activated',
      desc3: '1 year usage period countdown begins. 10% of DeepLink software NFT revenue is invested in node rewards,',
      // desc4: '40% of the proceeds are invested in the liquidity reward pool, and 50% is DeepLink operating company proceeds.',
      desc4: '40% of the proceeds will be destroyed to increase the value of the DLC Token.'
    },
    enabl: {
      title1: 'NFT Rights',
      // desc1: '1 year access to all enterprise features of DeepLink software',
      desc1: 'Have access to advanced features of DeepLink software',
      desc2: 'DeepLink Coin airdrop',
      desc3: 'DeepLink incubated gaming project token airdrop',
      desc4: 'Right to vote for which games to be launched online',
      desc5: 'Game NFT purchase discount',
      desc6: 'Cloud game rights and hours purchase discount',
      desc7: 'Other rights to participate in community governance'

    },
    edition: {
      title1: 'Professional version',
      title2: 'Team version',
      tip: 'Crown NFT',
      btn: 'Buy now',
      title3: 'Professional VS Team',
      desc: 'Specific features access',
      list: {
        li1_1: 'Content',
        li1_2: 'Basic version',
        li1_3: 'Professional version',
        li1_4: 'Team version',
        li2_1: 'Best picture quality',
        li3_1: 'Forwarding server room',
        li3_2: 'Small amount',
        li3_3: 'Large amount',
        li4_1: 'Forwarding bandwidth',
        li4_2: 'Maximum 4M',
        li4_3: 'Maximum50M',
        li4_4: 'Unlimited',
        li5_1: 'End-to-end delay',
        li5_2: 'Minimum <40ms',
        li5_3: 'Minimum <20ms',
        li6_1:'Number of devices that can initiate remote control simultaneously',
        li7_1:'Number of devices',
        li8_1:'Remote Desktop',
        li9_1:'Remote camera',
        li10_1:'Remote SSH',
        li11_1:'Privacy Screen',
        li12_1:'Virtual monitor',
        li13_1:'Gaming keypad',
        li14_1:'Multi-streaming',
        li15_1:'444 True color',
        li16_1:'Record screenshots',
        li17_1:'Dual Screen to Dual Screen',
        li18_1:'Handle',
        li19_1:'User/Device Grouping Management',
        li20_1:'SSO Single Sign-In',
        li21_1:'Unified Management Authorization',
        li22_1:'Bulk Deployment',
        li23_1:'Employee Behavior Audit',
        li24_1:'Watermark',
        li25_1:'Price',
        li26_1: 'Single-machine desktop sessions',
        li27_1: 'File transfer',

        new_text1: 'If the same wallet address is logged in on multiple devices simultaneously, only one of the devices can initiate remote control.',
        new_text2: 'A machine can only open two remotely controlled windows',
      }
    }
  },
  internetCafe: {
    title1: 'DeepLink Cloud Internet Cafes solution',
    block1: {
      text1: 'Reduce the main hardware investment of Internet cafes by more than 60%',
      text2: '40% shorter ROI period',
      text3: 'Check video',
      text4: 'View File',
    },
    title2: 'Cloud Internet Cafes NFT price',
    block2: {
      desc: '40% of the proceeds will be invested in the DLC Coin pool of the DEX exchange to reward DLC investors',
      text1_1: 'Supports 50 machines',
      text1_2: '3600 USDT a year',
      text2_1: 'Supports 100 machines',
      text2_2: '7200 USDT a year',
      text3_1: 'Supports 150 machines',
      text3_2: '10800 USDT a year',
      text4_1: 'Supports 200 machines',
      text4_2: '14400 USDT a year',
    },
    title3: 'DeepLink recruiting cloud Internet cafe agents globally',
    block3: {
      text1_1: 'Rights',
      text1_2: 'Obtain cloud Internet cafe NFT at a lower price than the market',
      text2_1: 'Responsibilities',
      text2_2: 'Find a cloud Internet cafe partner in the area where the agent is located, and the partner is capable of providing cloud Internet cafe services to the Internet cafe',
      text3_1: 'Qualification criteria',
      text3_2: 'Relevant Internet cafe industry resources in the region are required, and holds amount of DLC Coins equal to the population of the agent region are required',
    },
    title4: 'Cloud Internet Cafes Q&A',
    block4: {
      text1_1: 'What is a Cloud Internet Cafe?',
      text1_2: "Cloud Internet Cafe means that you only need to configure monitors and mini PCs in Internet cafes, and do not need to configure high-end GPUs, and then connect to remote GPU servers through the network. Players can choose to use various types of GPU servers in Internet cafes, and Internet cafes do not need to manage game resources. Internet cafes only need to pay according to the player's usage time.",
      text2_1: 'How to apply to become an agent?',
      text2_2: 'You can contact us by joining Telegram:https://t.me/deeplinkglobal',
      text3_1: 'What benefits can agents get?',
      text3_2: 'Agents have exclusive rights in their area. Agents develop cloud platforms to serve Internet cafes and earn the price difference from each NFT',
      text4_1: 'Does the agent need to sign an agreement with DeepLink official?',
      text4_2: 'Yes, an agreement is required',
      text5_1: 'What impact does the NFT income of Cloud Internet Cafe have on the price of DLC?',
      text5_2: '40% of the NFT income of Cloud Internet Café will be put into the DLC-USDT trading pair in decentralized exchanges such as Uniswap to purchase DLC, and the purchased DLC will be destroyed',
      text6_1: 'How to ensure that the purchase of DLC with the NFT income of Cloud Internet Cafe is transparent?',
      text6_2: 'The number of issued NFTs of Cloud Internet Cafes can be queried on the chain, and the income of NFTs can be calculated through the number of issuance',
    },
    title5: 'Cloud Internet Cafes Partners'
  },
  gameFi: {
    title1: ' Cloud GameFi Platform',
    block1: {
      text1: 'Cloud GameFi Platform',
      text1_1: 'Any user can play GameFi games through DeepLink without downloading',
      text1_2: 'No restrictions on game downloads in any country, no need to download in the Apple Appstore',
      text1_3: 'Help GameFi game manufacturers expand more users',
      text2: 'Cooperation method of game manufacturers',
      text2_1: 'Cooperation on advertising ',
      text2_2: 'Cooperation on traffic',
      text2_3: 'Profit sharing on games',
      text2_4: 'Cooperation on NFT',
    },
    title2: 'GameFi Q&A',
    block2: {
      text1_1: 'What is Cloud GameFi platform?',
      text1_2: 'DeepLink will select high-quality game partners and add the games to the DeepLink cloud platform, so that users can play GameFi games directly through DeepLink without downloading the game',
      text2_1: 'Does the cloud GameFi platform support mobile games?',
      text2_2: 'We will support PC to play mobile games, and can open multiple game windows at the same time',
      text3_1: 'What are the benefits of cooperation between GameFi manufacturers and DeepLink?',
      text3_2: 'DeepLink will help partners bring brand exposure and bring more gamers',
      text4_1: 'Do GameFi manufacturers need to sign an official agreement with DeepLink?',
      text4_2: 'No, DeepLink will screen high-quality partners, and the games that pass the screening will be automatically uploaded to the DeepLink platform',
      text5_1: 'How to I apply to work with DeepLink?',
      text5_2: 'Email us to apply:',
    }
  },
  lanchpad: {
    cont1: {
      title1: 'DeepLink Protocol',
      desc: 'Decentralized AI cloud gaming protocol',
      title2: 'DeepLink Protocol makes cloud game development easier than ever',
      btn1: 'Enter PinkSale',
      btn2: 'Download Whitepaper',
      btn3: 'Download Pitch Deck',
      btn4: 'Welcome to DeepLink Public Sale',
      desc1: 'Support Deeplink get DLC Token',
      desc2: '100% Secure & Verified',
      desc3: 'NVIDlA Inception Program'
    },
    cont2: {
      desc1: 'BATTLEFIELD',
      desc2: 'Demonstrated by two computers, for example, 50 kilometers away. The controlling end is an ordinary laptop, and the controlled end is a high-end GPU computer. It displays a dynamic picture of the game within about 3-5 seconds.',
      btn: 'Play the video to learn more'
    },
    cont3: {
      text1: 'Ultra low latency',
      text2: 'High resolution',
      text3: 'Ultra low latency',
      text4: 'No lag, no disconnection, high stability',
      desc1: 'DeepLink Protocol achieves a revolutionary technological breakthrough, bringing an unparalleled perfect experience',
      desc2: 'The core technical team members have been working in the cloud gaming industry since 2011, including Cisco, Intel, Nvidia, Huawei, Shunwang and other companies. DeepLink was established on the foundation of more than 10 years of research and development.'
    },
    cont16: {
      title1: 'DeepLink Protocol provides ultra-low latency technical services for cloud games',
      title2: 'The products currently developed based on DeepLink Protocol include the following, and more than 20 applications are under development',
      text1: 'Remote control',
      text2: 'Cloud Gaming & Cloud Gaming Platform',
      text3: 'Cloud Internet Cafe',
      text4: 'CloudXR',
      text5: 'XXX Cloud VR Game',
      text6: 'YYY Cloud Game Platform'
    },
    cont4: {
      title1: 'DeepLink Protocol uses remote control tools as traffic entrance',
      title2: 'DeepLink software uses remote control tools as traffic portals, and cloud gaming platforms and cloud Internet cafes as monetization methods',
      text1_1: '600,000+',
      text1_2: 'MAU',
      text2_1: '110 +',
      text2_2: 'Number of countries where services are provided',
      text3_1: '1,400,000+',
      text3_2: 'Total number of users',
      text4_1: '200,000+',
      text4_2: 'DAU',
      desc1: 'Remote tools',
      desc2: 'Cloud Gaming & Cloud XR',
      desc3: 'Cloud Internet Cafe',
      btn1: 'YouTube KOL video 1',
      btn2: 'YouTube KOL video 2',
      btn3: 'YouTube KOL video 3',
      btn4: 'YouTube KOL video 4',
    },
    cont17: {
      title1: "DeepLink Protocol's business model",
      text1: 'Commission model',
      text2: 'License mode',
      desc1: 'DeepLink Protocol will charge a 20-30% commission for each cloud game transaction. The commission will be settled in DLC, and 100% of the DLC will be destroyed in real time',
      // desc2: 'For cloud Internet cafe and cloud e-sports hotel applications, each application and each computer terminal pay a license fee of US$6 per month. 40% of the fee is settled in USDT and the DLC will be repurchased and destroyed'
      desc2: 'For cloud Internet cafe and cloud e-sports hotel applications, each application and each computer terminal pay a license fee of US$6 per month.'
    },
    cont5: {
      title1: 'Allow miners to provide idle network traffic through blockchain technology',
      desc1: 'Nodes input',
      desc2_1: 'Equipment needs',
      desc2_2: 'Traffic forwarding',
      desc3: 'Miners over the world get DLC token rewards by purchasing NFT to provide traffic nodes',
      desc3_2: '',
      title2: 'Traffic nodes provider',
      text2: 'Miners provide traffic nodes', 
      title3: 'Users terminal',
      text3: 'Personal Users'
    },
    cont6: {
      title1: 'Greatly enhance the market competitiveness of DeepLink',
      title2: 'Through blockchain technology',
      desc1: 'Pledge digital currency through smart contracts to ensure that GPU providers provide stable services.',
      desc2: 'Everyone can contribute to the network and get rewarded. For example, providing GPU servers and providing traffic nodes.',
      desc3: 'Attract miners to provide traffic forwarding nodes, wide distribution of nodes without requirement of centralized companies to invest in machine deployment nodes, which is decentralized and has zero server cost.',
      desc4: 'Pledging digital currency through smart contracts, investors do not need to wait for 5-10 years, they can exit in about 1 year and get payback.',
      desc5: 'Users can participate in the dividends of project growth, which is conducive to expanding the spread.',
      desc6: 'Users make payment anywhere without limited border.',
      text1: 'Pledge',
      text2: 'Everyone involved', 
      text3: 'Zero cost',
      text4: 'Reduce payback time',
      text5: 'No border', 
      text6: 'User gets bonus'
    },
    cont7: {
      title1: 'We are currently engaging in a dimensional reduction attack of Web2 technology with Web3 technology.',
      desc1: 'To achieve a low-latency gaming experience in cloud gaming, in addition to technical advancements, it is necessary for GPU servers to be located within a range of 50 kilometers from the users. To achieve global coverage, more than 50,000 data centers would be required.',
      title2: 'Traditional centralized cloud gaming platforms.',
      title3: 'DeepLink is a cloud gaming platform based on blockchain technology.',
      text1: 'The platform purchases machines by itself, huge cost causes the payback date is far away.',
      text2: 'Machine coverage is small and cannot be covered globally.', 
      text3: 'Centralized technology cannot support tens of thousands of data center connections.',
      text4: 'Miners and Internet Cafe owners provide GPU servers to get token rewards. Miners have quick payback cycle, and the platform have no pressure to purchase machines.',
      text5: 'A large number of miners and Internet cafes can cover over the world.', 
      text6: 'Distributed blockchain technology supports more than 100,000 data centers and more than 10 million GPU server nodes.'
    },
    cont8: {
      title1: 'DeepLink Token Application Scenarios',
      text1: 'Buy DLC',
      text2: 'Pay DLC', 
      text3: 'Pay USDT',
      text4: 'Pay DLC',
      text5: 'Put DLC and USDT in DEX', 
      text6: 'Sell DLC',
      text7: 'User 1',
      text8: 'DeepLink software', 
      text9: '40% of DLC will be burned directly on the chain.',
      // text10: '1.Buy NFT',
      // text11: '2.Buy cloud gaming time', 
      // text12: '3.Rent Internet Cafe PC time',
      // text13: '4.Buy personal shared gaming time', 
      text10: '1.Purchase a license',
      text11: '2.Purchase cloud gaming hours', 
      text12: '3.Duration of renting an internet café machine',
      text13: '4.Purchase personal shared game time', 
      text14: '40% USDT will be repurchased in DEX and then burned', 
      text15: 'Buy NFT',
      text16: 'DEX and CEX',
      text17: 'Other trading exchanges',
      text18: 'Miner',
      text19: 'Liquidity mining reward',
      text20: 'Reward 2 years, 5 billion DLC per year'
    },
    cont8_new: {
      text7: 'Personal user',
      text12: '3.Duration for renting Internet Cafe machine',
      text21: 'Business users',
      text22: 'DeepLink Protocol',
      // text23: '1.Buy license',
      // text24: '2.10%-20%commission',
      text23: '1.Purchase licenses',
      text24: '2.20-30% Handling fee',
      text25: '100% of the DLC will be destroyeddirectly on-chain',
    },
    cont9: {
      title1: 'DeepLink Token Liquidity mining reward',
      text1: 'User 1',
      text2: 'Put DLC and USDT in DEX', 
      text3: 'Liquidity mining reward',
      text4: 'Reward 2 years, 5 billion DLC per year',
      text5: 'Put DLC and USDT liquidity on Uniswap and Pancake Swap', 
      text6: 'Rewards are distributed once a day and automatically received through NFT liquidity',
      text7: '80% of which are rewarded to liquidity providers, 20% rewarded to trader.',
      text8: '13,698,630 DLC rewards every day'
    },
    cont9_new: {
      title1: 'DeepLink Token Liquidity mining reward',
      text1: '5% in DLC as well as equivalent values in USDT、ETH and BNB.',
      text2: 'Injected into DEX', 
      text3: 'Enhancing the liquidity of the DEX',
      text4: '',
      text5: 'This 5% of tokens will be sold during the IDO (lnitial DEX Offering). Based on the actual proportion sold, the entirety will eventually be injected into the DEX', 
      text6: 'Injecting DLC and USDT liquidity into DEXs like Uniswap, SushiSwap, Pancake Swap, etc',
      text7: 'This liquidity will be locked for 5 years, and after that, it will be used for the long-term development of the project',
      text8: ''
    },
    cont10: {
      title: 'Roadmap & Plan',
      desc1: 'October 2020',
      text1: 'DeepLink project initiation.',
      desc2: 'March 2021',
      text2: 'Securing seed funding.',
      desc3: 'August 2021',
      text3: 'Release of the first internal development version of DeepLink, achieving a technical latency of 3ms and supporting game keyboards and mouse.',
      desc4: 'May 2022',
      text4: "Release of the first test version of DeepLink's remote control feature, supporting 3K 144Hz displays.",
      desc5: 'August 2022',
      text5: "Release of the third test version of DeepLink's remote control feature, supporting virtual displays.",
      desc6: 'October 2022',
      text6: 'Launch of the official version of DeepLink, supporting Korean, Chinese, and English languages, and establishment of DeepCloudX company in Singapore to operate DeepLink.',
      desc7: 'December 2022',
      text7: 'Securing angel funding.',
      desc8: 'April 2023',
      text8: 'Collaboration with the largest chain of internet cafes in South Korea, setting up a real environment showroom in the cafes for cloud internet cafe testing, receiving positive user feedback.',
      desc9: 'June 2023',
      text9: 'DeepLink surpass 200,000 downloads, with users in 100 countries and support for 8K 60Hz displays.',
      desc10: 'January 2021',
      text10: 'Determining the research and development direction of DeepLink, and starting product design and development.',
      desc11: 'April 2021',
      text11: 'Completion of the core team formation, with team members who have been in the cloud gaming industry since 2011, including founding team members from companies such as Cisco, Intel, Nvidia, Huawei, and Shunwang.',
      desc12: 'March 2022',
      text12: 'Testing DeepLink in the internet cafe industry, achieving a technical latency of 1ms.',
      desc13: 'July 2022',
      text13: "Release of the second test version of DeepLink's remote control feature, supporting privacy screens and remote file copying.",
      desc14: 'September 2022',
      text14: "Release of the fourth test version of DeepLink's remote control feature, supporting multiple streaming and 444 true color.",
      desc15: 'November 2022',
      text15: 'Set up 10 mini PC machines and display simulations in Seoul for cloud internet cafe testing.',
      desc16: 'February 2023',
      text16: 'DeepLink surpass 100,000 downloads, with users in 50 countries and support for 4K 200Hz displays.',
      desc17: 'May 2023',
      text17: 'DeepLink surpass 150,000 downloads, with users in 80 countries and support DLC wallet, as well as support Vietnamese and Japanese languages.'
    },
    cont11: {
      title: 'Development Plan',
      desc1: 'July 2023',
      text1: 'Support the device list and referral reward feature.',
      desc2: 'August 2023',
      // text2: 'Support NFT functionality and officially launch the first cloud internet cafe in Seoul.',
      text2: 'Launch the first cloud internet cafe in Seoul.',
      desc3: 'October 2023',
      // text3: 'Support personal computer and internet cafe computer sharing, with the goal of reaching over 400,000 downloads for DeepLink.',
      text3: 'Support sharing reward function',
      desc4: 'December 2023',
      // text4: 'Launch the GameFi platform, supporting Arabic, German, French, Spanish, Thai, and Turkish languages.',
      text4: 'Support NFT functionality',
      desc5: 'Q1 2024',
      text5: 'Support game sharing feature for the WEB version and establish partnerships with target to reach over 0.5 million downloads for DeepLink',
      desc6: 'Q2 2024',
      text6: 'Target to reach over 1 million downloads for DeepLink, with 10 cloud internet cafes.',
      desc7: 'Q3 2024',
      text7: 'Target to reach over 2 million downloads for DeepLink, with 3,000 shared computers and partnerships with 50 cloud internet cafes',
      desc8: 'Q4 2024',
      text8: 'Support iOS, Mac, Vision Pro AR versions, with the goal of reaching over 3 million downloads for DeepLink, 10,000 shared computers, and partnerships with 150 cloud internet cafes.',
      desc9: '2025',
      text9: 'Support multiple brands of VR and AR devices, with the goal of reaching over 6 million downloads for DeepLink, 20,000 shared computers, and partnerships with 300 cloud internet cafes.',
      desc10: '2026',
      text10: 'With the goal of reaching over 15 million downloads for DeepLink, 50,000 shared computers, and partnerships with 800 cloud internet cafes.'
    },
    cont12: {
      text: '60 billion will be issued on the DBC chain,30 billion will be issued on the ETH chain, and 10 billion will be issued on the BSC chain.',
      text1: 'Investment',
      text1_1: 'Seeding Round',
      desc1_1: 'Unlocked 60 days after listed on CEX exchange, spread over 20 months, 5% unlocked per month',
      text1_2: 'Angel Round',
      desc1_2: 'Unlocked 30 days after listed on CEX exchange, spread over 10 months, 10% unlocked per month',
      text1_3: 'A Round',
      desc1_3: 'Unlocked 30 days after listed on CEX exchange, spread over 10 months, 10% unlocked per month',
      text1_4: 'PreSale and Public Sale',
      desc1_4: '40% unlock after listing, and the remaining will start unlocking on August 30th, with a 6-month unlocking period, during which 10% will be unlocked each month.10 to 30% of the fundraising amount will be added to DEX liquidity (locked for 3 years).',
      text2: 'Team',
      desc2: 'Unlocked 60 days after listed on CEX exchange, spread over 20 months, 5% unlocked per month',
      text3: 'Community Airdrop',
      desc3: 'Complete certain task requirements and pass DID KYC Verification to get airdrops, 80% is locked for one year.',
      text4: 'NFT Sales',
      desc4: 'NFT binds software license rights and gives away TOKEN,and The tokens will begin to unlock 30 days after being listed on the CEX exchange,with 80% locked for one year',
      text5: 'Mining',
      // desc5: '10% liquidity mining, 5% miners provide gpu computing power, 10% bandwidth mining is halved every 5 years. 10% of NFT revenue goes into mining rewards',
      desc5: '16% is for miners providing GPU power, and 4% is for bandwidth mining, which halves every 4 years. Mining will start 30 days after being listed on the CEX exchange.',
      text6: 'Foundation',
      desc6: 'The tokens will begin to unlock 60 days after being listed on the CEX exchange, with a total unlock period of 2 years,during which 12.5% will be unlocked each quarter.',
      text7: 'Consultant and Legal',
      desc7: 'Used to solve legal issues in different countries and rewards for consultants，unlocking will start 30 days after being listed on the CEX exchange,divided over 10 months,with 10% being unlocked each month.',

      text8: 'Total',
      text9: 'Quantity (billion)：',
      text10: 'Description：',
      text11: 'In circulation (billion) ：',
      text12: 'Not in circulation (billion) ：',
      text13: '100 billion',
    },
    cont13: {
      title: 'Team and Advisors',

      intro1: 'DeepLink CEO',
      intro2: 'Co-founder of DeepLink',
      intro3: 'DeepLink COO',
      intro4: 'DeepLink CBO & India Market Leader',
      intro5: 'Advisor',
      intro6: 'Advisor',
      intro7: 'Advisor',
      intro8: 'Co-Founder & CMO',
      user1_1: 'Serial entrepreneur with 13 years of experience in AI, 7 years in blockchain and distributed computing network technology development.',
      user1_2: "Graduated with a Bachelor's degree in Computer Science from China Ocean University in 2008.",
      user1_3: "In 2012, he founded Jushang Intelligent and developed the world's first Chinese AI voice assistant called 'Intelligent 360.'",
      user1_4: "Within three years, the software had over 17 million downloads. In 2014, he launched the world's first AI speaker, 'Xiao Zhi Speaker.'",
      user1_5: 'In May 2017, he founded DeepBrainChain, serving hundreds of AI, cloud gaming, and cloud internet cafe clients.',
      user2_1: 'Serial entrepreneur, and senior cloud gaming expert. Former Dean of Shunwang Cloud Gaming Research Institute, responsible for technical research and development.',
      user2_2: "Graduated with a Bachelor's degree in Computer Science from Zhejiang University in 1999. From 2001 to 2004, he worked at Zhejiang Lande, involved in telecommunications-related work.",
      user2_3: "In 2009, he founded China's first dual-core browser, 'Sunflower Browser.'",
      user2_4: "From 2010 to 2021, he joined the listed company Shunwang and founded Shunwang Cloud Computer, serving as the Dean of Shunwang Research Institute. He provided cloud internet cafe solutions and operated cloud gaming, making his company the first in China to offer cloud gaming solutions.",

      user3_1: 'Education: 2007 Peking University, Department of Chinese',
      user3_2: "2023: DeepLink COO",
      user3_3: "2022-2023: DeepBrain Chain Chief Director",
      user3_4: "2019-2021: CELLOGIN Overseas Market Leader",
      user3_5: "2018: FLOW FACTORY CEO",

      user4_1: 'Education and Certifications: - Bachelor of Business Administration degree earned in 2020 in University of Mysore.',
      user4_2: "- Certification in digital marketing.",
      user4_3: "- Certification in SEO.",
      user4_4: "- Certification in blockchain.",
      user4_5: "Sales and Marketing at Technic Constructions: - Sales and Marketing role held in 2019, achieving $500k in sales within a three-month period.",
      user4_6: "Business Development Associate at BYJU'S: - Employed from 2020 to 2021.",
      user4_7:  "- Played a key role in driving growth, forging partnerships, and streamlining operations for educational technology initiatives at a unicorn startup valued at $10 billion.",
      user4_8: "Co-founder of Space Inn: - Co-founded an independent project focused on developing entrepreneurial skills, strategic thinking, and business acumen.",
      user4_9: "Stock Market Investing/Trading: - Accumulated five years of experience in stock market investing and trading, demonstrating expertise in navigating financial markets and capitalizing on investment opportunities.",
      user4_10: "Internships in Corporate Companies: - Completed multiple internships in corporate companies, gaining valuable insights into corporate culture, efficient processes, and effective business strategies.",
      user4_11: "Crypto Industry Experience: - Worked on multiple crypto and NFT projects, gaining valuable industry experience.",
      user4_12: "- Contributed to Empowa, a prominent Real-Fi Cardano project, driving its development and growth. Empowa, a Cardano project in 2022, contributing to its growth.",
      user4_13: "- Currently working with DeepLink Cloud, expanding expertise in the crypto space.",

      user5_1: 'Working as Vice President at Hyunjin ICT, an IoT and communication service company since 2012, for 11 years. ',
      user5_2: "Sales and marketing management, experience in performing multiple large-scale projects in public institutions.",
      user5_3: "Information strategy consulting, Business innovation project, and SW development at Samsung SDS, the largest IT company in Korea, for 14 years (1998~2012). ",
      user5_4: "Worked for 3 years (2017~2019) as a strategic planning executive at Coinzest, a cryptocurrency exchange with experience of being ranked 1st in Korea and 10th globally. ",
      user5_5: "The founder of Hyunjin Venus, which operates welfare shopping malls for large Korean corporations and public institutions, and has operated the business for 5 years.",
      user5_6: 'Qualified PMP (Project Management Professional) and CISA (Certified Information Systems Auditor).',

      user6_1: 'Education: 2003 Guanghua School of Management, Peking University',
      user6_2: "2023: MetABC CEO",
      user6_3: "2020: HillstoneHub CEO",
      user6_4: "2019: Hillstone Business Center CEO",
      user6_5: "2019: Hillstone Global (blockchain) COO",
      user6_6: "2017: Foundersbridge Founder",
      user6_7: "2017: Hillstone Partners (Private Equity) Partner",
      user6_8: "2014: BrightA Consulting Co-founder ",
      user6_9: "2012: Senior Consultant (Chinese Business Expert)",

      user7_1: "Joseph Alexander is the creator of ChatDBC.com, Deep Brain Chain's custom large language model.",
      user7_2: 'His speciality is North American business development for DeepLink and DBC.' ,
      user7_3: "You can follow {'@'}ChatDBC on Twitter to stay informed on all DeepLink and DBC news.",
      
      user8_1: "Maryna Barysheva is a COO of LKI Consulting - award-winning Web3 Marketing Agency, specializing in deep tech, DeFi, infrastructure, and gaming projects. ",
      user8_2: "She has successfully scaled blockchain projects from 23+ countries, managed $100K- 10M marketing budgets and ran marketing for a $100M-valued B2B tech startup.",
      user8_3: "Maryna's expertise lies in community growth, brand awareness, and global GTM strategies. ",
      user8_4: "She is an International public speaker making frequent appearances at crypto conferences worldwide and an Incubator Mentor for AI, Tech, and Blockchain projects at University of Helsinki.",

      user9_1: "Brando is a veteran of crypto. In 2016, he started investigating cryptocurrency, which led to the famous 2017 bull market.",
      user9_2: "He founded Bigger Than Race™️ in 2012 and rebranded it into what it is today in 2017.",
      user9_3: "His qualifications include Certification in Web3/Metaverse through EveryRealm, a venture fund and leader in Metaverse innovation and development, and Certified in Blockchain and Bitcoin through IBM.",
      user9_4: "BRANDO ‘THE CRYPTO WIZARD’ MURPHY ",
      user9_5: "His primary vision and goal are to help people become financially free through Blockchain technology and to be able to invest and own the infrastructure of the new economy of the 4th and 5th Industrial Revolutions. The BTR Community VC is assisting loyal members globally in owning the tech of tomorrow.",
      user9_6: "In 2024, he became Co-Founder and CMO of DeepLink Protocol, an Ai cloud gaming technology that levels the playing field for gamers worldwide. Los Angeles, CA, is where he calls home.",

      user10_1: "Summer is the creator and host of FireHustle - a media company that covers crypto projects, investment strategies, and more. Summer has an education in business with experience in broadcasting and communications.",
      user10_2: "On the media side, the property portfolio includes a YouTube channel with 44,000+ subscribers, a dedicated Discord community of over 3,000 members, a  private membership group, and more.",
      user10_3: "On the investing side, FireHustle has personally invested in 50+ web3 start ups and serves as marketing, fundraising, and advisory partner to many crypto projects, VC firms, launchpads, syndicates, KOL groups, and more.",
    
      user11_1: "Manny is a blockchain enthusiast and expert with over a decade of experience in the industry, contributing to leading companies and initiatives since 2013.",
      user11_2: "His Career Background Includes: Foundational role at Grayscale, Operational team at Coinbase, Marketing team at DYDX and Ledger, and finally, a role in Binance US.",
      user11_3: "Academic credentials: Blockchain and cryptocurrency-related courses from prestigious institutions in the U.S., particularly from Stanford University, including Blockchain and Cryptocurrency: A Comprehensive Introduction, Advanced Blockchain Strategies, Chief Executive Officer Program, and Chief Marketing Officer Program. He holds a major in Finance and Business Administration.",
    },
    cont14: {
      title: 'Our Early Stage Investors',

      organ1: 'DeepBrain Chain Foundation was established in 2017. In January 2018, DBC Token was listed on Huobi Exchange. The main network was launched in 2021. DeepBrain Chain is a high-performance distributed computing power network with GPU as the core. The vision is based on blockchain technology Construct a distributed high-performance computing power network with unlimited expansion, and become the most important computing power infrastructure in the era of AI+Metaverse. It mainly provides GPU computing services for artificial intelligence, cloud games, movie rendering and other fields. At present, it has served hundreds of artificial intelligence and cloud game-related companies and more than 30,000 AI practitioners',
      organ2: "Gobi Partners is a professional venture capital firm with offices in Shanghai, Beijing, and Southeast Asia. They specialize in investing in early-stage technology projects in China. Gobi Fund's strategic investors include IBM, Sierra Ventures, The McGraw-Hill Companies, and Steamboat Ventures (Disney's venture capital division). They have previously invested in well-known startups such as Tuniu, Camera360, and Zhuyun. Gobi Partners is an established investment fund in the industry",
      organ3: "Hycons is a GPU computing cloud platform based on DBC technology developed by Hyunjin ICT. Hycons stands out with its DBC-based distributed high-performance network technology and DeepLink-based lag-free HD streaming technology. The platform provides comprehensive services such as membership management, registration and payment for different service types such as enterprises, schools, research institutions and franchisees. Currently, more than 1,000 GPU cards and 2 centers are in operation in Korea, and it is planned to expand to 5 centers in the future. In addition, Hycons is testing it by applying it to the franchise business of Internet cafes. With advanced technology foundation, competitive cloud service rates and automated service capabilities, Hycons aims to become the world's leading GPU computing platform.",
      organ4: 'ROCK Capital is a specialized cryptocurrency fund company founded in Korea in 2018, bringing together professional resources in finance and investment, as well as blockchain and decentralization experts. With extensive collaboration with various domestic and international businesses, global projects, governments, institutions, and other economic entities, ROCK have successfully executed over 100 projects, pioneering diverse areas of future finance. Through strengthened collaboration, growth, activation, and partnerships, ROCK possesses rich experience and expertise in diverse market environments. Upholding unwavering principles like a rock, ROCK strive to generate high returns, expand investment opportunities, and maximize capital activities, creating concrete customer experiences.',
      organ5: 'A Decentralized blockchain focused on emerging technology that will power the 4th and 5th revolution. The VC has a wealth of knowledge from different beings from all walks of life, which aids them in capturing opportunities throughout the web3 space and beyond. They have invested alongside known names like Bloktopia, Animoca Brands, Stripe, etc. They have secured relationships with the singularityNet and Shiba ecosystems and various gaming studios. Their VC is grassroots and accessible to those holding a BTR-Army pass.'
    },
    cont15: {
      title: 'Community events',
      name1: 'Vietnam MARVELS HCMC DeepLink',
      name2: 'Vietnam Web3 event speech',
      name3: 'Deeplink Hong Kong Events',
      name4: 'IIITB Indian University DeepLink',
      name5: 'IIT Sri City Indian University DeepLink',
      name6: 'IIT Tirupati Indian University DeepLink',
      name7: 'NIT Warangal Indian University DeepLink',
      name8: 'DeepLink Singapore Events',
      name9: 'DeepLink Singapore Events',
      name10: 'Vietnam ESPORTS3.0 EVENT',
      name11: 'DEEPLINK Investor Day in Seoul, South Korea',
      name12: 'DEEPLINK Investor Day in Tokyo, Japan',

      name13: 'DEEPLINK in the TEAMZ/WEB3 SUMMIT, Tokyo Japan',
      name14: 'DEEPLINK Sponsors the HTX Dubai Whale Night',
      name15: 'DEEPLINK at the Lbank Dubai Night',
      name16: 'DEEPLINK Sponsors the HOT Crypto Twist with MTCAPITAL and Tido Capital',
      name17: "DEEPLINK and Animoca's Dubai Game Night",
      name18: 'DeepLink at the Token2049 Dubai afterparty',
      name19: "DEEPLINK's presentation at BYBIT event",
      name20: "DeepLink participated in the 2024 Tokyo Blockchain Expo",
      name21: 'Brando, Advisor to DeepLink, Presents Keynote Address at Depin House Consensus Conference Hosted by IOTX',
      name22: "DeepLink Principal Consultant is attending the Investor Conference Forum in Austin, USA",
      name23: "DeepLink’s Cornerstone Investor Mr. Adam Ha, participated in the DEPIN ALLIANCE YACHT PARTY ⛵️panel and introduced DeepLink to the Vietnam market",
      name24: "We co-organized a blockchain event in Seoul with {'@'}DeepBrainChain and ETNEWS, Korea's top IT news media.",
      name25: "DeepLink Protocol Successfully Participates in Stanford 'The Future of Decentralized AI' Summit."
    }
  },
  homeNew: {
    cont1: {
      text1: 'Redefine remote games',
      text2: 'Remote control without delay can be achieved with DeepLink',
      text3: 'Now only supports Windows environment'
    },
    cont2: {
      title: 'Introduction to remote control',
      block1: {
        title: 'What is remote control',
        text: 'The act of remotely controlling computers or machines in different locations through the Internet'
      },
      block2: {
        title: 'Remote control type',
        text: 'Remote control is divided into client/server-based remote control and P2P point-to-point remote control'
      },
      block3: {
        title: 'Remote control function',
        text: 'You can share files between multiple computers, help secure remote education, and more'
      },
      block4: {
        title1: 'DeepLink',
        title2: ' Advantages of remote control',
        text: 'Through blockchain technology, we solve the problem of decentralization and provide the best gaming experience with e-sports grade ultra-low latency and no interruptions'
      }
    },
    cont3: {
      title: 'Traditional remote control tools make simple tasks difficult to complete',
      text1: 'Connection delay',
      text2: 'Frequently disconnected',
      text3: 'Frame rate drops',
      text4: 'Expensive fees'
    },
    cont4: {
      title1: "Now it's like controlling a local computer",
      title2: 'Smooth!',
      desc: 'Solve distributed problems through smart contract technology and experience fast and powerful remote control functions',
      text1: 'Easy and quick access',
      text2: 'Support 8K',
      text3: 'High performance game optimization',
      text4: 'No interruption',
      text5: 'Optimization of work tools',
      text6: 'Free to use'
    },
    cont5: {
      title1: 'Strong and smooth',
      title2: 'Remote game',
      title3: 'Can be used anytime and anywhere',
      text1: 'Battlefield',
      text2: 'League of Legends',
      text3: 'VALORANT'
    },
    cont6: {
      title1: 'Heavy and expensive gaming laptop',
      title2: 'And DeepLink',
      title3: 'Lightweight and affordable',
      title4: '',
      text1: 'At least 1,400 dollar',
      text2: 'At least 1.6kg or more',
      text3: 'High performance notebook',
      text4: '0 dollar'
    },
    cont7: {
      title1: 'Various ways to use',
      title2: 'Very versatile',
      block1: {
        text1: 'External',
        text2: 'for work',
        text3: 'Notebook'
      },
      block2: {
        text1: 'Office, home',
        text2: 'High performance',
        text3: ' Desktop machine',
        text4: 'High performance work',
        text5: 'High performance gaming'
      },
      block3: {
        text1: 'Customer',
        text2: 'Work together',
        text3: 'Remote office'
      },
      title3: 'While supporting powerful technology',
      title4: 'Ensuring comprehensive security',
      block4: {
        text1: 'Account security',
        text2: 'Based on blockchain technology',
        text3: 'Multiple account protection mechanism'
      },
      block5: {
        text1: 'Connection security',
        text2: 'DTLS1.2 protocol protection'
      },
      block6: {
        text1: 'Data security',
        text2: '256 BIT AES encryption'
      },
      block7: {
        text1: 'Machine security',
        text2: 'User permissions can be set'
      }
    },
    cont8: {
      title1: 'Using DeepLink',
      title2: 'Connect anytime, anywhere',
      text1: 'As long as there are nodes, you can play high-performance games remotely and seamlessly. Connect your blockchain node computer and play online high-performance games from any country or location',
      title3: 'Use DeepLink flexibly',
      title4: 'Collaborative work',
      text2: "DeepLink's ultra-low-latency remote connections enable stronger collaboration. Optimized for designers, planners and developers"
    },
    cont9: {
      title1: 'Not just a game',
      title2: 'It also provides productivity tools for employees'
    },
    cont10: {
      title1: 'With DeepLink',
      title2: 'Get rid of space and technology constraints',
      block1: {
        name: 'Hyun Choi',
        desc: 'IT Operations and Remote Maintenance Manager',
        text1: 'Remote work support without delay',
        text2: 'Improved customer satisfaction'
      },
      block2: {
        name: 'Sangwo Baek',
        desc: 'Gamer',
        text1: 'Now you only need a sales laptop to play high-performance games on the road',
        text2: 'I never thought it was possible'
      },
      block3: {
        name: 'Junho Kim',
        desc: 'sales representative',
        text1: 'No device restrictions',
        text2: 'I can conduct business using only my laptop'
      },
      block4: {
        name: 'Min Kyung Yoo',
        desc: 'remote work designer',
        text1: 'I can work anytime, anywhere, no need to download',
        text2: 'Connect to headquarters computer for work'
      },
    },
    cont11: {
      title: 'What is DeepLink Wallet',
      text1: 'Secure account protection system',
      desc1: 'DeepLink wallet uses blockchain technology, 256-bit AES encryption and a multi-layer account protection mechanism based on device information to support a robust and secure account protection system',
      text2: 'Trade and use tokens securely',
      desc2: 'Wallets enable more secure token transactions and usage compared to transactions via smart contracts and peer-to-peer cryptocurrency transfers',
      text3: 'DeepLink can be used around the world',
      desc3: 'As long as you have the wallet private key, you can log in to your private wallet and play games through DeepLink from anywhere in the world'
    },
    cont12: {
      title1: 'Ultra-low latency remote gaming',
      title2: 'Start DeepLink now!',
      text1_1: 'Please download',
      text1_2: 'DeepLink Client',
      text2_1: 'Register as a DeepLink member',
      text2_2: 'Need to generate a personal wallet',
      text2_3: '',
      text3_1: 'After wallet generation',
      text3_2: 'Access the required device and start DeepLink',
      text3_3: ''
    }
  },
  cloudInternet: {
    title: 'How to Become a Cloud Internet Café Solution Provider',
    text: 'Currently, major internet café distribution countries globally include South Korea (6,000), China (40,000), Brazil (10,000), Indonesia (20,000), the Philippines (20,000), Vietnam (15,000), Turkey (5,000), as well as India, Mexico, the Middle East, South America, Africa, Europe, and other countries. Globally, there are over 150,000 internet cafés. However, the internet café industry has a history of 20 years and has become a traditional industry. The payback period has increased to 3-4 years, and it faces various risks. For example, the COVID-19 pandemic has led to the closure of a large number of internet cafés. Therefore, the internet café industry needs a new solution to reduce the payback period, mitigate risks, and improve operational efficiency. Thus, the cloud internet café solution is born.',
    cont2: {
      text1: 'What is ',
      text2: 'A Cloud Cybercafe Solution?',
      text3: 'A cloud cybercafe solution is a new type of cybercafe service model with ultra-low latency rendering technology. In a cloud cybercafe, games and applications are not installed directly on local computers but hosted on cloud GPU servers. Users access and play games through cloud services without the need for high-performance local hardware.',
      block1: {
        text1: 'Reduce Local Hardware Requirements',
        text2: 'Since games and applications run on cloud GPU servers, local computers do not require high-performance GPUs or large storage space. This reduces cybercafe hardware investment and maintenance costs.'
      },
      block2: {
        text1: 'Instant Access to Latest Games',
        text2: 'Users can access the latest games instantly without waiting for download and installation. All game updates and maintenance are done in the cloud, providing users with convenient and efficient experiences.'
      },
      block3: {
        text1: 'High-Performance Gaming Experience',
        text2: 'Local monitors only need to be equipped with mini PCs (around $100), and cloud cybercafes can still provide high-performance gaming experiences. This is because game computing is done on remote high-performance GPU servers.'
      },
      block4: {
        text1: 'Space and Energy Saving',
        text2: 'Lower-configured local computers occupy less space and consume less power, helping to reduce cybercafe operating costs.'
      },
      block5: {
        text1: 'Easy Management and Maintenance',
        text2: 'Cybercafe managers can more easily manage software and games because all content is centrally managed in the cloud. This reduces local maintenance work and potential technical issues.'
      }
    },
    cont3: {
      text1: 'Glowsteam Cloud Cybercafe in South Korea',
      text2: 'Actual Operating Environment of Cloud Cybercafe'
    },
    cont4: {
      text1: 'Cloud Cybercafe Solution Can Help Cybercafe Investors',
      text2: 'Increase Net Profit by 3 Times and Shorten Payback Period by 40%',
      text3: 'Click to Download Comparative Data'
    },
    cont5: {
      text1: 'How Do Cloud Cybercafe Solution Providers Profit?',
      text2: 'If the profit is calculated as 12% of the earnings per cybercafe machine, then servicing a total of 1000 cybercafes with 100,000 machines, each machine being used an average of 10 hours per day, and with an average cost of $0.8 per machine per hour, 12% equals approximately $0.1.',
      text3: 'The annual profit of a cloud cybercafe solution provider is',
      text4: '100,000 * 10 * 0.1 * 365 = $36.5 million',
      text5: 'How to Become a Cloud Cybercafe Solution Provider',
      text6: "Based on DeepBrainChain and DeepLink technologies, South Korea's HYCONS has developed a complete cloud cybercafe solution. DeepBrainChain provides GPU computing power, DeepLink offers ultra-low latency rendering technology, and HYCONS delivers the entire solution. Analogously, if we compare it to a car, DeepBrainChain provides the engine, DeepLink provides the autonomous driving system, and HYCONS provides the complete car solution. Local solution providers in various countries only need to integrate their local payment systems into HYCONS to provide services to local cybercafes, and solution providers can set their own brands. HYCONS acts solely as a technology service provider without exposing its brand.",
      text7: 'For more information, please contact:',
      text8: "service{'@'}deepbrainchain.org"
    },
      cont6: {
      title: 'Download Cloud Cybercafe Related Materials',
      text1: 'HYCONS Introduction',
      text2: 'Cloud Cybercafe Solution Profit Analysis',
      text3: 'Cloud Cybercafe Data Center Architecture Diagram',
      text4: 'Cloud Cybercafe Solution Testing and Installation Manual',
      text5: 'DeepLink Protocol Introduction'
    }
  },
  reward2: {
    desc: {
      title1: 'DeepLink Nodes',
      text1: 'Only Node owners can participate in CPU traffic mining and personal GPU machine mining. The total reward is 12% of DLC Tokens, 4% for CPU traffic nodes, and 8% for personal GPU nodes. The total token amount is 12 billion. The output per node is related to the actual number of nodes sold. If all are sold, each node will produce 100,000 DLC. If only 10% of the nodes are sold, each node will produce 1 million DLC.',
      title2: 'Total Nodes: 120,000 No additional issuance',
      text2: 'Each Node receives additional airdrops',
      text3: 'The minimum amount of DLC mined per Node',
      text4: 'Initial price',
      text5: 'Maximum price'
    },
    table: {
      tier: 'Tier',
      license: 'Node License Price (USDT)',
      total_num: 'Total Licenses',
      total_usdt: 'Total USDT',
      buy: 'Buy'
    },
    intro: {
      title: 'Node Mining Introduction',
      text1_1: 'The more network users DeepLink has, the more network traffic and computing power it requires, which leads to the need for more CPU traffic machines and more GPU computing machines to provide infrastructure for the DeepLink network, achieving decentralization and helping DeepLink grow faster. By purchasing node authorization, you have the opportunity to participate in mining and earn rewards. Have any questions? Come',
      text1_2: 'and',
      text1_3: 'chat with us above.',
      text2: 'Each node is issued in the form of an NFT and additionally receives an airdrop of 30,000 DLC. The airdrop begins before the listing on the exchange, with 20% of the airdrop DLC unlocked immediately and 80% unlocked over 8 months. Download the DeeLink Software to create a DLC wallet address:',
      text3: 'Nodes cannot be resold to others before October 31, 2024.',
      text4: '25% of the mined tokens are unlocked immediately, with 75% unlocked linearly over 150 days.',
      text5: 'If mining does not start within 10 days after the listing, rewards will be distributed through airdrops, with airdrops occurring every 7 days. The airdrop rules are the same as the mining output rules, with a total daily output of 4.1 million DLC evenly distributed based on the total number of nodes sold. 25% unlocks immediately, and 75% unlocks linearly over 150 days. If mining has already started, corresponding machines are required to receive rewards.'
    },
    question: {
      ques1: 'How many Node NFTs are sold in total?',
      ans1: 'A total of 120,000',
      ques2: 'How much does each Node NFT cost?',
      ans2: 'It is divided into 10 tiers, with prices ranging from 30U to 155U, sold on the official website until sold out',
      ques3: 'Is purchasing a Node NFT a one-time payment?',
      ans3: 'Yes, purchasing a Node is a one-time payment, and no additional fees are required in the future',
      ques4: 'Are there additional airdrops for purchasing a Node NFT?',
      ans4: 'Yes, each Node owner receives an airdrop of 30,000 DLC. The airdrop starts before listing on the exchange, with 20% of the DLC unlocked immediately and 80% unlocked over 8 months',
      ques5: 'What is the specific mining mechanism?',
      ans5: 'The total reward is 12 billion DLC. The total earnings for traffic nodes are 4 billion DLC, and the total earnings for personal GPU machine nodes are 8 billion DLC. By purchasing a Node NFT, you can participate in both types of mining simultaneously. The reward for the first 4 years is 1.5 billion DLC per year, with halving every 4 years. The total daily reward is 4.11 million DLC, distributed only to Node NFT owners. 25% of the mined tokens are unlocked immediately, with 75% unlocked linearly over 150 days',
      ques6: 'Can a purchased Node NFT participate in both CPU traffic machine and personal GPU machine mining?',
      ans6: 'Yes',
      ques7: 'What is a CPU bandwidth traffic machine node?',
      ans7: 'The CPU bandwidth traffic machine node undertakes the core task of computation and traffic forwarding in the DeepLink network. Through the mining model, DeepLink allows more people to participate and earn rewards. The total earnings for traffic nodes are 4 billion DLC',
      ques8: 'What is the total reward for bandwidth traffic machines in the first year?',
      ans8: 'The first-year reward is 500 million DLC, with approximately 1.37 million DLC rewarded daily',
      ques9: 'How does the bandwidth of each CPU bandwidth traffic machine node relate to its earnings?',
      ans9: 'The base bandwidth is 10Mb uplink bandwidth. The bandwidth value is 10 for 10Mb uplink bandwidth. The bandwidth is only valid in multiples of 10, for example, 45Mb uplink bandwidth is calculated as 40 in bandwidth value. Bandwidth increases by 30% according to actual usage. Finally, earnings are divided according to the actual bandwidth value',
      ques10: 'Are the configuration requirements for bandwidth traffic machine nodes high? Is there any configuration information? How do you participate in mining?',
      ans10: 'The configuration requirements are not high. vCPU 1C + 2G memory + 50G hard disk. Renting on AWS costs around 10 USD per month, with bandwidth charged according to actual usage. After renting a CPU virtual machine on AWS, install the DeepLink traffic node mining program, create a wallet address, transfer the Node NFT to the wallet, and activate the mining function to participate in mining',
      ques11: 'What is a personal GPU machine node?',
      ans11: 'Individuals can offer their GPU computers to participate in mining. The GPU machines provide cloud gaming infrastructure services for the DeepLink Protocol. The total earnings for personal GPU machine nodes are 8 billion DLC',
      ques12: 'What is the total reward for personal GPU machine nodes in the first year?',
      ans12: 'The first-year reward is 1 billion DLC, with approximately 2.74 million DLC rewarded daily',
      ques13: 'What are the machine configuration requirements for personal GPU machine nodes? How do you participate in mining?',
      ans13: 'The configuration requirements for GPU machines are: CPU frequency of at least 3.0GHz, GPU from Nvidia Geforce series: 20xx, 30xx, 40xx, and at least 16G of memory'
    }
  }
}