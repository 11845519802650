<template>
  <div class="gameFi" :class="{lan_En : lan != 'zh'}">
    <div class="content content1">
      <div class="cont1">
        <div class="icon1"></div>
        <div class="icon2"></div>
        <div class="icon3"></div>
        <div class="icon4"></div>
        <div class="title">{{ $t('gameFi.title1') }}</div>
        <div class="introduct">
          <div class="top">{{ $t('gameFi.block1.text1') }}</div>
          <div class="desc">
            <div class="li">{{ $t('gameFi.block1.text1_1') }}</div>
            <div class="li">{{ $t('gameFi.block1.text1_2') }}</div>
            <div class="li">{{ $t('gameFi.block1.text1_3') }}</div>
          </div>
          <div class="method">{{ $t('gameFi.block1.text2') }}</div>
          <div class="list">
            <div class="li">{{ $t('gameFi.block1.text2_1') }}</div>
            <div class="li">{{ $t('gameFi.block1.text2_2') }}</div>
            <div class="li">{{ $t('gameFi.block1.text2_3') }}</div>
            <div class="li">{{ $t('gameFi.block1.text2_4') }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="content content2">
      <div class="cont2">
        <div class="QA_cont">
          <div class="name">{{ $t('gameFi.title2') }}</div>
          <div class="list">
            <div v-for="item in 5" data="" ref="TagList" class="li" :class="{ active : index == item }" @click="chooseQuestin(item)">{{ $t(`gameFi.block2.text${item}_1`) }}</div>
            <div ref="TagText" class="text"> {{ text }} <span v-if="index == 5" href="https://t.me/deeplinkglobal" target="_blank">service@deeplink.cloud</span>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { defineComponent, computed, ref, onMounted, watch } from "vue";
  import { useI18n } from "vue-i18n";
  import { useStore } from "vuex"
  export default defineComponent({
    name: 'gameFi',
    setup() {
      const store = useStore()
      let lan = computed(() => {
        return store.state.lan
      })
      const { t, locale } = useI18n();
      const index = ref(1)
      const TagList = ref([])
      const TagText = ref(null)
      const text = ref(t('gameFi.block2.text1_2'))
      const chooseQuestin = (val) => {
        index.value = val
        text.value = t(`gameFi.block2.text${val}_2`)
      }
      watch(lan, (newvlaue, oldvalue) => {
        text.value = t(`gameFi.block2.text${index.value}_2`)
      })
      onMounted(() => {
      })
      return {
        lan,
        text,
        index,
        TagList,
        TagText,
        chooseQuestin
      };
    }
  })
</script> 

<style lang="scss" scoped>
.gameFi {
  width: 100%;
  font-style: normal;
  .content {
    position: relative;
    width: 100%;
    min-width: 1200px;
    font-size: 0;
    line-height: 0;
    .show {
      display: none;
    }
    .hide {
      display: block;
    }
    &.content1 {
      width: 100%;
      height: 680px;
      overflow: hidden;
      background: url("../assets/inter_bg1.png") no-repeat center;
      background-size: 100% 680px;
    }
    &.content2 {
      width: 100%;
      height: 400px;
      background: url("../assets/inter_bg2.png");
      background-size: 100% 550px;
    }
    .cont1 {
      margin: auto;
      position: relative;
      min-width: 1200px;
      max-width: 1440px;
      padding: 60px 0 0;
      text-align: center;
      box-sizing: border-box;
      .icon1 {
        position: absolute;
        top: -30px;
        left: 120px;
        width: 112px;
        height: 112px;
        background: url('../assets/icon1.png') no-repeat 100%/100%;
        opacity: .8;
      }
      .icon2 {
        z-index: 1;
        position: absolute;
        top: 15px;
        right: 200px;
        width: 133px;
        height: 133px;
        background: url('../assets/icon2.png') no-repeat 100%/100%;
        opacity: .6;
      }
      .icon3 {
        position: absolute;
        z-index: 3;
        top: 450px;
        right: 180px;
        width: 133px;
        height: 133px;
        background: url('../assets/icon3.png') no-repeat 100%/100%;
        opacity: .6;
      }
      .icon4 {
        position: absolute;
        top: 500px;
        left: 100px;
        width: 105px;
        height: 105px;
        background: url('../assets/icon4.png') no-repeat 100%/100%;
        opacity: .6;
      }
      .title {
        font-size: 24px;
        color: #363F5E;
        font-weight: 600;
        line-height: 34px;
        margin-bottom: 30px;
      }
      .introduct {
        position: relative;
        z-index: 2;
        margin: auto;
        width: 1000px;
        height: 380px;
        padding: 0 30px;
        border-radius: 12px;
        box-sizing: border-box;
        background: linear-gradient(0deg, #FFFFFF, #FFFFFF), #ECEFF8;
        .top {
          width: 360px;
          height: 46px;
          font-size: 18px;
          color: #FFFFFF;
          line-height: 46px;
          text-align: center;
          margin: 0 auto 20px;
          background: url('../assets/askbg.png') no-repeat 100%/100%;
        }
        .desc {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .li {
            width: 297px;
            height: 76px;
            font-size: 14px;
            line-height: 22px;
            padding: 15px 20px;
            border-radius: 6px;
            box-sizing: border-box;
            color: rgba(54, 63, 94, 0.68);
            background: rgba(63, 102, 232, 0.06);
          }
        }
        .method {
          width: 914px;
          height: 50px;
          font-size: 20px;
          color: #FFFFFF;
          line-height: 50px;
          margin: 60px 0 25px;
          border-radius: 57px;
          background: linear-gradient(270deg, rgba(63, 102, 232, 0) 0%, rgba(63, 102, 232, 0.024) 7.48%, rgba(63, 102, 232, 0.7) 32.92%, rgba(63, 102, 232, 0.6) 69.27%, rgba(63, 102, 232, 0.024) 90.99%, rgba(63, 102, 232, 0) 100%);
        }
        .list {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .li {
            width: 220px;
            height: 54px;
            font-size: 14px;
            line-height: 54px;
            padding: 0 10px;
            border-radius: 6px;
            box-sizing: border-box;
            color: rgba(54, 63, 94, 0.8);
            background: rgba(63, 102, 232, 0.06);
          }
        }
      }
    }
    .cont2 {
      position: relative;
      width: 1200px;
      margin: auto;
      display: flex;
      flex-wrap: wrap;
      font-weight: 500;
      box-sizing: border-box;
      justify-content: space-between;
      .QA_cont {
        width: 1000px;
        margin: 0 auto;
        text-align: center;
        .name {
          font-size: 20px;
          color: #363F5E;
          font-weight: 600;
          line-height: 28px;
        }
        .list {
          display: flex;
          width: 1000px;
          padding: 24px 24px 10px;
          flex-wrap: wrap;
          margin-top: 20px;
          align-items: center;
          border-radius: 12px;
          box-sizing: border-box;
          background: rgba(255, 255, 255, 0.4);
          .li {
            line-height: 1;
            font-size: 16px;
            cursor: pointer;
            font-weight: 600;
            color: #3F66E8;
            padding: 10px 20px;
            border-radius: 12px;
            margin: 0 12px 16px 0;
            background: rgba(221, 229, 248, 0.8);
            &:hover {
              color: #FFFFFF;
              background: #3F66E8;
            }
            &.active {
              position: relative;
              color: #FFFFFF;
              background: #3F66E8;
              &::after {
                position: absolute;
                bottom: -6px;
                left: calc(50% - 6px);
                content: '';
                border-top: 6px solid #6585ED;
                border-left: 6px solid transparent;
                border-right: 6px solid transparent;
              }
            }
          }
          .text {
            width: 100%;
            font-size: 16px;
            font-weight: 600;
            text-align: left;
            color: #363F5E;
            line-height: 26px;
            padding: 16px 20px;
            border-radius: 12px;
            margin-bottom: 15px;
            background: #FFFFFF;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1200px){
  .gameFi {
    width: 100%;
    min-width: 600px;
    .content {
      position: relative;
      margin-top: -2px;
      min-width: 600px;
      width: 100%;
      font-size: 0;
      line-height: 0;
      &.content1 {
        height: 1100px;
        background: url('../assets/mobile/inter_bg1.png') no-repeat center;
        background-size: 100% 100%;
      }
      &.content2 {
        height: 600px;
        background: url('../assets/mobile/inter_bg2.png') no-repeat center;
        background-size: 100% 1000px;
      }
      .show {
        display: block;
      }
      .hide {
        display: none;
      }
      .cont1 {
        margin: auto;
        width: 600px;
        min-width: unset;
        max-width: unset;
        padding: 80px 0 0;
        text-align: center;
        box-sizing: border-box;
        .icon1 {
          display: none;
        }
        .icon2 {
          display: none;
        }
        .icon3 {
          display: none;
        }
        .icon4 {
          display: none;
        }
        .title {
          font-size: 40px;
          font-weight: 400;
          margin-top: 20px;
          line-height: 53px;
        }
        .introduct {
          position: relative;
          z-index: 2;
          margin: auto;
          width: 600px;
          height: 700px;
          padding: 0 30px;
          border-radius: 12px;
          box-sizing: border-box;
          background: linear-gradient(0deg, #FFFFFF, #FFFFFF), #ECEFF8;
          .top {
            width: 230px;
            height: 46px;
            font-size: 18px;
            color: #FFFFFF;
            line-height: 46px;
            text-align: center;
            margin: 0 auto 20px;
            background: url('../assets/askbg.png') no-repeat 100%/100%;
          }
          .desc {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            .li {
              width: 100%;
              height: 76px;
              font-size: 14px;
              padding: 0 10px;
              line-height: 74px;
              margin-bottom: 20px;
            }
          }
          .method {
            width: 100%;
            height: 68px;
            font-size: 24px;
            font-weight: 600;
            color: #FFFFFF;
            line-height: 68px;
            margin: 60px 0 25px;
            border-radius: 57px;
            background: linear-gradient(270deg, rgba(63, 102, 232, 0) 0%, rgba(63, 102, 232, 0.024) 7.48%, rgba(63, 102, 232, 0.7) 32.92%, rgba(63, 102, 232, 0.6) 69.27%, rgba(63, 102, 232, 0.024) 90.99%, rgba(63, 102, 232, 0) 100%);
          }
          .list {
            flex-wrap: wrap;
            .li {
              width: 260px;
              height: 54px;
              font-size: 14px;
              padding: 0 10px;
              line-height: 54px;
              border-radius: 6px;
              margin-bottom: 20px;
              box-sizing: border-box;
              color: rgba(54, 63, 94, 0.8);
              background: rgba(63, 102, 232, 0.06);
            }
          }
        }
      }
      .cont2 {
        width: 600px;
        margin: auto;
        text-align: center;
        position: relative;
        min-width: unset;
        max-width: unset;
        .QA_cont {
          width: 600px;
          text-align: center;
          margin: 0 auto;
          .name {
            font-size: 34px;
            color: #363F5E;
            line-height: 48px;
          }
          .list {
            display: flex;
            width: 600px;
            padding: 24px;
            margin-top: 30px;
            .li {
              font-size: 20px;
              cursor: pointer;
              font-weight: 600;
              color: #3F66E8;
              padding: 10px 20px;
              border-radius: 12px;
              margin: 0 12px 16px 0;
              background: rgba(221, 229, 248, 0.8);
              &:hover {
                color: #FFFFFF;
                background: #3F66E8;
              }
              &.active {
                position: relative;
                color: #FFFFFF;
                background: #3F66E8;
                &::after {
                  position: absolute;
                  bottom: -6px;
                  left: calc(50% - 6px);
                  content: '';
                  border-top: 6px solid #6585ED;
                  border-left: 6px solid transparent;
                  border-right: 6px solid transparent;
                }
              }
            }
            .text {
              width: 100%;
              font-size: 24px;
              font-weight: 400;
              text-align: left;
              color: #363F5E;
              line-height: 30px;
            }
          }
        }
      }
    }
  }
  .lan_En.gameFi {
    .content {
      .cont1 {
        .title {
          font-size: 34px;
        }
        .introduct {
          .top {
            width: 260px;
            font-size: 14px;
          }
          .desc {
            .li {
              display: flex;
              line-height: 1.1;
              text-align: center;
              align-items: center;
              justify-content: center;
            }
          }
          .method {
            font-size: 16px;
          }
          .list {
            .li {
              font-size: 14px;
            }
          }
        }
      }
      .cont2 {
        .QA_cont {
          margin-top: -20px;
          .name {
            font-size: 30px;
          }
          .list {
            margin-top: 20px;
            .li {
              font-size: 18px;
            }
            .text {
              font-size: 20px;
            }
          }
        }
      }
    }
  }
}

</style>

