<template>
  <div class="home" :class="{lan_En : lan != 'zh'}">
    <div class="content content1">
      <div class="video-container">
          <video class="video-hosted" autoplay loop="loop" muted="muted" src="../assets/home_new/bgvideo.mp4"></video>
      </div>
      <div class="overlay"></div>
      <div class="cont">
        <h2 class="text1 fadeInUp">WEB 3.0</h2>
        <h2 class="text2 fadeInUp">{{ $t('homeNew.cont1.text1') }}</h2>
        <p class="desc fadeInUp">{{ $t('homeNew.cont1.text2') }}</p>
        <div class="btn_list">
          <div class="btn_li">
            <a class="btn fadeInUp" href="https://www.deeplink.cloud/DeeplinkSetup.exe"><span class="btn_icon"><img src="../assets/pc_icon.png" alt=""> </span> <p>{{$t('cont1.btn1')}}</p></a>
            <p class="version fadeInUp">{{ $t('cont1.version', {ver: version}) }}</p>
          </div>
          <div class="btn_li">
            <a class="btn fadeInUp" href="https://rtc.deeplink.cloud/" target="_blank"><span class="btn_icon"><img src="../assets/web_icon.png" alt=""></span><p>{{$t('cont1.btn2')}}</p></a>
            <p class="version fadeInUp">{{ $t('cont1.version', {ver: version_web}) }}</p>
          </div>
          
          <div class="btn_li">
            <a class="btn fadeInUp" href="https://static1.deeplink.cloud/deeplink_app.apk"><span class="btn_icon"><img src="../assets/ar_icon.png" alt=""> </span> <p>{{$t('cont1.btn3')}}</p></a>
            <p class="version fadeInUp">{{ $t('cont1.version', {ver: version_ar}) }}</p>
          </div>
        </div>
        
        <p class="tip">{{ $t('homeNew.cont1.text3') }}</p>
      </div>
    </div>
    <div class="content content2">
      <div class="title">{{ $t('homeNew.cont2.title') }}</div>
      <div class="cont">
        <div class="block delay100 hide" v-animate="{delay: 100, class:'fadeInUp'}">
          <img src="../assets/home_new/cont2_icon1.svg" alt="">
          <div class="block_title">{{ $t('homeNew.cont2.block1.title') }}</div>
          <div class="block_desc">{{ $t('homeNew.cont2.block1.text') }}</div>
        </div>
        <div class="block delay300 hide" v-animate="{delay: 300, class:'fadeInUp'}">
          <img src="../assets/home_new/cont2_icon2.svg" alt="">
          <div class="block_title">{{ $t('homeNew.cont2.block2.title') }}</div>
          <div class="block_desc">{{ $t('homeNew.cont2.block2.text') }}</div>
        </div>
        <div class="block delay500 hide" v-animate="{delay: 500, class:'fadeInUp'}">
          <img src="../assets/home_new/cont2_icon3.svg" alt="">
          <div class="block_title">{{ $t('homeNew.cont2.block3.title') }}</div>
          <div class="block_desc">{{ $t('homeNew.cont2.block3.text') }}</div>
        </div>
        <div class="block delay700 hide" v-animate="{delay: 700, class:'fadeInUp'}">
          <img src="../assets/home_new/cont2_icon4.svg" alt="">
          <div class="block_title"> <span>{{ $t('homeNew.cont2.block4.title1') }}</span>{{ $t('homeNew.cont2.block4.title2') }}</div>
          <div class="block_desc">{{ $t('homeNew.cont2.block4.text') }}</div>
        </div>
      </div>
    </div>
    <div class="content content3">
      <div class="title">{{ $t('homeNew.cont3.title') }}</div>
      <div class="roundList">
        <div class="round delay100 hide" v-animate="{delay: 100, class:'fadeInUp'}">
          <img src="../assets/home_new/cont3_icon1.svg" alt="">
          <div class="round_title">{{ $t('homeNew.cont3.text1') }}</div>
        </div>
        <div class="round delay300 hide" v-animate="{delay: 300, class:'fadeInUp'}">
          <img src="../assets/home_new/cont3_icon2.svg" alt="">
          <div class="round_title">{{ $t('homeNew.cont3.text2') }}</div>
        </div>
        <div class="round delay600 hide" v-animate="{delay: 600, class:'fadeInUp'}">
          <img src="../assets/home_new/cont3_icon3.svg" alt="">
          <div class="round_title">{{ $t('homeNew.cont3.text3') }}</div>
        </div>
        <div class="round delay900 hide" v-animate="{delay: 900, class:'fadeInUp'}">
          <img src="../assets/home_new/cont3_icon4.svg" alt="">
          <div class="round_title">{{ $t('homeNew.cont3.text4') }}</div>
        </div>
      </div>
    </div>
    <div class="content content4">
      <div class="title">{{ $t('homeNew.cont4.title1') }} <span>{{ $t('homeNew.cont4.title2') }}</span></div>
      <div class="desc">{{ $t('homeNew.cont4.desc') }}</div>
      <div class="imgList">
        <div class="imgli delay100 hide" v-animate="{delay: 100, class:'fadeInUp'}">
          <img src="../assets/home_new/cont4_img1.png" alt="">
          <p>{{ $t('homeNew.cont4.text1') }}</p>
          <div class="imgli_footer"></div>
        </div>
        <div class="imgli delay200 hide" v-animate="{delay: 200, class:'fadeInUp'}">
          <img src="../assets/home_new/cont4_img2.png" alt="">
          <p>{{ $t('homeNew.cont4.text2') }}</p>
          <div class="imgli_footer"></div>
        </div>
        <div class="imgli delay300 hide" v-animate="{delay: 300, class:'fadeInUp'}">
          <img src="../assets/home_new/cont4_img3.png" alt="">
          <p>{{ $t('homeNew.cont4.text3') }}</p>
          <div class="imgli_footer"></div>
        </div>
        <div class="imgli delay500 hide" v-animate="{delay: 500, class:'fadeInUp'}">
          <img src="../assets/home_new/cont4_img4.png" alt="">
          <p>{{ $t('homeNew.cont4.text4') }}</p>
          <div class="imgli_footer"></div>
        </div>
        <div class="imgli delay700 hide" v-animate="{delay: 700, class:'fadeInUp'}">
          <img src="../assets/home_new/cont4_img5.png" alt="">
          <p>{{ $t('homeNew.cont4.text5') }}</p>
          <div class="imgli_footer"></div>
        </div>
        <div class="imgli delay900 hide" v-animate="{delay: 900, class:'fadeInUp'}">
          <img src="../assets/home_new/cont4_img6.png" alt="">
          <p>{{ $t('homeNew.cont4.text6') }}</p>
          <div class="imgli_footer"></div>
        </div>
      </div>
    </div>
    <div class="content content5">
      <div class="title">
        <p>{{ $t('homeNew.cont5.title1') }}<span>{{ $t('homeNew.cont5.title2') }}</span></p>
        <p>{{ $t('homeNew.cont5.title3') }}</p>
      </div>
      <div class="gameList">
        <div class="game hide" v-animate="{delay: 0, class:'amplify'}">
          <div class="game_img1">
            <img src="../assets/home_new/cont5_img1.gif" alt="">
          </div>
          <p class="game_name">{{ $t('homeNew.cont5.text1') }}</p>
        </div>
        <div class="game hide game2" v-animate="{delay: 0, class:'amplify'}">
          <div class="game_img2">
            <img src="../assets/home_new/cont5_img2.gif" alt="">
          </div>
          <p class="game_name">{{ $t('homeNew.cont5.text2') }}</p>
        </div>
        <div class="game hide" v-animate="{delay: 0, class:'amplify'}">
          <div class="game_img1">
            <img src="../assets/home_new/cont5_img3.gif" alt="">
          </div>
          <p class="game_name">{{ $t('homeNew.cont5.text3') }}</p>
        </div>
      </div>
    </div>
    <div class="content content6">
      <div class="title">
        <p>{{ $t('homeNew.cont6.title1') }}</p>
        <p>{{ $t('homeNew.cont6.title2') }} <span>{{ $t('homeNew.cont6.title3') }}</span>{{ $t('homeNew.cont6.title4') }}</p>
      </div>
      <div class="contrast">
        <div class="img1 hide" v-animate="{delay: 0, class:'amplify'}">
          <div class="text1">
            <p>{{ $t('homeNew.cont6.text1') }}</p>
            <p>{{ $t('homeNew.cont6.text2') }}</p>
          </div>
          <div class="text2">{{ $t('homeNew.cont6.text3') }}</div>
        </div>
        <div class="img2">
          <img src="../assets/home_new/cont6_img2.png" alt="">
        </div>
        <div class="img3 hide" v-animate="{delay: 0, class:'amplify'}">
          <div class="text1">{{ $t('homeNew.cont6.text4') }}</div>
          <div class="text2">DeepLink</div>
        </div>
      </div>
    </div>
    <div class="content content7">
      <div class="title">
        <p>{{ $t('homeNew.cont7.title1') }}</p>
        <p>{{ $t('homeNew.cont7.title2') }}</p>
      </div>
      <div class="method">
        <div class="method_top hide" v-animate="{delay: 0, class:'fadeInDown'}">
          <div class="top_tip">{{ $t('homeNew.cont7.block1.text1') }}</div>
          <div class="top_title">
            <p>{{ $t('homeNew.cont7.block1.text2') }}</p>
            <p>{{ $t('homeNew.cont7.block1.text3') }}</p>
          </div>
        </div>
        <div class="method_center">
          <img class="img img1" src="../assets/home_new/cont7_img1.png" alt="">
          <img class="img img2" src="../assets/home_new/cont7_img2.png" alt="">
        </div>
        <div class="method_bottom">
          <div class="bottom_cont hide" v-animate="{delay: 0, class:'fadeInUp'}">
            <div class="top_img top_img1">
              <p class="desc">{{ $t('homeNew.cont7.block2.text1') }}</p>
              <p class="text">{{ $t('homeNew.cont7.block2.text2') }}{{ $t('homeNew.cont7.block2.text3') }}</p>
            </div>
            <div class="top_text">
              <p>{{ $t('homeNew.cont7.block2.text4') }}</p>
              <div class="line"></div>
              <p>{{ $t('homeNew.cont7.block2.text5') }}</p>
            </div>
          </div>
          <div class="bottom_cont hide" v-animate="{delay: 0, class:'fadeInUp'}">
            <div class="top_img top_img2">
              <p class="text">{{ $t('homeNew.cont7.block3.text1') }}</p>
            </div>
            <div class="top_text">
              <p>{{ $t('homeNew.cont7.block3.text2') }}</p>
              <div class="line"></div>
              <p>{{ $t('homeNew.cont7.block3.text3') }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="title">
        <p>{{ $t('homeNew.cont7.title3') }}</p>
        <p>{{ $t('homeNew.cont7.title4') }}</p>
      </div>
      <div class="feature">
        <div class="feature_Li hide delay100" v-animate="{delay: 100, class:'fadeInUp'}">
          <div class="feature_top"><img src="../assets/home_new/cont7_img6.png" alt=""></div>
          <div class="feature_arrow"></div>
          <div class="feature_text">
            <h2>{{ $t('homeNew.cont7.block4.text1') }}</h2>
            <p>{{ $t('homeNew.cont7.block4.text2') }}<br/>{{ $t('homeNew.cont7.block4.text3') }}</p>
          </div>
        </div>
        <div class="feature_Li hide delay300" v-animate="{delay: 300, class:'fadeInUp'}">
          <div class="feature_top"><img src="../assets/home_new/cont7_img7.png" alt=""></div>
          <div class="feature_arrow"></div>
          <div class="feature_text">
            <h2>{{ $t('homeNew.cont7.block5.text1') }}</h2>
            <p>{{ $t('homeNew.cont7.block5.text2') }}</p>
          </div>
        </div>
        <div class="feature_Li hide delay500" v-animate="{delay: 500, class:'fadeInUp'}">
          <div class="feature_top"><img src="../assets/home_new/cont7_img8.png" alt=""></div>
          <div class="feature_arrow"></div>
          <div class="feature_text">
            <h2>{{ $t('homeNew.cont7.block6.text1') }}</h2>
            <p>{{ $t('homeNew.cont7.block6.text2') }}</p>
          </div>
        </div>
        <div class="feature_Li hide delay700" v-animate="{delay: 700, class:'fadeInUp'}">
          <div class="feature_top"><img src="../assets/home_new/cont7_img9.png" alt=""></div>
          <div class="feature_arrow"></div>
          <div class="feature_text">
            <h2>{{ $t('homeNew.cont7.block7.text1') }}</h2>
            <p>{{ $t('homeNew.cont7.block7.text2') }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="content content8 contShow">
      <div class="left hide" v-animate="{delay: 0, class:'fadeInUp'}">
        <img src="../assets/home_new/cont8_img1.png" alt="">
        <h2>{{ $t('homeNew.cont8.title1') }}<br/>{{ $t('homeNew.cont8.title2') }}</h2>
        <p>{{ $t('homeNew.cont8.text1') }}</p>
      </div>
      <div class="right hide" v-animate="{delay: 0, class:'fadeInDown'}">
        <img src="../assets/home_new/cont8_img2.png" alt="">
        <h2>{{ $t('homeNew.cont8.title3') }}<br/>{{ $t('homeNew.cont8.title4') }}</h2>
        <p>{{ $t('homeNew.cont8.text2') }}</p>
      </div>
    </div>
    <div class="content content8 contHide">
      <div class="block">
        <div class="left hide" v-animate="{delay: 0, class:'fadeInLeft'}">
          <img src="../assets/home_new/cont8_img1.png" alt="">
          <h2>{{ $t('homeNew.cont8.title1') }}<br/>{{ $t('homeNew.cont8.title2') }}</h2>
          <p>{{ $t('homeNew.cont8.text1') }}</p>
        </div>
      </div>
      <div class="block">
        <div class="right hide" v-animate="{delay: 0, class:'fadeInRight'}">
          <img src="../assets/home_new/cont8_img2.png" alt="">
          <h2>{{ $t('homeNew.cont8.title3') }}<br/>{{ $t('homeNew.cont8.title4') }}</h2>
          <p>{{ $t('homeNew.cont8.text2') }}</p>
        </div>
      </div>
    </div>
    <div class="content content9">
      <div class="title">
        <p>{{ $t('homeNew.cont9.title1') }}</p>
        <p>{{ $t('homeNew.cont9.title2') }}</p>
      </div>
      <div class="bgimg">
        <img class="hide" v-animate="{delay: 0, class:'amplify'}" src="../assets/home_new/cont9_img1.png" alt="">
      </div>
    </div>
    <div class="content content10">
      <div class="title">
        <p>{{ $t('homeNew.cont10.title1') }}</p>
        <p>{{ $t('homeNew.cont10.title2') }}</p>
      </div>
      <div class="imgList">
        <div class="imgLi hide delay100" v-animate="{delay: 100, class:'fadeInLeft'}">
          <div class="list_cont">
            <div class="img">
              <img src="../assets/home_new/cont10_img1.jpg" alt="">
            </div>
            <div class="text_cont">
              <div class="text_top">{{ $t('homeNew.cont10.block1.name') }} <span>{{ $t('homeNew.cont10.block1.desc') }}</span> </div>
              <div class="text_desc">
                <p>{{ $t('homeNew.cont10.block1.text1') }}</p>
                <p>{{ $t('homeNew.cont10.block1.text2') }} </p>
              </div>
            </div>
          </div>
        </div>
        <div class="imgLi hide delay300 right" v-animate="{delay: 300, class:'fadeInRight'}">
          <div class="list_cont">
            <div class="img">
              <img src="../assets/home_new/cont10_img2.jpg" alt="">
            </div>
            <div class="text_cont">
              <div class="text_top">{{ $t('homeNew.cont10.block2.name') }} <span>{{ $t('homeNew.cont10.block2.desc') }}</span> </div>
              <div class="text_desc">
                <p>{{ $t('homeNew.cont10.block2.text1') }}</p>
                <p>{{ $t('homeNew.cont10.block2.text2') }} </p>
              </div>
            </div>
          </div>
        </div>
        <div class="imgLi hide delay500" v-animate="{delay: 500, class:'fadeInLeft'}">
          <div class="list_cont">
            <div class="img">
              <img src="../assets/home_new/cont10_img3.jpg" alt="">
            </div>
            <div class="text_cont">
              <div class="text_top">{{ $t('homeNew.cont10.block3.name') }} <span>{{ $t('homeNew.cont10.block3.desc') }}</span> </div>
              <div class="text_desc">
                <p>{{ $t('homeNew.cont10.block3.text1') }}</p>
                <p>{{ $t('homeNew.cont10.block3.text2') }} </p>
              </div>
            </div>
          </div>
        </div>
        <div class="imgLi hide delay700 right" v-animate="{delay: 700, class:'fadeInRight'}">
          <div class="list_cont">
            <div class="img">
              <img src="../assets/home_new/cont10_img4.jpg" alt="">
            </div>
            <div class="text_cont">
              <div class="text_top">{{ $t('homeNew.cont10.block4.name') }} <span>{{ $t('homeNew.cont10.block4.desc') }}</span> </div>
              <div class="text_desc">
                <p>{{ $t('homeNew.cont10.block4.text1') }}</p>
                <p>{{ $t('homeNew.cont10.block4.text2') }} </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content content11">
      <div class="title">
        <p>{{ $t('homeNew.cont11.title') }}</p>
      </div>
      <div class="blurbList">
        <div class="blurbLi hide delay100" v-animate="{delay: 100, class:'fadeInUp'}">
          <div class="img"><img src="../assets/home_new/cont11_img1.png" alt=""> </div>
          <div class="blurb_title">{{ $t('homeNew.cont11.text1') }}</div>
          <div class="blurb_text">{{ $t('homeNew.cont11.desc1') }}</div>
        </div>
        <div class="blurbLi hide delay400" v-animate="{delay: 400, class:'fadeInUp'}">
          <div class="img"><img src="../assets/home_new/cont11_img2.png" alt=""></div>
          <div class="blurb_title">{{ $t('homeNew.cont11.text2') }}</div>
          <div class="blurb_text">{{ $t('homeNew.cont11.desc2') }}</div>
        </div>
        <div class="blurbLi hide delay900" v-animate="{delay: 900, class:'fadeInUp'}">
          <div class="img"><img src="../assets/home_new/cont11_img3.png" alt=""></div>
          <div class="blurb_title">{{ $t('homeNew.cont11.text3') }}</div>
          <div class="blurb_text">{{ $t('homeNew.cont11.desc3') }}</div>
        </div>
      </div>
    </div>
    <div class="content content12">
      <div class="title">
        <p>{{ $t('homeNew.cont12.title1') }}</p>
        <p><span>{{ $t('homeNew.cont12.title2') }}</span></p>
      </div>
      <div class="introList">
        <div class="introLi hide delay100" v-animate="{delay: 100, class:'fadeInUp'}">
          <div class="left">
            <h3 class="left_num">.01</h3>
            <p class="left_text">{{ $t('homeNew.cont12.text1_1') }}</p>
            <p class="left_text light">{{ $t('homeNew.cont12.text1_2') }}</p>
          </div>
          <img v-if="lan == 'zh'" src="../assets/home_new/cont12_img1.png" alt="">
          <img v-else-if="lan == 'ko'" src="../assets/home_new/cont12_img_ko_1.png" alt="">
          <img v-else src="../assets/home_new/cont12_img_en_1.png" alt="">
        </div>
        <div class="introLi hide delay300" v-animate="{delay: 300, class:'fadeInUp'}">
          <div class="left">
            <h3 class="left_num">.02</h3>
            <p class="left_text light">{{ $t('homeNew.cont12.text2_1') }}</p>
            <p class="left_text">{{ $t('homeNew.cont12.text2_2') }}</p>
          </div>
          <img v-if="lan == 'zh'" src="../assets/home_new/cont12_img2.png" alt="">
          <img v-else-if="lan == 'ko'" src="../assets/home_new/cont12_img_ko_2.png" alt="">
          <img v-else src="../assets/home_new/cont12_img_en_2.png" alt="">
        </div>
        <div class="introLi hide delay500" v-animate="{delay: 500, class:'fadeInUp'}">
          <div class="left">
            <h3 class="left_num">.03</h3>
            <p class="left_text">{{ $t('homeNew.cont12.text3_1') }}</p>
            <p class="left_text light">{{ $t('homeNew.cont12.text3_2') }}{{ $t('homeNew.cont12.text3_3') }}</p>
          </div>
          <img v-if="lan == 'zh'" src="../assets/home_new/cont12_img3.png" alt="">
          <img v-else-if="lan == 'ko'" src="../assets/home_new/cont12_img_ko_3.png" alt="">
          <img v-else src="../assets/home_new/cont12_img_en_3.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { defineComponent, ref, computed, watch, onMounted } from "vue";
  import { useStore } from "vuex"
  export default defineComponent({
    name: 'Home',
    setup() {
      const store = useStore()
      const obj = {
        'zh': "/deeplink_cn.mp4",
        'ko': "/deeplink_kr.mp4"
      }
      let lan = computed(() => {
        return store.state.lan
      })
      const videoLink = ref(obj[lan.value] ? obj[lan.value] : "deeplink_us.mp4")
      const downLoad = (url, fileName) => {
        const x = new XMLHttpRequest();
        x.open('GET', url, true)
        x.onload = function () {
            const url = window.URL.createObjectURL(x.response)
            const a = document.createElement('a')
            a.href = url
            a.download = fileName
            a.click()
        }
        x.send()
      }
      const version = ref('1.0.1.9')
      const version_ar = ref('1.0.8')
      const version_web = ref('1.0.0.7')
      const getVersion = () => {
        const script = document.createElement('script');
        script.src = 'https://www.deeplink.cloud/version.js';
        script.onload = () => {
          console.log(window.verData, 'window.verData');
          version.value = window.verData.version;
          version_ar.value = window.verData.version_ar;
          version_web.value = window.verData.version_web;
        };
        document.head.appendChild(script);
      }
      watch(lan, (newvlaue, oldvalue) => {
        videoLink.value = obj[newvlaue] ? obj[newvlaue] : "deeplink_us.mp4"
      })
      onMounted(() => {
        getVersion()
      })
      return {
        lan,
        version,
        version_ar,
        version_web,
        videoLink,
        downLoad
      };
    }
  })
</script>

<style lang="scss" scoped>
.home {
  width: 100%;
  font-style: normal;
  // font-family: 'Rubik, sans-serif';
  background: linear-gradient(180deg, #F0F5FC 0%, #FFF 100%);
  .contShow {
    display: flex !important;
  }
  .contHide {
    display: none !important;
  }
  .content {
    position: relative;
    min-width: 600px;
    width: 100%;
    font-size: 0;
    line-height: 0;
    padding-bottom: 100px;
    
    .hide {
      visibility: hidden;
    }
    @keyframes fadeInUp {
      0% {
        opacity: 0;
        transform: translate3d(0,100%,0);
      }
      100% {
        opacity: 1;
        transform: none;
      }
    }
    @keyframes fadeInDown {
      0% {
        opacity: 0;
        transform: translate3d(0,-100%,0);
      }
      100% {
        opacity: 1;
        transform: none;
      }
    }
    @keyframes fadeInLeft {
      0% {
        opacity: 0;
        transform: translate3d(-100%,0,0);
      }
      100% {
        opacity: 1;
        transform: none;
      }
    }
    @keyframes fadeInRight {
      0% {
        opacity: 0;
        transform: translate3d(100%,0,0);
      }
      100% {
        opacity: 1;
        transform: none;
      }
    }
    @keyframes amplify {
      0% {
        opacity: 0;
        transform: scale(0);
      }
      100% {
        opacity: 1;
        transform: scale(1);
      }
    }
    .fadeInUp {
      animation-name: fadeInUp;
      animation-duration: 1.5s;
    }
    .fadeInDown {
      animation-name: fadeInDown;
      animation-duration: 1.5s;
    }
    .fadeInLeft {
      animation-name: fadeInLeft;
      animation-duration: 1.5s;
    }
    .fadeInRight {
      animation-name: fadeInRight;
      animation-duration: 1.5s;
    }
    .amplify {
      animation-name: amplify;
      animation-duration: 2s;
    }
    .delay100 {
      animation-delay: 100ms; 
    }
    .delay200 {
      animation-delay: 200ms; 
    }
    .delay300 {
      animation-delay: 300ms; 
    }
    .delay400 {
      animation-delay: 400ms; 
    }
    .delay500 {
      animation-delay: 500ms; 
    }
    .delay600 {
      animation-delay: 600ms; 
    }
    .delay700 {
      animation-delay: 700ms; 
    }
    .delay800 {
      animation-delay: 800ms; 
    }
    .delay900 {
      animation-delay: 900ms; 
    }
    &.content1 {
      min-height: 600px;
      text-align: center;
      padding-bottom: 0;
      height: calc(100vh - 72px);
      border-bottom: 1px solid #03FF91;
      .video-container {
        transition: opacity 1s;
        pointer-events: none;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        position: absolute;
        overflow: hidden;
        z-index: 0;
        direction: ltr;
        .video-hosted {
          object-fit: cover;
          position: absolute;
          top: 50%;
          left: 50%;
          width: 100%;
          height: 100%;
          transform: translate(-50%,-50%);
          margin: 0;
          line-height: 1;
          border: none;
          box-sizing: border-box;
        }
      }
      .overlay {
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        position: absolute;
        box-sizing: border-box;
        filter: brightness( 100% ) contrast( 100% ) saturate( 100% ) blur( 0px ) hue-rotate( 0deg );
        background-color: #363F5E;
        opacity: 0.7;
      }
      .cont {
        height: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        align-items: center;
        justify-content: center;
        h2 {
          margin: 0 auto;
          line-height: 1.2;
        }
        .text1 {
          color: #03FF91;
          font-size: 72px;
          font-weight: 400;
          line-height: 1.2;
          letter-spacing: 14px;
          // font-family: "IBM Plex Sans Condensed", Sans-serif;
        }
        .text2 {
          color: #FFF;
          stroke: #000;
          font-size: 64px;
          font-weight: 600;
          letter-spacing: 5px;
          -webkit-text-stroke-color: #000;
        }
        .desc {
          color: #FFF;
          line-height: 1;
          font-size: 15px;
          font-weight: 400;
          letter-spacing: 6px;
          margin: 30px auto 50px; 
        }
        .btn_list {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-around;
          // width: 600px;
          .btn {
            display: flex;
            min-width: 220px;
            padding: 0 10px;
            height: 50px;
            color: #fff;
            line-height: 1;
            font-size: 16px;
            text-align: center;
            align-items: center;
            border-radius: 17px;
            margin: 20px 10px 0;
            text-decoration: none;
            justify-content: center;
            background: linear-gradient(271.98deg, #2AB36A 4.13%, rgba(104, 204, 79, 0.8) 114.86%);
            .btn_icon {
              width: 26px;
              height: 26px;
              margin-right: 5px;
              display: inline-block;
              img {
                width: 26px;
                height: 26px;
              }
            }
          }
        }
        .version {
          color: #FFF;
          line-height: 1;
          font-size: 15px;
          font-weight: 400;
          margin-top: 10px;
          letter-spacing: 1px;
        }
        .tip {
          bottom: 20px;
          color: #999;
          font-size: 14px;
          line-height: 20px;
          position: absolute;
        }
      }
    }
    &.content2 {
      // height: 900px;
      text-align: center;
      .title {
        position: relative;
        color: #363F5E;
        font-size: 28px;
        line-height: 1;
        font-weight: 600;
        padding: 16px 48px;
        display: inline-block;
        margin: 100px auto 60px;
        border: 1px solid #363F5E;
        clip-path: polygon(8px 0, calc(100% - 8px) 0, 100% 8px, 100% calc(100% - 8px), calc(100% - 8px) 100%, 8px 100%, 0 calc(100% - 8px), 0 8px);
      }
      .cont {
        max-width: 630px;
        margin: auto;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-around;
        .block {
          width: 306px;
          height: 280px;
          // background-color: #303030;
          margin: 5px 0;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          border: 1px solid #FFF;
          background: rgb(48, 48, 48, .7);
          img {
            height: 60px;
          }
          .block_title {
            height: 45px;
            color: #FFF;
            line-height: 1;
            font-size: 20px;
            font-weight: 600;
            margin: 20px auto 10px;
            span {
              color: #03FF91;
            }
          }
          .block_desc {
            width: 280px;
            height: 100px;
            color: #e5e5e5;
            font-size: 16px;
            font-weight: 400;
            line-height: 1.1;
          }
        }
      }
    }
    &.content3 {
      // height: 620px;
      text-align: center;
      // background: linear-gradient(233deg, #2B318A 26.3%, #01204C 90.43%);
      background: linear-gradient(180deg, #363F5E 52.12%, rgba(54, 63, 94, 0.90) 100%);
      .title {
        position: relative;
        color: #fff;
        font-size: 28px;
        line-height: 1;
        font-weight: 600;
        padding: 16px 48px;
        display: inline-block;
        margin: 100px auto 60px;
        border: 1px solid #ccc;
        clip-path: polygon(8px 0, calc(100% - 8px) 0, 100% 8px, 100% calc(100% - 8px), calc(100% - 8px) 100%, 8px 100%, 0 calc(100% - 8px), 0 8px);
      }
      .roundList {
        margin: auto;
        width: 1080px;
        // height: 300px;
        display: flex;
        position: relative;
        .round {
          width: 300px;
          height: 300px;
          display: flex;
          margin-left: -40px;
          align-items: center;
          border-radius: 300px;
          flex-direction: column;
          box-sizing: border-box;
          justify-content: center;
          border: 1px solid #ff6a00;
          &:first-child {
            margin-left: 0;
          }
          img {
            width: 88px;
            height: 88px;
            margin-bottom: 20px;
          }
          .round_title {
            color: #fff;
            font-size: 20px;
            font-weight: 400;
          }
        }
      }
    }
    &.content4 {
      // height: 900px;
      text-align: center;
      .title {
        position: relative;
        color: #363F5E;
        font-size: 28px;
        line-height: 1;
        font-weight: 600;
        padding: 16px 48px;
        display: inline-block;
        margin: 100px auto 20px;
        border: 1px solid #363F5E;
        clip-path: polygon(8px 0, calc(100% - 8px) 0, 100% 8px, 100% calc(100% - 8px), calc(100% - 8px) 100%, 8px 100%, 0 calc(100% - 8px), 0 8px);
        span {
          color: #03FF91;
        }
      }
      .desc {
        font-size: 20px;
        font-weight: 400;
        color: #363f5e;
        line-height: 32px;
        text-align: center;
      }
      .imgList {
        display: flex;
        flex-wrap: wrap;
        max-width: 1000px;
        align-items: center;
        margin: 60px auto 0;
        justify-content: space-around;
        .imgli {
          width: 332px;
          height: 242px;
          display: flex;
          transition: all .3s;
          position: relative;
          align-items: center;
          margin-bottom: 10px;
          box-sizing: border-box;
          justify-content: center;
          border: 1px solid transparent;
          &:hover {
            position: relative;
            z-index: 1;
            transition: all .4s;
            transform: scale(1.1);
            border: 1px solid #03FF91;
            &::before {
              position: absolute;
              top: -1px;
              left: -1px;
              z-index: 5;
              content: '';
              width: 6px;
              height: 6px;
              background: #03FF91;
            }
            &::after {
              position: absolute;
              top: -1px;
              right: -1px;
              z-index: 5;
              content: '';
              width: 6px;
              height: 6px;
              background: #03FF91;
            }
            .imgli_footer {
              position: absolute;
              bottom: 0;
              width: 332px;
              &::before {
                position: absolute;
                bottom: 0;
                left: 0;
                z-index: 5;
                content: '';
                width: 6px;
                height: 6px;
                background: #03FF91;
              }
              &::after {
                position: absolute;
                bottom: 0;
                right: 0;
                z-index: 5;
                content: '';
                width: 6px;
                height: 6px;
                background: #03FF91;
              }
            }
          }
          img {
            position: absolute;
            top: 0;
            left: 0;
            width: 330px;
            font-size: 0;
            height: 240px;
          }
          p {
            z-index: 1;
            color: #fff;
            line-height: 1;
            font-size: 28px;
            font-weight: 600;
          }
        }
      }
    }
    &.content5 {
      // height: 620px;
      text-align: center;
      background: linear-gradient(233deg, #2B318A 26.3%, #01204C 90.43%);
      // background: linear-gradient(180deg, #363F5E 52.12%, rgba(54, 63, 94, 0.90) 100%);
      .title {
        position: relative;
        color: #fff;
        font-size: 28px;
        line-height: 1.2;
        font-weight: 600;
        padding: 16px 48px;
        display: inline-block;
        margin: 100px auto 60px;
        border: 1px solid #ccc;
        clip-path: polygon(8px 0, calc(100% - 8px) 0, 100% 8px, 100% calc(100% - 8px), calc(100% - 8px) 100%, 8px 100%, 0 calc(100% - 8px), 0 8px);
        span {
          color: #03FF91;
        }
      }
      .gameList {
        margin: auto;
        max-width: 1100px;
        // height: 300px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-around;
        .game {
          width: 330px;
          &.game2 {
            width: 400px;
          }
          .game_img1 {
            width: 330px;
            // border: 1px solid #03FF91;
            box-shadow: 0px 0px 4px 1px #03FF91;
            img {
              width: 330px;
            }
          }
          .game_img2 {
            width: 400px;
            // border: 1px solid #03FF91;
            box-shadow: 0px 0px 4px 1px #03FF91;
            img {
              width: 400px;
            }
          }
          .game_name {
            color: #fff;
            line-height: 1;
            font-size: 20px;
            font-weight: 600;
            margin: 30px auto 0;
          }
        }
      }
    }
    &.content6 {
      // height: 600px;
      text-align: center;
      .title {
        position: relative;
        color: #363F5E;
        font-size: 28px;
        line-height: 1.2;
        font-weight: 600;
        padding: 16px 48px;
        display: inline-block;
        margin: 100px auto 20px;
        border: 1px solid #363F5E;
        clip-path: polygon(8px 0, calc(100% - 8px) 0, 100% 8px, 100% calc(100% - 8px), calc(100% - 8px) 100%, 8px 100%, 0 calc(100% - 8px), 0 8px);
        span {
          color: #03FF91;
        }
      }
      .contrast {
        max-width: 1100px;
        display: flex;
        margin: auto;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-around;
        .img1 {
          width: 450px;
          height: 180px;
          position: relative;
          background: url(../assets/home_new/cont6_img1.png) no-repeat 100%/100%;
          .text1 {
            position: absolute;
            bottom: 20px;
            left: 20px;
            font-size: 16px;
            color: #CECECE;
            line-height: 20px;
          }
          .text2 {
            position: absolute;
            bottom: 11px;
            right: 9px;
            line-height: 1;
            font-size: 16px;
            color: #CECECE;
            padding: 8px 10px;
            display: inline-block;
            background: #242929;
          }
        }
        .img2 {
          width: 80px;
          img {
            width: 80px;
          }
        }
        .img3 {
          width: 550px;
          height: 285px;
          position: relative;
          background: url(../assets/home_new/cont6_img3.png) no-repeat 100%/100%;
          .text1 {
            position: absolute;
            bottom: 30px;
            left: 40px;
            font-size: 24px;
            font-weight: 700;
            color: #03FF91;
            line-height: 20px;
          }
          .text2 {
            position: absolute;
            bottom: 13px;
            right: 13px;
            line-height: 1;
            font-size: 16px;
            color: #242929;
            padding: 8px 10px;
            display: inline-block;
            background: #03FF91;
          }
        }
      }
    }
    &.content7 {
      // height: 1000px;
      text-align: center;
      background: linear-gradient(180deg, #363F5E 52.12%, rgba(54, 63, 94, 0.90) 100%);
      // background: linear-gradient(233deg, #2B318A 26.3%, #01204C 90.43%);
      .title {
        position: relative;
        color: #fff;
        font-size: 28px;
        line-height: 1.2;
        font-weight: 600;
        padding: 16px 48px;
        display: inline-block;
        margin: 100px auto 60px;
        border: 1px solid #ccc;
        clip-path: polygon(8px 0, calc(100% - 8px) 0, 100% 8px, 100% calc(100% - 8px), calc(100% - 8px) 100%, 8px 100%, 0 calc(100% - 8px), 0 8px);
      }
      .method {
        max-width: 900px;
        margin: auto;
        .method_top {
          margin: auto;
          width: 240px;
          height: 240px;
          display: flex;
          line-height: 1;
          align-items: center;
          border-radius: 240px;
          flex-direction: column;
          justify-content: center;
          border: 1px solid #03FF91;
          .top_tip {
            font-size: 16px;
            font-weight: 500;
            color: #FFFFFFCC;
          }
          .top_title {
            color: #fff;
            font-size: 24px;
            font-weight: 600;
            padding-top: 15px;
          }
        }
        .method_center {
          width: 400px;
          display: flex;
          margin: -30px auto 0;
          justify-content: space-between;
          .img {
            width: 90px;
            height: 90px;
            img {
              width: 90px;
              height: 90px;
            }
          }
        }
        .method_bottom {
          max-width: 900px;
          display: flex;
          margin: 40px auto 0;
          justify-content: space-around;
          .bottom_cont {
            width: 240px;
            .top_img {
              width: 240px;
              height: 240px;
              display: flex;
              line-height: 1.1;
              align-items: center;
              border-radius: 240px;
              flex-direction: column;
              justify-content: center;
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center center;
              .desc {
                font-size: 16px;
                font-weight: 500;
                color: #FFFFFFCC;
                margin-bottom: 20px;
              }
              .text {
                color: #fff;
                font-size: 24px;
                font-weight: 600;
              }
            }
            .top_img1 {
              background-image: url(../assets/home_new/cont7_img3.png);
            }
            .top_img2 {
              background-image: url(../assets/home_new/cont7_img4.png);
            }
            .top_text {
              display: flex;
              color: #CCC;
              line-height: 1;
              font-size: 20px;
              font-weight: 500;
              margin-top: 30px;
              align-items: center;
              flex-direction: column;
              justify-content: center;
              .line {
                width: 20px;
                margin: 20px auto;
                border-top: 1px solid #03FF91;
              }
            }
          }
        }
      }
      .feature {
        margin: auto;
        display: flex;
        max-width: 1100px;
        align-items: center;
        justify-content: space-around;
        .feature_Li {
          width: 260px;
          .feature_top {
            width: 200px;
            height: 200px;
            margin: auto;
            overflow: hidden;
            border-radius: 200px;
            border: 1px solid #03FF91;
            img {
              width: 200px;
              height: 200px;
            }
          }
          .feature_arrow {
            width: 14px;
            height: 34px;
            margin: 16px auto;
            background: url(../assets/home_new/cont7_img5.svg) no-repeat 100%/100%;
          }
          .feature_text {
            width: 100%;
            h2 {
              color: #fff;
              line-height: 1;
              font-size: 24px;
              font-weight: 600;
              overflow: hidden;
              white-space: nowrap;
              padding-bottom: 8px;
              text-overflow: ellipsis;
            }
            p {
              height: 65px;
              color: #999;
              font-size: 20px;
              font-weight: 400;
              line-height: 32px;
              text-align: center;
            }
          }
        }
      }
    }
    &.content8 {
      display: flex;
      // height: 730px;
      flex-wrap: wrap;
      margin-top: 100px;
      text-align: center;
      justify-content: center;
      .left {
        width: 540px;
        height: 450px;
        display: flex;
        text-align: left;
        padding: 60px 64px;
        background: #2D3333;
        box-sizing: border-box;
        flex-direction: column;
        align-items: flex-start;
        border: 1px solid #03FF91;
        img {
          width: 85px;
          margin-bottom: 20px;
        }
        h2 {
          color: #fff;
          font-size: 28px;
          font-weight: 600;
          line-height: 40px;
        }
        p {
          font-size: 20px;
          font-weight: 400;
          line-height: 32px;
          color: #FFFFFF99;
        }
      }
      .right {
        width: 540px;
        height: 450px;
        display: flex;
        text-align: left;
        margin-top: 167px;
        padding: 60px 64px;
        background: #03FF91;
        box-sizing: border-box;
        flex-direction: column;
        align-items: flex-start;
        border: 1px solid #03FF91;
        img {
          width: 85px;
          margin-bottom: 20px;
        }
        h2 {
          color: #000;
          font-size: 28px;
          font-weight: 600;
          line-height: 40px;
        }
        p {
          font-size: 20px;
          font-weight: 400;
          line-height: 32px;
          color: #00000099;
        }
      }
    }
    &.content9 {
      // height: 1050px;
      text-align: center;
      background: linear-gradient(233deg, #2B318A 26.3%, #01204C 90.43%);
      // background: linear-gradient(180deg, #363F5E 52.12%, rgba(54, 63, 94, 0.90) 100%);
      .title {
        position: relative;
        color: #fff;
        font-size: 28px;
        line-height: 1.2;
        font-weight: 600;
        padding: 16px 48px;
        display: inline-block;
        margin: 100px auto 60px;
        border: 1px solid #ccc;
        clip-path: polygon(8px 0, calc(100% - 8px) 0, 100% 8px, 100% calc(100% - 8px), calc(100% - 8px) 100%, 8px 100%, 0 calc(100% - 8px), 0 8px);
      }
      .bgimg {
        margin: auto;
        width: 1100px;
        height: 679px;
        img {
          width: 1100px;
          height: 679px;
        }
      }
    }
    &.content10 {
      // height: 1300px;
      overflow: hidden;
      text-align: center;
      // margin-bottom: 100px;
      .title {
        position: relative;
        color: #363F5E;
        font-size: 28px;
        line-height: 1.2;
        font-weight: 600;
        padding: 16px 48px;
        display: inline-block;
        margin: 100px auto 20px;
        border: 1px solid #363F5E;
        clip-path: polygon(8px 0, calc(100% - 8px) 0, 100% 8px, 100% calc(100% - 8px), calc(100% - 8px) 100%, 8px 100%, 0 calc(100% - 8px), 0 8px);
      }
      .imgList {
        margin: auto;
        width: 1000px;
        display: flex;
        flex-wrap: wrap;
        .imgLi {
          width: 100%;
          margin-top: 50px;
          display: flex;
          &.right {
            flex-direction: row-reverse;
            .list_cont {
              flex-direction: row-reverse;
              .img {
                &::before {
                  left: unset;
                  bottom: -5px;
                  right: -5px;
                }
                &::after {
                  left: unset;
                  right: -5px;
                  top: -5px;
                }
              }
              .text_cont {
                text-align: right;
                align-items: flex-end;
                &::before {
                  bottom: -5px;
                  right: unset;
                  left: -5px;
                }
                &::after {
                  top: -5px;
                  right: unset;
                  left: -5px;
                }
              }
            }
          }
          .list_cont {
            width: 700px;
            height: 190px;
            display: flex;
            align-items: center;
            border: 1px solid #03FF91;
            justify-content: space-between;
            .img {
              width: 190px;
              height: 190px;
              position: relative;
              img {
                width: 190px;
                height: 190px;
              }
              &::before {
                position: absolute;
                bottom: -5px;
                left: -5px;
                z-index: 5;
                content: '';
                width: 10px;
                height: 10px;
                background: #03FF91;
              }
              &::after {
                position: absolute;
                left: -5px;
                top: -5px;
                z-index: 5;
                content: '';
                width: 10px;
                height: 10px;
                background: #03FF91;
              }
            }
            .text_cont {
              flex: 1;
              height: 190px;
              display: flex;
              text-align: left;
              padding: 0 20px;
              position: relative;
              box-sizing: border-box;
              background: #1B1B1B;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              &::before {
                position: absolute;
                bottom: -5px;
                right: -5px;
                z-index: 5;
                content: '';
                width: 10px;
                height: 10px;
                background: #03FF91;
              }
              &::after {
                position: absolute;
                top: -5px;
                right: -5px;
                z-index: 5;
                content: '';
                width: 10px;
                height: 10px;
                background: #03FF91;
              }
              .text_top {
                font-size: 20px;
                color: #E8E8E8;
                line-height: 30px;
                margin-bottom: 20px;
                span {
                  margin: 0 10px;
                  font-size: 12px;
                  color: #898989;
                }
              }
              .text_desc {
                font-size: 16px;
                color: #CECECE;
                line-height: 30px;
              }
            }
          }
        }
      }
    }
    &.content11 {
      // height: 650px;
      text-align: center;
      background: linear-gradient(180deg, #363F5E 52.12%, rgba(54, 63, 94, 0.90) 100%);
      .title {
        position: relative;
        color: #fff;
        font-size: 28px;
        line-height: 1.2;
        font-weight: 600;
        padding: 16px 48px;
        display: inline-block;
        margin: 100px auto 60px;
        border: 1px solid #ccc;
        clip-path: polygon(8px 0, calc(100% - 8px) 0, 100% 8px, 100% calc(100% - 8px), calc(100% - 8px) 100%, 8px 100%, 0 calc(100% - 8px), 0 8px);
      }
      .blurbList {
        margin: auto;
        max-width: 1080px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        .blurbLi {
          width: 350px;
          display: flex;
          flex-direction: column;
          box-sizing: border-box;
          align-items: flex-start;
          justify-content: center;
          .img {
            height: 68px;
            display: flex;
            align-items: flex-end;
            img {
              max-height: 68px;
            }
          }
          .blurb_title {
            height: 50px;
            color: #fff;
            line-height: 1;
            font-size: 22px;
            font-weight: 600;
            text-align: left;
            margin: 50px 0 10px;
            // font-family: 'Pretendard';
          }
          .blurb_text {
            height: 150px;
            color: #cfcccc;
            font-size: 18px;
            text-align: left;
            font-weight: 400;
            line-height: 36px;
          }
        }
      }
    }
    &.content12 {
      // height: 1500px;
      text-align: center;
      .title {
        position: relative;
        color: #363F5E;
        font-size: 28px;
        line-height: 1.2;
        font-weight: 600;
        padding: 16px 48px;
        display: inline-block;
        margin: 100px auto 40px;
        border: 1px solid #363F5E;
        clip-path: polygon(8px 0, calc(100% - 8px) 0, 100% 8px, 100% calc(100% - 8px), calc(100% - 8px) 100%, 8px 100%, 0 calc(100% - 8px), 0 8px);
        span {
          color: #03FF91;
        }
      }
      .introList {
        margin: auto;
        width: 1080px;
        .introLi {
          width: 100%;
          // height: 364px;
          display: flex;
          overflow: hidden;
          margin-bottom: 30px;
          padding: 30px 60px 0;
          background: #2D3333;
          box-sizing: border-box;
          img {
            width: 600px;
          }
          .left {
            flex: 1;
            display: flex;
            margin-right: 10px;
            align-items: flex-start;
            flex-direction: column;
            justify-content: center;
            .left_num {
              font-size: 40px;
              color: #03FF91;
              font-weight: 600;
              word-spacing: -0.8px;
              // font-family: "IBM Plex Sans Condensed", Sans-serif;
            }
            .left_text {
              color: #999;
              font-size: 24px;
              font-weight: 600;
              text-align: left;
              line-height: 40px;
              // font-family: 'Pretendard';
              &.light {
                color: #fff;
              }
            }
          }
        }
      }
    }
  }

}
.lan_En.home {
  .content2 {
    .cont {
      .block {
        .block_desc {
          font-size: 14px;
        }
      }
    }
  }
  .content4 {
    .imgList {
      .imgli {
        p {
          font-size: 20px;
        }
      }
    }
  }
  .content7 {
    .method {
      .method_bottom {
        .bottom_cont {
          .top_img {
            .text {
              font-size: 20px;
            }
          }
          .top_text {
            font-size: 16px;
          }
        }
      }
    }
    .feature {
      .feature_Li {
        .feature_text {
          p {
            font-size: 14px;
            line-height: 18px;
          }
        }
      }
    }
  }
  .content8 {
    .left {
      h2 {
        font-size: 22px;
        line-height: 28px;
      }
      p {
        font-size: 18px;
        line-height: 24px;
      }
    }
    .right {
      h2 {
        font-size: 22px;
        line-height: 28px;
      }
      p {
        font-size: 18px;
        line-height: 24px;
      }
    }
  }
  .content11 {
    .blurbList  {
      .blurbLi {
        .blurb_title {
          font-size: 24px;
          margin: 30px 0 10px;
        }
        .blurb_text {
          font-size: 18px;
          line-height: 22px;
        }
      }
    }
  }
  .content12 {
    .introList {
      .introLi {
        .left {
          .left_text {
            font-size: 20px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1200px){
  .home {
    min-width: 600px;
      .contShow {
        display: none !important;
      }
      .contHide {
        display: flex !important;
      }
    .content {
      .title {
        max-width: 600px;
        box-sizing: border-box;
        font-size: 22px !important;
        padding: 16px 20px !important;
      }
      &.content3 {
        .roundList {
          width: 600px;
          flex-wrap: wrap;
          justify-content: center;
          .round {
            &:nth-child(3) {
              margin-left: 0;
              margin-top: -40px;
            }
            &:nth-child(4) {
              margin-top: -40px;
            }
          }
        }
      }
      &.content5 {
        .gameList {
          width: 600px;
          .game {
            width: 430px;
            &.game2 {
              width: 550px;
            }
            .game_img1 {
              width: 430px;
              img {
                width: 430px;
              }
            }
            .game_img2 {
              width: 550px;
              img {
                width: 550px;
              }
            }
            .game_name {
              margin: 30px auto 40px;
            }
            &:last-child {
              .game_name {
                margin-bottom: 0;
              }
            }
          }
        }
      }
      &.content6 {
        .contrast {
          width: 600px;
          .img1 {
            width: 500px;
            img {
              width: 500px;
            }
          }
          .img2 {
            width: 120px;
            img {
              width: 120px;
            }
          }
          .img3 {
            width: 580px;
            img {
              width: 580px;
            }
          }
        }
      }
      &.content7 {
        .method {
          .method_center {
            .img {
              margin-top: 10px;
              &.img1 {
                transform: rotate(-45deg);
              }
              &.img2 {
                transform: rotate(45deg);
              }
            }
          }
        }
        .feature {
          margin: auto;
          display: flex;
          flex-wrap: wrap;
          max-width: 600px;
          align-items: center;
          justify-content: space-around;
          .feature_Li {
            width: 260px;
            margin-bottom: 20px;
            .feature_top {
              width: 200px;
              height: 200px;
              margin: auto;
              overflow: hidden;
              border-radius: 200px;
              border: 1px solid #03FF91;
              img {
                width: 200px;
                height: 200px;
              }
            }
            .feature_arrow {
              width: 14px;
              height: 34px;
              margin: 16px auto;
              background: url(../assets/home_new/cont7_img5.svg) no-repeat 100%/100%;
            }
            .feature_text {
              width: 100%;
              h2 {
                color: #fff;
                line-height: 1;
                font-size: 24px;
                font-weight: 600;
                overflow: hidden;
                white-space: nowrap;
                padding-bottom: 8px;
                text-overflow: ellipsis;
              }
              p {
                height: 65px;
                color: #999;
                font-size: 20px;
                font-weight: 400;
                line-height: 32px;
                text-align: center;
              }
            }
          }
        }
      }
      &.content8 {
        .block {
          width: 100%;
          display: flex;
          overflow: hidden;
          &:last-child {
            justify-content: flex-end;
          }
          .right {
            margin-top: 0;
          }
        }
        
      }
      &.content9 {
        .bgimg {
          width: 100%;
          margin: auto;
          display: flex;
          height: inherit;
          overflow: hidden;
          justify-content: center;
          img {
            width: 820px;
            height: inherit;
          }
        }
      }
      &.content10 {
        .imgList {
          width: 100%;
          padding: 0 30px;
          min-width: 600px;
          box-sizing: border-box;
          img {
            width: 60%;
          }
        }
      }
      &.content11 {
        .blurbList {
          .blurbLi {
            align-items: center;
          }
        }
      }
      &.content12 {
        .introList {
          width: 580px;
          .introLi {
            // height: 500px;
            flex-wrap: wrap;
            img {
              width: 100%;
            }
            .left {
              width: 100%;
              height: 240px;
            }
          }
        }
      }
    }
  }
  .lan_En.home {
    .content7 {
      .feature {
        .feature_Li {
          .feature_text {
            h2 {
              font-size: 20px;
            }
            p {
              font-size: 14px;
              line-height: 18px;
            }
          }
        }
      }
    }
    .content10 {
      .imgList {
        .imgLi {
          .list_cont {
            .text_cont {
              .text_top {
                font-size: 18px;
              }
              .text_desc {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
