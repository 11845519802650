export default {
  meau: {
    nav1: 'Trang chủ',
    nav2: 'Cộng đồng',
    nav3: 'Tải xuống',
    nav4: 'Phần thưởng',
    nav5: 'NFT',
    nav6: 'Internet Cafe',
    nav7: 'GameFi',
  },
  cont1: {
    text1: 'Phần mềm điều khiển từ xa chơi game chuyên nghiệp',
    text2: 'Nền tảng chơi game đám mây',
    btn: 'Tải ngay miễn phí',
    text3_1: 'Tham gia',
    text3_2: 'cộng đồng',
    text3_3: 'để nhận thưởng DeepLink Coin!'
  },
  cont2: {
    text1: 'DeepLink',
    text2: 'Dựa vào khả năng kỹ thuật độ trễ thấp cấp độ Esport, chơi game mượt mà',
    text3: 'Năng lực cốt lõi và thế mạnh kỹ thuật',
    text4: 'Dựa trên các khả năng cốt lõi của chơi game trên đám mây, trải nghiệm vượt trội hơn nhiều so với điều khiển từ xa truyền thống!',
    leaf1: {
      text1: 'Độ mượt cao, hiệu năng cao',
      text2: 'Chi phí thấp',
      text3: 'Giải pháp mã hóa dẫn đầu ngành giúp tiêu thụ tài nguyên tối thiểu với cùng lưu lượng mã ',
      text4: 'Mạng ngang hàng P2P: Hỗ trợ ipv6 với thông lượng NAT lên tới 95% mà không tốn dung lượng băng thông.'
    },
    leaf2: {
      text1: 'Tốc độ khung hình cao, độ trễ thấp',
      text2: '',
      text3: "Với độ trễ LAN <15 mili giây và khả năng hiển thị 4K 240Hz, WAN cũng được đánh giá thuộc hàng đầu thế giới."
    },
    leaf3: {
      text1: 'Độ nét cao',
      text2: '',
      text3: 'Các thuật toán thông minh làm tăng chất lượng hình ảnh ở cùng tốc độ bit và tái tạo màu sắc chân thực nhất.'
    },
    leaf4: {
      text1: 'Độ ổn định cao',
      text2: '',
      text3: 'Các chiến lược chống mất gói tin và chống tắc nghẽn được hỗ trợ bởi lượng lớn dữ liệu, cho phép nhanh chóng điều chỉnh tình trạng mất gói tin và tắc nghẽn dữ liệu.'
    },
    leaf5: {
      text1: 'Độ bảo mật cao',
      text2: '',
      text3: 'Tất cả dữ liệu được mã hóa bởi DTLS1.2 với AES256.'
    }
  },
  cont3: {
    text1: "Mượt như đang điều khiển thiết bị tại chỗ",
    text2: 'Áp dụng hàng chục kỹ thuật tối ưu hóa trong ba lĩnh vực chính, DeepLink có thể duy trì tốc độ khung hình cao dù cho có tỷ lệ mất gói tin 5% và độ trễ mạng 100 mili giây*. Đáp ứng yêu cầu trong mọi thể loại môi trường mạng.',
    li1: {
      text1: 'Công nghệ đối phó với mạng yếu',
      text2: 'Chiến lược chống mất gói tin FEC',
      text3: 'Dự báo băng thông',
      text4: 'Chiến lược mã hóa SVC'
    },
    li2: {
      text1: 'Kỹ thuật tối ưu hóa đường truyền',
      text2: 'Dự đoán cổng vân tay',
      text3: 'Hỗ trợ IPV6',
      text4: 'UPNP',
      text5: 'Định tuyến thông minh'
    },
    li3: {
      text1: 'Công nghệ xử lý hiển thị',
      text2: 'Chiến lược thích ứng tốc độ khung hình',
      text3: 'Dự đoán hình ảnh chuyển động',
      text4: 'Chiến lược xử lý biến thiên độ trễ',
      text5: 'Kết xuất hình ảnh HD nền tảng AI mã thấp',
      text6: 'Ứng dụng thực tế ảo động'
    },
    desc3: 'LAN < 5 mili giây - Mạng liên thành < 10 mili giây - Mạng liên tỉnh < 30 mili giây'
  },
  cont4: {
    text1: 'Chất lượng đồ họa siêu rõ nét',
    text2: 'Độ phân giải Ultra HD',
    text3: 'Hỗ trợ 1,07 tỷ màu',
    text4: '4:4:4 chất lượng hình ảnh không bị hư hại',
    text5: "DeepLink luôn sử dụng các chỉ số chất lượng hình ảnh khắt khe hơn để đạt được trải nghiệm hình ảnh vượt xa các công nghệ điều khiển từ xa truyền thống khác, với độ méo hình ảnh từ xa nằm dưới giới hạn nhận dạng của mắt người, hỗ trợ trọn vẹn cho nhu cầu hiển thị hình ảnh chính xác trong ngành công nghiệp sáng tạo.",
    text6: '*PSNR đề cập đến Tỷ lệ tín hiệu cực đại trên nhiễu, dưới 20dB độ méo hình ảnh cao, 20-30dB độ méo hình ảnh ở mức trung bình, 30-40dB độ méo hình ảnh thấp, trên 40dB độ méo hình ảnh cực kỳ thấp và không thể phát hiện bằng mắt thường.'
  },
  cont5: {
    text1: 'Hỗ trợ ngoại vi rộng rãi',
    text2: 'DeepLink cung cấp hỗ trợ từ xa cho các công cụ chuyên dụng trong ngành công nghiệp sáng tạo như bảng kỹ thuật số và cần điều khiển, đồng thời tối ưu hóa việc sử dụng phần mềm sáng tạo như PS, Zbrush và Unity để đảm bảo trải nghiệm nhất quán các công cụ sản xuất sáng tạo bất kể khi dùng từ xa hay tại chỗ.'
  },
  cont6: {
    text1: 'Bảo mật đa tầng - Tiếp cận đơn giản',
    block1: {
      title1: 'Tài khoản an toàn',
      desc1: 'Cơ chế bảo vệ tài khoản nhiều lớp đảm bảo yên tâm',
      title2: 'Kết nối an toàn',
      desc2: 'Giao thức bảo mật DTLS1.2, chỉ truy cập khi được ủy quyền',
      title3: 'Dữ liệu an toàn',
      desc3: 'Mã hóa AES 256-bit, an toàn trong suốt quá trình truyền dữ liệu',
      title4: 'Thiết bị an toàn',
      desc4: 'Chỉ người tương ứng mới nhìn thấy thiết bị tương ứng theo cài đặt của bạn'
    },
    block2: {
      title1: 'Bảo mật đa tầng',
      desc1: 'Bảo mật mọi kết nối',
      title2: 'Dễ sử dụng',
      desc2: 'Dễ bắt đầu'
    },
    block3: {
      title1: 'Tải xuống nhẹ',
      desc1: "Phần mềm DeepLink chỉ nặng khoảng 10M đối với bản người dùng cuối cùng",
      title2: 'Dễ học',
      desc2: 'Cài đặt theo hướng dẫn',
      title3: 'Dễ sử dụng',
      desc3: 'Điều khiển từ xa bất kỳ thiết bị thông qua chuột'
    }
  },
  cont7: {
    text1: 'Tâm huyết trong việc cung cấp các giải pháp điều khiển từ xa',
    block1: {
      word1: 'Ngành giáo dục',
      word2: 'Ngành công nghiệp trò chơi',
      word3: 'Ngành sáng tạo & thiết kế',
      word4: 'Ngành công nghệ thông tin',
      word5: 'Nhiều nhà kinh doanh đang được khai sinh',
      word6: 'Nhiều đối tác trên khắp thế giới',
      word7: 'Dịch vụ Vận hành và Bảo trì từ xa IT O&M',
      word8: 'Người dùng cá nhân và game thủ'
    },
    block2: {
      text1: 'Hỗ trợ các tình huống điều khiển từ xa trong nhiều ngành khác nhau',
      text2: 'Dựa trên nhu cầu thực tế của doanh nghiệp trong các ngành khác nhau, chúng tôi giúp họ đạt được mục tiêu kinh doanh theo cách tiết kiệm năng lượng.',
      text3: 'Cung cấp các chính sách cá nhân hóa và mã hóa, linh hoạt, bảo mật',
      text4: "Nhân viên công ty có thể dựa trên phân quyền truy cập tài nguyên của công ty mọi lúc mọi nơi."
    },
    block3: {
      text1: 'Đáp ứng nhu cầu đa dạng của người dùng',
      text2: 'Cho dù bạn chọn sử dụng cho nhân viên nội bộ,',
      text3: 'hay cho khách hàng, đối tác bên ngoài hoặc thậm chí dùng cho cá nhân.',
      text4: 'Ứng dụng điều khiển từ xa của chúng tôi có thể phục vụ bạn trên các thiết bị và nền tảng chính,',
      text5: 'đưa ra một phương thức thay thế có thể giải quyết vấn đề một cách đơn giản và thông minh.'
    }
  },
  cont9: {
    block1: {
      text1: 'Bảo mật đa tầng',
      text2: 'Bảo vệ an toàn mọi kết nối',
    },
    block2: { 
      title1: 'Quán Internet chia sẻ',
      text1_1: 'Mã hóa AES 256-bit',
      text1_2: 'Bảo mật suốt quá trình truyền tải',
      title2: 'Bảo mật kết nối',
      text2_1: 'Giao thức bảo mật DTLS1.2',
      text2_2: 'Truy cập theo cấp quyền',
      title3: 'Bảo mật thiết bị',
      text3_1: 'Cài đặt cho phép đối tượng tương ứng chỉ nhìn thấy thiết bị tương ứng',
      title4: 'Bảo mật tài khoản',
      text4_1: 'Cơ chế đa tầng dựa trên công nghệ blockchain, bảo vệ tài khoản và thông tin thiết bị, mã hóa truyền tải P2P, không thông qua máy chủ tập trung',
    },
    block3: {
      text1: 'Quán Internet chia sẻ',
      text2: 'Thông qua DeepLink, chủ quán Internet có thể cho thuê máy game để kiếm thu nhập, và người chơi có thể ngồi ở nhà thuê chơi game từ máy của quán Internet trong phạm vi 50 km',
    },
  },
  cont10: {
    title: 'DeepLink cung cấp giải pháp quán Internet Cafe trên nền tảng đám mây',
    desc: 'Nhờ giảm chi phí cùng khả năng mở rộng không giới hạn, tối đa hóa tỷ suất lợi nhuận cho các quán Internet Cafe',
    block1: {
      title1: 'Bốn vấn đề lớn của các quán Internet hiện tại',
      text1: 'Hóa đơn tiền điện lại tăng?',
      text1_1: 'Gánh nặng tiền điện',
      text1_2: 'Giá điện tăng theo quy định Nhà nước.',
      text2: 'Quán có thể đầu tư bao nhiêu RTX-40XX?',
      text2_1: 'Gánh nặng nâng cấp GPU/CPU',
      text2_2: 'Mỗi khi ra mắt GPU mới là kèm theo gánh nặng mua nâng cấp GPU đắt tiền.',
      text3: 'Làm cách nào để người dùng thay đổi nhận thức về chi tiêu?',
      text3_1: 'Nhận thức chi tiêu',
      text3_2: 'Cần một lý do để thay đổi nhận thức cố hữu về giới hạn chi tiêu dưới 1.000 won',
      text4: 'Làm cách nào đối phó với tác động của các yếu tố môi trường bên ngoài?',
      text4_1: 'Tỷ lệ sử dụng PC thấp tại các quán Internet',
      text4_2: 'Do đại dịch, thời tiết, v.v., ít người đến quán Internet hơn; cần giải pháp xử lý các PC nhàn rỗi.',
    },
    block2: {
      title1: 'Chuỗi quán Internet Cafe đám mây',
      desc1: 'Giá trị của Internet Cafe đám mây： Giảm chi phí ban đầu và chi phí bảo trì, không phát sinh chi phí nâng cấp, mang lại lợi ích kinh tế tốt nhất cho chủ quán Internet',
      text1_1: 'Chi phí khởi nghiệp giảm tới 61%',
      text1_2: 'Giảm chi phí khởi nghiệp, giảm gánh nặng chi phí mua PC',
      text2_1: 'Độ trễ cực thấp',
      text2_2: 'Cung cấp sức tính hiệu suất cao, cải thiện tốc độ phản hồi trò chơi',
      text3_1: 'Phí bảo trì “0 đồng”',
      text3_2: 'Không có chi phí quản lý máy tính bàn',
      text4_1: 'Tăng doanh thu',
      text4_2: 'Tính phí theo cấu hình GPU. Tất cả ghế chơi đều là "phòng game cao cấp"',
      text5_1: 'Tiết kiệm tiền điện',
      text5_2: 'Tiết kiệm năng lượng và giảm thải carbon',
      text4: 'Làm cách nào đối phó với tác động của các yếu tố môi trường bên ngoài?',
      text6_1: 'Phí nâng cấp “0 đồng”',
      text6_2: 'CPU/GPU được nâng cấp ở trung tâm đám mây',
    }
  },
  footer: {
    link: 'Học nhiều hơn về',
    community: 'Hãy theo dõi cộng đồng của chúng tôi',
    tip: 'Cộng đồng của chúng tôi trên: Twitter, telegram, reddit, medium, discord, tiktok, YouTube'
  },
  reward: {
    title1: 'DeepLink node',
    desc1: 'Có quyền truy cập vào các phần thưởng, ví dụ: DeepLink Token, Crown NFT, và quyền hội đồng quản trị.',
    title2: 'Nhận Genesis Node NFT',
    text1: "Càng nhiều người dùng trong mạng lưới DeepLink thì càng cần nhiều phép tính và lưu lượng truy cập. Các Genesis Node cung cấp cơ sở hạ tầng cho mạng lưới DeepLink, nhờ đó phi tập trung hóa và giúp DeepLink phát triển nhanh hơn.",
    text2: 'Bằng cách mua một Genesis Node NFT, bạn có cơ hội nhận phần thưởng cho đóng góp của mình vào mạng lưới.',
    text3: 'Đó có thể bao gồm DeepLink Token, Crown NFT, và quyền hội đồng quản trị.',
    text4_1: 'Bạn có câu hỏi? Hãy tương tác với chúng tôi trên',
    text4_2: 'và',
    text4_3: '',
    btntext: 'Mua ngay',
    text5: 'Cứ 1000 Genesis Node NFT được bán, giá sẽ tăng thêm 1000USD',
    text6_1: 'Số lượng node',
    text6_2: 'mỗi NFT hiện hỗ trợ',
    text7: 'Tổng số Genesis Node NFT',
    text8: 'Phát hành vĩnh viễn',
    text9: 'Giá ban đầu',
    text10_1: 'Số lượng node tối đa',
    text10_2: 'Được hỗ trợ trên mỗi NFT',
    text11: 'Giá tối đa',
    text12: 'Genesis node là gì?',
    text13: 'Genesis node đảm nhận nhiệm vụ cốt lõi trong việc tính toán và chuyển tiếp lưu lượng truy cập của mạng lưới DeepLink, đồng thời DeepLink cho phép nhiều người tham gia hơn để đạt được doanh thu thông qua mô hình khai thác.',
    text14: 'Tổng cộng có bao nhiêu Token đã được đưa vào các Genesis node để thưởng?',
    text15: '30% tổng số Token, tức là 30 tỷ DeepLink Token',
    text16: 'Genesis Node NFT là gì?',
    text17: 'Chỉ những người sở hữu Genesis Node NFT mới được phép tham gia khai thác.',
    text18: 'Doanh thu từ việc bán Genesis Node NFT được phân phối như thế nào?',
    text19: '50% số tiền thu được sẽ được đầu tư vào pool phần thưởng thanh khoản phi tập trung (ví dụ: DeepLink Token/USDC cho Uniswap, Sushiswap) và 50% số tiền thu được sẽ được đưa về công ty điều hành DeepLink.',
    text20: 'Cơ chế khai thác cụ thể là gì?',
    text21: 'Tổng phần thưởng là 5 tỷ một năm, cứ mỗi 3 năm thì giảm một nửa, 10% doanh thu DeepLink Crown NFT được dùng để mua lại DeepLink Token và thêm vào tổng phần thưởng trước mỗi đợt giảm một nửa, là một phần của 50% phần thưởng Token, được thưởng đến tất cả node trong 3 năm. Ví dụ: sau 3 năm, 10% thu nhập của DeepLink Crown NFT có thể mua lại 12 tỷ DeepLink Token, thì tổng phần thưởng còn lại là: 12 tỷ + 15 tỷ, tổng cộng là 27 tỷ, và tổng phần thưởng hàng năm từ đợt 3-năm lần thứ hai là 27 tỷ * 50% * 1/3 = 4,5 tỷ.',
    text22: 'Các yêu cầu đối với máy Genesis node là gì?',
    text23: "Mỗi máy phải đã nằm trong mạng lưới DeepBrain Chain. DeepBrain Chain là mạng máy tính hiệu suất cao phi tập trung, và các máy trong DeepBrain Chain đã cam kết DBC, nhờ vậy đảm bảo tính ổn định của máy. Để biết thêm thông tin về DeepBrain Chain:",
    text24: 'Những lợi ích khác của việc nắm giữ Genesis Node NFT là gì?',
    details: {
      text1: "1. Việc nắm giữ một số lượng Genesis Node NFT nhất định mang lại cho bạn quyền đại lý phổ thông để bán DeepLink NFT tại một quốc gia (cần 1 NFT cho mỗi 3 triệu người tùy theo tỷ lệ dân số của quốc gia đó);",
      text2: '2. Chương trình airdrop DeepLink Coin;',
      text3: '3. Chương trình airdrop token dự án game DeepLink ươm mầm;',
      text4: '4. Quyền bầu chọn game nào sẽ được phát hành trực tuyến;',
      text5: '5. Giảm giá mua Game NFT;',
      text6: '6. Giảm giá mua quyền chơi và giờ chơi game trên đám mây;',
      text7: '7. Các quyền khác để tham gia hội đồng quản trị.'
    },
    question: {
      text1_1: 'Genesis node là gì?',
      text1_2: 'Genesis node đảm nhận nhiệm vụ cốt lõi trong việc tính toán và chuyển tiếp lưu lượng truy cập của mạng lưới DeepLink, đồng thời DeepLink cho phép nhiều người tham gia hơn để đạt được doanh thu thông qua mô hình khai thác.',
      text2_1: 'Tổng cộng có bao nhiêu Token đã được đưa vào các Genesis node để thưởng?',
      text2_2: '10% tổng số Token, tức là 10 tỷ DeepLink Token',
      text3_1: 'Genesis Node NFT là gì?',
      text3_2: 'Chỉ những người sở hữu Genesis Node NFT mới được phép tham gia khai thác.',
      text4_1: 'Doanh thu từ việc bán Genesis Node NFT được phân phối như thế nào?',
      text4_2: '50% số tiền thu được sẽ được đầu tư vào pool phần thưởng thanh khoản phi tập trung (ví dụ: DeepLink Token/USDC cho Uniswap, Sushiswap) và 50% số tiền thu được sẽ được đưa về công ty điều hành DeepLink.',
      text5_1: 'Cơ chế khai thác cụ thể là gì?',
      text5_2: 'Tổng phần thưởng là 5 tỷ một năm, cứ mỗi 3 năm thì giảm một nửa, 10% doanh thu DeepLink Crown NFT được dùng để mua lại DeepLink Token và thêm vào tổng phần thưởng trước mỗi đợt giảm một nửa, là một phần của 50% phần thưởng Token, được thưởng đến tất cả node trong 3 năm. Ví dụ: sau 3 năm, 10% thu nhập của DeepLink Crown NFT có thể mua lại 12 tỷ DeepLink Token, thì tổng phần thưởng còn lại là: 12 tỷ + 15 tỷ, tổng cộng là 27 tỷ, và tổng phần thưởng hàng năm từ đợt 3-năm lần thứ hai là 27 tỷ * 50% * 1/3 = 4,5 tỷ.',
      text6_1: 'Các yêu cầu đối với máy Genesis node là gì?',
      text6_2: "Mỗi máy phải đã nằm trong mạng lưới DeepBrain Chain. DeepBrain Chain là mạng máy tính hiệu suất cao phi tập trung, và các máy trong DeepBrain Chain đã cam kết DBC, nhờ vậy đảm bảo tính ổn định của máy. Để biết thêm thông tin về DeepBrain Chain:",
      text7_1: 'Những lợi ích khác của việc nắm giữ Genesis Node NFT là gì?',
      text7_2: `1. Việc nắm giữ một số lượng Genesis Node NFT nhất định mang lại cho bạn quyền đại lý phổ thông để bán DeepLink NFT tại một quốc gia (cần 1 NFT cho mỗi 3 triệu người tùy theo tỷ lệ dân số của quốc gia đó);2. Chương trình airdrop DeepLink Coin;3. Chương trình airdrop token dự án game DeepLink ươm mầm;
      4. Quyền bầu chọn game nào sẽ được phát hành trực tuyến;5. Giảm giá mua Game NFT;6. Giảm giá mua quyền chơi và giờ chơi game trên đám mây;7. Các quyền khác để tham gia hội đồng quản trị.`,
    }
  },
  nft: {
    cont: {
      title1: 'Crown NFT',
      desc1: 'Với NFT này, bạn có 1 năm quyền truy cập vào tất cả tính năng dành cho doanh nghiệp của phần mềm DeepLink.',
      desc2: 'NFT không hoạt động sau khi mua, khi cần sử dụng, có thể kích hoạt NFT này trong phần mềm DeepLink. Khi NFT được kích hoạt',
      desc3: 'bắt đầu đếm ngược thời gian sử dụng 1 năm. 10% doanh thu NFT của phần mềm DeepLink được đầu tư vào phần thưởng node,',
      // desc4: '40% số tiền thu được được đầu tư vào pool phần thưởng thanh khoản và 50% là tiền thu được của công ty điều hành DeepLink.'
      desc4: '40% số tiền thu được sẽ bị tiêu hủy để tăng giá trị của DLC Token'
    },
    enabl: {
      title1: 'Quyền NFT',
      // desc1: '1 năm truy cập vào tất cả tính năng dành cho doanh nghiệp của phần mềm DeepLink',
      desc1: 'Có quyền truy cập vào các tính năng nâng cao của phần mềm DeepLink',
      desc2: 'Chương trình airdrop DeepLink Coin',
      desc3: 'Chương trình airdrop token dự án game DeepLink ươm mầm',
      desc4: 'Quyền bầu chọn game nào sẽ được phát hành trực tuyến',
      desc5: 'Giảm giá mua Game NFT',
      desc6: 'Giảm giá mua quyền chơi và giờ chơi game trên đám mây',
      desc7: 'Các quyền khác để tham gia hội đồng quản trị'

    },
    edition: {
      title1: 'Phiên bản chuyên gia',
      title2: 'Phiên bản đội',
      tip: 'Crown NFT',
      btn: 'Mua ngay',
      title3: 'Chuyên gia VS Phiên bản đội',
      desc: 'Truy cập các tính năng cụ thể',
      list: {
        li1_1: 'Nội dung',
        li1_2: 'Phiên bản cơ bản',
        li1_3: 'Phiên bản chuyên gia',
        li1_4: 'Phiên bản đội',
        li2_1: 'Chất lượng hình ảnh tốt nhất',
        li3_1: 'Chuyển tiếp phòng máy chủ server',
        li3_2: 'Số lượng nhỏ',
        li3_3: 'Số lượng lớn',
        li4_1: 'Chuyển tiếp băng thông',
        li4_2: 'Tối đa 4M',
        li4_3: 'Tối đa 50M',
        li4_4: 'Không giới hạn',
        li5_1: 'Độ trễ đầu cuối',
        li5_2: 'Tối thiểu <40ms',
        li5_3: 'Tối thiểu <20ms',
        li6_1:'Số lượng thiết bị có thể khởi tạo điều khiển từ xa cùng một lúc',
        li7_1:'Số lượng thiết bị',
        li8_1:'Desktop từ xa',
        li9_1:'Camera từ xa',
        li10_1:'SSH từ xa',
        li11_1:'Màn hình cá nhân',
        li12_1:'Màn hình ảo',
        li13_1:'Bàn phím chơi game',
        li14_1:'Đa luồng Multi-streaming',
        li15_1:'444 Màu thật',
        li16_1:'Ghi lại ảnh chụp màn hình',
        li17_1:'Từ Màn hình kép đến Màn hình kép',
        li18_1:'xử lý',
        li19_1:'Quản lý Nhóm người dùng/thiết bị',
        li20_1:'SSO Đăng nhập một lần',
        li21_1:'Ủy quyền quản lý thống nhất',
        li22_1:'Triển khai hàng loạt',
        li23_1:'Kiểm tra hành vi của nhân viên',
        li24_1:'Đóng dấu mờ Watermark',
        li25_1:'Giá',
        li26_1: 'Phiên máy tính để bàn một máy',
        li27_1: 'chuyển tập tin',

        new_text1: 'Nếu cùng một địa chỉ ví được đăng nhập trên nhiều thiết bị cùng một lúc, chỉ có một trong các thiết bị có thể khởi động điều khiển từ xa.',
        new_text2: 'Một máy chỉ có thể mở hai cửa sổ điều khiển từ xa',
      }
    }
  },
  internetCafe: {
    title1: 'DeepLink cung cấp giải pháp quán Internet Cafe đám mây',
    block1: {
      text1: 'Giảm hơn 60% chi phí đầu tư phần cứng của các chủ quán Internet',
      text2: 'Rút ngắn hơn 40% thời gian hoàn vốn đầu tư',
      text3: 'Xem video',
      text4: 'Xem tập tin',
    },
    title2: 'Giá NFT cho Internet Cafe đám mây',
    block2: {
      desc: '40% số tiền thu được sẽ được đầu tư vào mining pool DLC Coin trên sàn DEX để thưởng cho các nhà đầu tư DLC',
      text1_1: 'Hỗ trợ 50 máy',
      text1_2: 'Một năm 3600 USDT',
      text2_1: 'Hỗ trợ 100 máy',
      text2_2: 'Một năm 7200 USDT',
      text3_1: 'Hỗ trợ 150 máy',
      text3_2: 'Một năm 10800 USDT',
      text4_1: 'Hỗ trợ 200 máy',
      text4_2: 'Một năm 14400 USDT',
    },
    title3: 'DeepLink tuyển Đại lý toàn cầu ngành Internet Cafe đám mây',
    block3: {
      text1_1: 'Quyền lợi',
      text1_2: 'Được mua NFT Internet Cafe đám mây với giá thấp hơn giá thị trường',
      text2_1: 'Trách nhiệm',
      text2_2: 'Tìm đối tác kinh doanh Internet Cafe đám mây tại khu vực đại lý, đối tác có khả năng cung cấp dịch vụ Internet Cafe đám mây cho các quán Internet',
      text3_1: 'Yêu cầu',
      text3_2: 'Có nguồn lực liên quan ngành Internet Cafe tại khu vực đại lý, sở hữu số lượng DLC Coin tương đương với dân số khu vực đại lý',
    },
    title4: 'Q&A về Internet Cafe đám mây ',
    block4: {
      text1_1: 'Internet Cafe đám mây là gì？',
      text1_2: 'Internet Cafe đám mây là quán Internet chỉ cần trang bị màn hình và mini PC，không cần trang bị GPU cấu hình cao. Nhờ kết nối từ xa với máy chủ GPU qua mạng, người chơi tại quán Internet có thể tùy ý lựa chọn sử dụng các loại máy chủ GPU khác nhau. Quán Internet không cần quản lý tài nguyên game. Quán Internet chỉ cần thu phí tính theo thời gian sử dụng của người chơi.',
      text2_1: 'Làm thế nào để đăng ký làm đại lý？',
      text2_2: 'Vui lòng liên hệ chúng tôi tại nhóm telegram：',
      text3_1: 'Đại lý được hưởng những quyền lợi gì？',
      text3_2: 'Đại lý độc quyền trong khu vực đại lý, phát triển nền tảng đám mây để phục vụ các quán Internet và hưởng giá chênh lệch từ mỗi NFT.',
      text4_1: 'Đại lý có cần ký thỏa thuận chính thức với DeepLink không?',
      text4_2: 'Đại lý cần ký thỏa thuận chính thức với DeepLink.',
      text5_1: 'Thu nhập NFT Internet Cafe đám mây tác động như thế nào đến giá DLC?',
      text5_2: '40% thu nhập NFT Internet Cafe đám mây sẽ được để vào sàn phi tập trung, ví dụ cặp DLC-USDT trên Uniswap, dùng để mua DLC, DLC đã mua sẽ bị tiêu hủy.',
      text6_1: 'Làm thế nào đảm bảo tính minh bạch của việc dùng thu nhập NFT Internet Cafe đám mây để mua DLC？',
      text6_2: 'Số lượng NFT Internet Cafe đám mây đã phát hành có thể được truy vấn on-chain, thông qua số lượng đã phát hành có thể tính được thu nhập NFT.',
    },
    title5: 'Đối tác Internet Cafe đám mây'
  },
  gameFi: {
    title1: 'Nền tảng GameFi đám mây',
    block1: {
      text1: 'Nền tảng GameFi đám mây',
      text1_1: 'Ai cũng có thể chơi GameFi thông qua DeepLink mà không cần tải xuống',
      text1_2: 'Không gặp tình trạng hạn chế tải game ở một số quốc gia. Không cần tải từ Apple Appstore',
      text1_3: 'Giúp các nhà sản xuất GameFi thu hút thêm nhiều người chơi',
      text2: 'Phương thức hợp tác với nhà sản xuất game',
      text2_1: 'Hợp tác quảng cáo',
      text2_2: 'Hợp tác lưu lượng người dùng',
      text2_3: 'Chia sẻ lợi nhuận từ game',
      text2_4: 'Hợp tác NFT',
    },
    title2: 'Q&A về GameFi',
    block2: {
      text1_1: 'Nền tảng GameFi đám mây là gì？',
      text1_2: 'DeepLink sẽ chọn đối tác là các nhà làm game chất lượng cao, đưa game của họ lên nền tảng đám mây DeepLink. Người dùng thông qua DeepLink trực tiếp chơi GameFi mà không cần tải xuống',
      text2_1: 'Nền tảng GameFi đám mây có hỗ trợ game mobile không？',
      text2_2: 'Chúng tôi sẽ hỗ trợ PC để chơi game mobile, và có thể mở nhiều cửa sổ game cùng một lúc',
      text3_1: 'Lợi ích hợp tác giữa nhà sản xuất GameFi và DeepLink là gì？',
      text3_2: 'DeepLink sẽ giúp các nhà làm game quảng bá thương hiệu và thu hút nhiều người chơi hơn',
      text4_1: 'Nhà sản xuất GameFi có cần ký thỏa thuận chính thức với DeepLink không?',
      text4_2: 'Không cần. DeepLink sẽ sàng lọc đối tác là các nhà làm game chất lượng cao. Các game được sàng lọc sẽ tự động được đưa lên nền tảng DeepLink',
      text5_1: 'Làm thế nào để đăng ký hợp tác với DeepLink？',
      text5_2: 'Vui lòng gửi email đăng ký hợp tác đến：',
    }
  },
  cloudInternet: {
    title: 'Làm thế nào để trở thành đối tác giải pháp quán trò chơi mạng trên đám mây',
    text: 'Hiện nay, các quốc gia có số lượng quán trò chơi mạng chính trên toàn thế giới bao gồm Hàn Quốc (6000 quán), Trung Quốc (40,000 quán), Brazil (10,000 quán), Indonesia (20,000 quán), Philippines (20,000 quán), Việt Nam (15,000 quán), Thổ Nhĩ Kỳ (5000 quán), cùng với Ấn Độ, Mexico, Trung Đông, Nam Mỹ, Châu Phi, châu Âu và Mỹ và nhiều quốc gia khác. Tổng số lượng quán trò chơi mạng trên toàn thế giới lên đến hơn 150,000 quán, nhưng ngành công nghiệp này đã tồn tại trong 20 năm và đã trở thành một ngành công nghiệp truyền thống. Chu kỳ thu hồi vốn đã tăng lên đến 3-4 năm, và đối mặt với nhiều rủi ro, như đại dịch COVID-19 đã khiến nhiều quán trò chơi mạng phải đóng cửa. Do đó, ngành công nghiệp quán trò chơi mạng cần một giải pháp mới toanh, có thể giảm thiểu thời gian thu hồi vốn, giảm thiểu rủi ro và nâng cao hiệu suất vận hành quán trò chơi mạng. Và từ đó, giải pháp quán trò chơi mạng trên đám mây đã ra đời.',
    cont2: {
      text1: 'là gì',
      text2: 'Giải pháp quán trò chơi mạng trên đám mây?',
      text3: 'Giải pháp quán trò chơi mạng trên đám mây là một loại hình dịch vụ quán trò chơi mới với công nghệ kết xuất siêu thấp độ trễ. Trong quán trò chơi mạng trên đám mây, trò chơi và ứng dụng không được cài đặt trực tiếp trên máy tính cục bộ mà được lưu trữ trên các máy chủ GPU trên đám mây. Người dùng truy cập và chơi trò chơi thông qua dịch vụ đám mây mà không cần phải sử dụng phần cứng cục bộ có hiệu suất cao.',
      block1: {
        text1: 'Giảm Yêu Cầu Phần Cứng Cục Bộ',
        text2: 'Do trò chơi và ứng dụng chạy trên các máy chủ GPU trên đám mây, máy tính cục bộ không cần phải có GPU hiệu suất cao hoặc dung lượng lưu trữ lớn. Điều này giảm thiểu chi phí đầu tư và bảo trì phần cứng của quán trò chơi.'
      },
      block2: {
        text1: 'Truy Cập Ngay Lập Tức Đến Các Trò Chơi Mới Nhất',
        text2: 'Người dùng có thể truy cập các trò chơi mới nhất ngay lập tức mà không cần phải chờ đợi để tải xuống và cài đặt. Tất cả các cập nhật và bảo trì trò chơi đều được thực hiện trên đám mây, mang lại trải nghiệm thuận tiện và hiệu quả cho người dùng.'
      },
      block3: {
        text1: 'Trải Nghiệm Chơi Game Hiệu Suất Cao',
        text2: 'Màn hình cục bộ chỉ cần được trang bị mini PC (khoảng 100 đô la), và quán trò chơi mạng trên đám mây vẫn có thể cung cấp trải nghiệm chơi game hiệu suất cao. Điều này bởi vì tính toán trò chơi được thực hiện trên các máy chủ GPU hiệu suất cao từ xa.'
      },
      block4: {
        text1: 'Tiết Kiệm Không Gian và Năng Lượng',
        text2: 'Máy tính cục bộ có cấu hình thấp chiếm ít không gian hơn và tiêu thụ ít năng lượng hơn, giúp giảm chi phí vận hành của quán trò chơi.'
      },
      block5: {
        text1: 'Quản Lý và Bảo Trì Dễ Dàng',
        text2: 'Các quản lý quán trò chơi có thể dễ dàng quản lý phần mềm và trò chơi vì tất cả nội dung được quản lý tập trung trên đám mây. Điều này giảm thiểu công việc bảo trì cục bộ và các vấn đề kỹ thuật tiềm ẩn.'
      }
    },
    cont3: {
      text1: 'Glowsteam Cloud Cybercafe ở Hàn Quốc',
      text2: 'Môi Trường Hoạt Động Thực Tế của Quán Trò Chơi Mạng Trên Đám Mây'
    },
    cont4: {
      text1: 'Giải Pháp Quán Trò Chơi Mạng Trên Đám Mây Có Thể Giúp Các Nhà Đầu Tư Quán Trò Chơi Mạng',
      text2: 'Tăng Lợi Nhuận Ròng lên 3 Lần và Rút Ngắn Thời Gian Hồi Vốn lên 40%',
      text3: 'Nhấn để Tải Dữ Liệu So Sánh'
    },
    cont5: {
      text1: 'Lợi nhuận của nhà cung cấp giải pháp quán trò chơi mạng trên đám mây là như thế nào?',
      text2: 'Nếu lợi nhuận được tính là 12% của thu nhập mỗi máy trò chơi mạng trên đám mây, sau đó dịch vụ một tổng số 1000 quán trò chơi mạng với 100.000 máy, mỗi máy được sử dụng trung bình 10 giờ mỗi ngày, và với một chi phí trung bình là 0.8 đô la mỹ cho mỗi máy mỗi giờ, 12% tương đương khoảng 0.1 đô la.',
      text3: 'Lợi nhuận hàng năm của một nhà cung cấp giải pháp quán trò chơi mạng trên đám mây là',
      text4: '100.000 * 10 * 0.1 * 365 = 36.5 triệu đô la',
      text5: 'Làm thế nào để trở thành một nhà cung cấp giải pháp quán trò chơi mạng trên đám mây',
      text6: 'Dựa trên công nghệ DeepBrainChain và DeepLink, HYCONS của Hàn Quốc đã phát triển một giải pháp quán trò chơi mạng trên đám mây hoàn chỉnh. DeepBrainChain cung cấp sức mạnh tính toán GPU, DeepLink cung cấp công nghệ kết xuất với độ trễ siêu thấp, và HYCONS cung cấp toàn bộ giải pháp. Tương tự, nếu chúng ta so sánh nó với một chiếc ô tô, DeepBrainChain cung cấp động cơ, DeepLink cung cấp hệ thống lái tự động, và HYCONS cung cấp giải pháp ô tô hoàn chỉnh. Các nhà cung cấp giải pháp địa phương tại các quốc gia khác nhau chỉ cần tích hợp hệ thống thanh toán địa phương của họ vào HYCONS để cung cấp dịch vụ cho các quán trò chơi mạng địa phương, và các nhà cung cấp giải pháp có thể thiết lập thương hiệu của riêng họ. HYCONS chỉ hoạt động duy nhất như một nhà cung cấp dịch vụ công nghệ mà không tiết lộ thương hiệu của mình.',
      text7: 'Để biết thêm thông tin, vui lòng liên hệ:',
      text8: "service{'@'}deepbrainchain.org"
    },
    cont6: {
      title: 'Tải về tài liệu liên quan đến quán trò chơi mạng trên đám mây',
      text1: 'Giới thiệu về HYCONS',
      text2: 'Phân tích lợi nhuận từ giải pháp quán trò chơi mạng trên đám mây',
      text3: 'Sơ đồ kiến trúc Trung tâm Dữ liệu Quán trò chơi mạng trên đám mây',
      text4: 'Hướng dẫn Kiểm tra và Cài đặt Giải pháp quán trò chơi mạng trên đám mây',
      text5: 'Giới thiệu giao thức DeepLink'
    }
  },
  reward2: {
    desc: {
      title1: 'Nút DeepLink',
      text1: 'Chỉ những người sở hữu nút mới có thể tham gia khai thác lưu lượng CPU và khai thác máy GPU cá nhân. Tổng phần thưởng là 12% DLC Tokens, trong đó 4% dành cho các nút lưu lượng CPU và 8% dành cho các nút GPU cá nhân. Tổng số lượng token là 12 tỷ. Sản lượng của mỗi nút liên quan đến số lượng nút thực tế đã bán ra. Nếu tất cả được bán hết, mỗi nút sẽ sản xuất 100,000 DLC. Nếu chỉ có 10% nút được bán ra, mỗi nút sẽ sản xuất 1 triệu DLC.',
      title2: 'Tổng số nút: 120,000 không phát hành thêm',
      text2: 'Mỗi nút nhận được airdrop bổ sung',
      text3: 'Số lượng DLC khai thác tối thiểu của mỗi nút',
      text4: 'Giá ban đầu',
      text5: 'Giá cao nhất'
    },
    table: {
      tier: 'Cấp độ',
      license: 'Giá giấy phép nút (USDT)',
      total_num: 'Tổng số giấy phép',
      total_usdt: 'Tổng USDT',
      buy: 'Mua'
    },
    intro: {
      title: 'Giới thiệu khai thác nút',
      text1_1: 'Càng có nhiều người dùng mạng DeepLink, nhu cầu về lưu lượng mạng và khả năng tính toán càng lớn, điều này dẫn đến nhu cầu nhiều hơn về các máy lưu lượng CPU và máy tính GPU để cung cấp cơ sở hạ tầng cho mạng DeepLink, đạt được phi tập trung hóa và giúp DeepLink phát triển nhanh hơn. Bằng cách mua quyền hạn nút, bạn có cơ hội tham gia khai thác và nhận phần thưởng. Có thắc mắc nào không? Hãy đến',
      text1_2: 'và',
      text1_3: 'trò chuyện với chúng tôi ở trên.',
      text2: 'Mỗi nút được phát hành dưới dạng NFT và được nhận thêm 30,000 DLC thông qua airdrop. Airdrop bắt đầu trước khi niêm yết trên sàn giao dịch, với 20% DLC từ airdrop được mở khóa ngay lập tức và 80% còn lại được mở khóa trong 8 tháng. Tải phần mềm DeeLink để tạo địa chỉ ví DLC:',
      text3: 'Các nút không thể được bán lại cho người khác trước ngày 31 tháng 10 năm 2024.',
      text4: '25% token khai thác được mở khóa ngay lập tức, với 75% còn lại được mở khóa dần dần trong 150 ngày.',
      text5: 'Nếu việc khai thác không bắt đầu trong vòng 10 ngày sau khi niêm yết, phần thưởng sẽ được phân phối thông qua airdrop, với airdrop diễn ra mỗi 7 ngày. Quy tắc airdrop giống như quy tắc khai thác, với tổng sản lượng hàng ngày là 4,1 triệu DLC được phân bổ đều dựa trên tổng số nút đã bán. 25% được mở khóa ngay lập tức và 75% được mở khóa dần dần trong 150 ngày. Nếu việc khai thác đã bắt đầu, bạn cần có các máy tương ứng để nhận phần thưởng.'
    },
    question: {
      ques1: 'Tổng cộng có bao nhiêu NFT nút được bán?',
      ans1: 'Tổng cộng có 120,000',
      ques2: 'Mỗi NFT nút có giá bao nhiêu?',
      ans2: 'Chia thành 10 cấp, với giá từ 30U đến 155U, được bán trên trang web chính thức cho đến khi bán hết',
      ques3: 'Việc mua NFT nút có phải là thanh toán một lần không?',
      ans3: 'Có, việc mua nút là thanh toán một lần và không yêu cầu phí bổ sung trong tương lai',
      ques4: 'Có airdrop bổ sung nào khi mua NFT nút không?',
      ans4: 'Có, mỗi chủ sở hữu nút nhận được airdrop 30,000 DLC. Airdrop bắt đầu trước khi niêm yết trên sàn giao dịch, với 20% DLC được mở khóa ngay lập tức và 80% được mở khóa trong 8 tháng',
      ques5: 'Cơ chế khai thác cụ thể là gì?',
      ans5: 'Tổng phần thưởng là 12 tỷ DLC. Tổng thu nhập từ các nút lưu lượng là 4 tỷ DLC và tổng thu nhập từ các nút máy GPU cá nhân là 8 tỷ DLC. Bằng cách mua một NFT nút, bạn có thể tham gia khai thác cả hai loại đồng thời. Phần thưởng trong 4 năm đầu là 1.5 tỷ DLC mỗi năm, giảm một nửa mỗi 4 năm. Tổng phần thưởng hàng ngày là 4.11 triệu DLC, chỉ được phân phối cho các chủ sở hữu NFT nút. 25% số token được khai thác sẽ được mở khóa ngay lập tức, với 75% còn lại được mở khóa dần trong 150 ngày',
      ques6: 'NFT nút đã mua có thể tham gia cả khai thác máy lưu lượng CPU và khai thác máy GPU cá nhân không?',
      ans6: 'Có',
      ques7: 'Nút máy lưu lượng băng thông CPU là gì?',
      ans7: 'Nút máy lưu lượng băng thông CPU đảm nhiệm nhiệm vụ chính là tính toán và chuyển tiếp lưu lượng trong mạng DeepLink. Thông qua mô hình khai thác, DeepLink cho phép nhiều người tham gia hơn và kiếm được phần thưởng. Tổng thu nhập từ các nút lưu lượng là 4 tỷ DLC',
      ques8: 'Phần thưởng tổng cộng cho các máy lưu lượng băng thông trong năm đầu tiên là bao nhiêu?',
      ans8: 'Phần thưởng trong năm đầu tiên là 500 triệu DLC, với khoảng 1.37 triệu DLC được thưởng hàng ngày',
      ques9: 'Cách tính mối quan hệ giữa thu nhập của mỗi nút máy lưu lượng băng thông CPU và băng thông?',
      ans9: 'Băng thông cơ bản là băng thông uplink 10Mb. Giá trị băng thông là 10 cho băng thông uplink 10Mb. Băng thông chỉ có hiệu lực trong các bội số của 10, ví dụ, băng thông uplink 45Mb được tính là 40 theo giá trị băng thông. Băng thông tăng 30% theo sử dụng thực tế. Cuối cùng, thu nhập được chia đều theo giá trị băng thông thực tế',
      ques10: 'Yêu cầu cấu hình của các nút máy lưu lượng băng thông có cao không? Có thông tin cấu hình nào không? Làm thế nào để tham gia khai thác?',
      ans10: 'Yêu cầu cấu hình không cao. vCPU 1C + 2G bộ nhớ + 50G ổ cứng. Chi phí thuê trên AWS khoảng 10 USD mỗi tháng, với băng thông được tính phí theo sử dụng thực tế. Sau khi thuê một máy ảo CPU trên AWS, cài đặt chương trình khai thác nút lưu lượng DeepLink, tạo địa chỉ ví, chuyển NFT nút vào ví và kích hoạt chức năng khai thác để tham gia khai thác',
      ques11: 'Nút máy GPU cá nhân là gì?',
      ans11: 'Các cá nhân có thể cung cấp máy tính GPU của mình để tham gia khai thác. Máy GPU cung cấp dịch vụ hạ tầng chơi game đám mây cho Giao thức DeepLink. Tổng thu nhập từ các nút máy GPU cá nhân là 8 tỷ DLC',
      ques12: 'Phần thưởng tổng cộng cho các nút máy GPU cá nhân trong năm đầu tiên là bao nhiêu?',
      ans12: 'Phần thưởng trong năm đầu tiên là 1 tỷ DLC, với khoảng 2.74 triệu DLC được thưởng hàng ngày',
      ques13: 'Yêu cầu cấu hình của các máy GPU cá nhân là gì? Làm thế nào để tham gia khai thác?',
      ans13: 'Yêu cầu cấu hình của các máy GPU: Tần số CPU không dưới 3.0GHz, GPU từ series Nvidia Geforce: 20xx, 30xx, 40xx, và ít nhất 16G bộ nhớ'
    }
  }
}