<template>
  <div class="Lanchpad" :class="{lan_En : lan != 'zh'}" @scroll.passive="handleScroll">
    <div class="content content1">
      <div class="meteor"></div>
      <div class="meteor1"></div>
      <div class="meteor2"></div>
      <div class="bg1"></div>
      <div class="bg2"></div>
      <div class="bg3"></div>
      <div class="bg4"></div>
      <div class="bg5"></div>
      <div class="bg6"></div>
      <div class="bg7"></div>
      <div class="bg8"></div>
      <div class="bg9"></div>
      <div class="bg10"></div>
      <div class="bg11"></div>
      <div class="line_point" v-for="(item, index) in pointList" :class="{active: item.active &&  !item.samll, small: item.samll ? item.active && item.samll :false }" :style="{'top': item.top+'px'}" :key="index"></div>
      <div class="line" ref="lineRef"><img src="../assets/line.svg" alt=""></div>
      <div class="line3"></div>
      <div class="line2"></div>
      <div class="cont cont1">
        <div class="title">
          <p>{{ $t('lanchpad.cont1.title1') }}</p>
          <p>{{ $t('lanchpad.cont1.desc') }}</p>
        </div>
        <div class="desc">{{ $t('lanchpad.cont1.title2') }}</div>
        <div class="btnList">
          <!-- <div class="btn" @click="linkHref('https://www.pinksale.finance/launchpad/0x38300f83021D5461cf2073725aA27aa45a3306ad?chain=ETH')">{{ $t('lanchpad.cont1.btn1') }}</div> -->
          <div class="btn" @click="downLoad('white')">{{ $t('lanchpad.cont1.btn2') }}</div>
          <div class="btn" @click="downLoad('Protocol')">{{ $t('lanchpad.cont1.btn3') }}</div> 
        </div>
        <div class="tips">{{ $t('lanchpad.cont1.desc1') }}</div>
        <div class="gif"><img src="../assets/lanch.gif" alt=""></div>
      </div>
      <div class="cont cont2">
        <div class="videoPaly">
          <video ref="videoRef" src="https://www.deeplink.cloud/vedio.mp4"
            loop="loop" 
            muted="muted" 
            controls="controls"
            controlsList="noplaybackrate, nopip">
          </video>
        </div>
        <div class="imgbg">
          <div class="video1">
            <video src="../assets/lanch_video1.mp4"
              loop="loop" 
              muted="muted" 
              autoplay>
            </video>
          </div>
          <div class="text">Distance:50km</div>
          <div class="video2">
            <video src="../assets/lanch_video2.mp4"
              loop="loop" 
              muted="muted" 
              autoplay>
            </video>
          </div>
          <!-- <div class="text">
            <div class="text1">{{ $t('lanchpad.cont2.desc1') }}</div>
            <div class="text2">{{ $t('lanchpad.cont2.desc1') }} </div>
          </div> -->
        </div>
        <!-- <div class="videoBtn" @click="dialogTableVisible = true">{{ $t('lanchpad.cont2.btn') }}</div> -->
      </div>
      <div class="cont cont3">
        <div class="warp-data" ref="wrapper">
          <div class="data_ul" ref="marquee" @mouseover="mouseover" @mouseout="mouseout">
            <div ref="warp_cont">
              <div class="data_li">
                <div class="left">1ms</div>
                <span>{{ $t('lanchpad.cont3.text1') }}</span>
              </div>
              <div class="data_li">
                <div class="left">8K</div>
                <span>{{ $t('lanchpad.cont3.text2') }}</span>
              </div>
              <div class="data_li">
                <div class="left">244Hz</div>
                <span>{{ $t('lanchpad.cont3.text3') }}</span>
              </div>
              <div class="data_li">
                <div class="left">24H</div>
                <span>{{ $t('lanchpad.cont3.text4') }}</span>
              </div>
            </div>
            <div class="data_li blank" ref="blank">
            </div>
            <div>
              <div class="data_li">
                <div class="left">1ms</div>
                <span>{{ $t('lanchpad.cont3.text1') }}</span>
              </div>
              <div class="data_li">
                <div class="left">8K</div>
                <span>{{ $t('lanchpad.cont3.text2') }}</span>
              </div>
              <div class="data_li">
                <div class="left">244Hz</div>
                <span>{{ $t('lanchpad.cont3.text3') }}</span>
              </div>
              <div class="data_li">
                <div class="left">24H</div>
                <span>{{ $t('lanchpad.cont3.text4') }}</span>
              </div>
            </div>
            <div class="data_li blank" ref="blank1">
              
            </div>
          </div>
        </div>
        <div class="text_desc">
          <div class="title">{{ $t('lanchpad.cont3.desc1') }}</div>
          <div class="text">{{ $t('lanchpad.cont3.desc2') }}</div>
        </div>
      </div>
      <div class="cont cont16">
        <div class="title">{{ $t('lanchpad.cont16.title1') }}</div>
        <div class="desc">{{ $t('lanchpad.cont16.title2') }}</div>
        <div class="product">
          <div class="pro_cont">
            <div class="title">{{ $t('lanchpad.cont16.text1') }}</div>
            <div class="img"><img src="../assets/cont16_img5.png"/></div> 
            <div class="span">deeplink.cloud</div>
          </div>
          <div class="pro_cont">
            <div class="title">{{ $t('lanchpad.cont16.text2') }}</div>
            <div class="img"><img src="../assets/cont16_img1.png"/></div>
            <div class="span">hycons.cloud</div>
            <div class="img"><img src="../assets/cont16_img5.png"/></div> 
            <div class="span">deeplink.cloud</div>
          </div>
          <div class="pro_cont">
            <div class="title">{{ $t('lanchpad.cont16.text3') }}</div>
            <div class="img"><img src="../assets/cont16_img4.png"/></div>
            <div class="img"><img src="../assets/cont16_img3.png"/></div>
            <div class="img"><img src="../assets/cont16_img2.png"/></div>
            <div class="img"><img src="../assets/cont16_img1.png"/></div>
          </div>
          <div class="pro_cont">
            <div class="title">{{ $t('lanchpad.cont16.text4') }}</div>
            <div class="span">{{ $t('lanchpad.cont16.text5') }}</div>
            <div class="span">{{ $t('lanchpad.cont16.text6') }}</div> 
          </div>
        </div>
      </div>
      <div class="cont cont4">
        <div class="title">{{ $t('lanchpad.cont4.title1') }}</div>
        <div class="desc">{{ $t('lanchpad.cont4.title2') }}</div>
        <div class="inlet show">
          <div class="round">
            <div class="top">{{ $t('lanchpad.cont4.text1_1') }}</div>
            <div class="text text1">{{ $t('lanchpad.cont4.text1_2') }}</div>
          </div>
          <div class="round">
            <div class="top">{{ $t('lanchpad.cont4.text2_1') }}</div>
            <div class="text">{{ $t('lanchpad.cont4.text2_2') }}</div>
          </div>
          <div class="center">
            <div class="block1">{{ $t('lanchpad.cont4.desc1') }}</div>
            <div class="block2">{{ $t('lanchpad.cont4.desc2') }}</div>
            <div class="block3">{{ $t('lanchpad.cont4.desc3') }}</div>
          </div>
          <div class="round">
            <div class="top">{{ $t('lanchpad.cont4.text3_1') }}</div>
            <div class="text">{{ $t('lanchpad.cont4.text3_2') }}</div>
          </div>
          <div class="round">
            <div class="top">{{ $t('lanchpad.cont4.text4_1') }}</div>
            <div class="text">{{ $t('lanchpad.cont4.text4_2') }}</div>
          </div>
        </div>
        <div class="inlet hide">
          <div class="center">
            <div class="block1">{{ $t('lanchpad.cont4.desc1') }}</div>
            <div class="block2">{{ $t('lanchpad.cont4.desc2') }}</div>
            <div class="block3">{{ $t('lanchpad.cont4.desc3') }}</div>
          </div>
          <div class="inletFlex">
            <div class="round">
              <div class="top">{{ $t('lanchpad.cont4.text1_1') }}</div>
              <div class="text text1">{{ $t('lanchpad.cont4.text1_2') }}</div>
            </div>
            <div class="round">
              <div class="top">{{ $t('lanchpad.cont4.text2_1') }}</div>
              <div class="text">{{ $t('lanchpad.cont4.text2_2') }}</div>
            </div>
            <div class="round">
              <div class="top">{{ $t('lanchpad.cont4.text3_1') }}</div>
              <div class="text">{{ $t('lanchpad.cont4.text3_2') }}</div>
            </div>
            <div class="round">
              <div class="top">{{ $t('lanchpad.cont4.text4_1') }}</div>
              <div class="text">{{ $t('lanchpad.cont4.text4_2') }}</div>
            </div>
          </div>
        </div>
        <div class="viewpoint">
          <div class="li" @click="linkHref('https://youtu.be/lO7XVxveGR8')">{{ $t('lanchpad.cont4.btn1') }}</div>
          <div class="li" @click="linkHref('https://youtu.be/6slRE67heX8')">{{ $t('lanchpad.cont4.btn2') }}</div>
          <div class="li" @click="linkHref('https://youtu.be/G9MvdCAsngY')">{{ $t('lanchpad.cont4.btn3') }}</div>
          <div class="li" @click="linkHref('https://youtu.be/dl0C4uAFz0o')">{{ $t('lanchpad.cont4.btn4') }}</div>
        </div>
      </div>
      <div class="cont cont17">
        <div class="title">{{ $t('lanchpad.cont17.title1') }}</div>
        <div class="mode">
          <div class="modetype mode1">
            <div class="title">{{ $t('lanchpad.cont17.text1') }}</div>
            <div class="desc">{{ $t('lanchpad.cont17.desc1') }}</div>
          </div>
          <div class="modetype mode2">
            <div class="title">{{ $t('lanchpad.cont17.text2') }}</div>
            <div class="desc">{{ $t('lanchpad.cont17.desc2') }}</div>
          </div>
        </div>
      </div>
      <div class="cont cont5">
        <div class="title">{{ $t('lanchpad.cont5.title1') }}</div>
        <div class="intro">
          <div class="topcenter">
            <div>{{ $t('lanchpad.cont5.desc1') }}</div>
            <div>
              <p>{{ $t('lanchpad.cont5.desc2_1') }}</p>
              <p>{{ $t('lanchpad.cont5.desc2_2') }}</p>
            </div>
          </div>
          <div class="left">
            <div class="text1">{{ $t('lanchpad.cont5.title2') }}</div>
            <div class="text2">{{ $t('lanchpad.cont5.text2') }}</div>
          </div>
          <div class="right">
            <div class="text1">{{ $t('lanchpad.cont5.title3') }}</div>
            <div class="text2">{{ $t('lanchpad.cont5.text3') }}</div>
          </div>
          <div class="details">{{ $t('lanchpad.cont5.desc3') }} <br/>{{ $t('lanchpad.cont5.desc3_2') }}</div>
        </div>
      </div>
      <div class="cont cont6">
        <div class="title">{{ $t('lanchpad.cont6.title2') }}</div>
        <div class="title">{{ $t('lanchpad.cont6.title1') }}</div>
        <div class="intro show">
          <div class="intro_li left">
            <div class="left_1">
              <p class="text">{{ $t('lanchpad.cont6.desc1') }}</p>
            </div>
            <div class="left_2">
              <div class="icon icon1"></div>
              <div class="text">{{ $t('lanchpad.cont6.text1') }}</div>
            </div>
          </div>
          <div class="intro_li right">
            <div class="right_2">
              <div class="icon icon2"></div>
              <div class="text">{{ $t('lanchpad.cont6.text2') }}</div>
            </div>
            <div class="right_1">
              <p class="text">{{ $t('lanchpad.cont6.desc2') }}</p>
            </div>
          </div>
          <div class="intro_li left">
            <div class="left_1 lineH1">
              <p class="text">{{ $t('lanchpad.cont6.desc3') }}</p>
            </div>
            <div class="left_2">
              <div class="icon icon6"></div>
              <div class="text">{{ $t('lanchpad.cont6.text3') }}</div>
            </div>
          </div>
          <div class="intro_li right">
            <div class="right_2">
              <div class="icon icon3"></div>
              <div class="text">{{ $t('lanchpad.cont6.text4') }}</div>
            </div>
            <div class="right_1 lineH1">
              <p class="text">{{ $t('lanchpad.cont6.desc4') }}</p>
            </div>
          </div>
          <div class="intro_li left">
            <div class="left_1">
              <p class="text">{{ $t('lanchpad.cont6.desc6') }}</p>
            </div>
            <div class="left_2">
              <div class="icon icon5"></div>
              <div class="text">{{ $t('lanchpad.cont6.text5') }}</div>
            </div>
          </div>
          <div class="intro_li right">
            <div class="right_2">
              <div class="icon icon4"></div>
              <div class="text">{{ $t('lanchpad.cont6.text6') }}</div>
            </div>
            <div class="right_1">
              <p class="text">{{ $t('lanchpad.cont6.desc5') }}</p>
            </div>
          </div>
        </div>
        <div class="intro hide">
          <div class="intro_desc">
            <div class="intro_li left">
              <div class="left_1">
                <p class="text">{{ $t('lanchpad.cont6.desc1') }}</p>
              </div>
              <div class="left_2">
                <div class="icon icon1"></div>
                <div class="text">{{ $t('lanchpad.cont6.text1') }}</div>
              </div>
            </div>
            <div class="point point1"></div>
          </div>
          <div class="intro_desc">
            <div class="intro_li left">
              <div class="left_1 lineH1">
                <p class="text">{{ $t('lanchpad.cont6.desc3') }}</p>
              </div>
              <div class="left_2">
                <div class="icon icon6"></div>
                <div class="text">{{ $t('lanchpad.cont6.text3') }}</div>
              </div>
            </div>
            <div class="point point2"></div>
          </div>
          <div class="intro_desc">
            <div class="intro_li left">
              <div class="left_1">
                <p class="text">{{ $t('lanchpad.cont6.desc6') }}</p>
              </div>
              <div class="left_2">
                <div class="icon icon5"></div>
                <div class="text">{{ $t('lanchpad.cont6.text5') }}</div>
              </div>
            </div>
            <div class="point point3"></div>
          </div>
          <div class="intro_desc">
            <div class="point point1"></div>
            <div class="intro_li right">
              <div class="right_2">
                <div class="icon icon2"></div>
                <div class="text">{{ $t('lanchpad.cont6.text2') }}</div>
              </div>
              <div class="right_1">
                <p class="text">{{ $t('lanchpad.cont6.desc2') }}</p>
              </div>
            </div>
          </div>
          <div class="intro_desc">
            <div class="point point2"></div>
            <div class="intro_li right">
              <div class="right_2">
                <div class="icon icon3"></div>
                <div class="text">{{ $t('lanchpad.cont6.text4') }}</div>
              </div>
              <div class="right_1 lineH1">
                <p class="text">{{ $t('lanchpad.cont6.desc4') }}</p>
              </div>
            </div>
          </div>
          <div class="intro_desc">
            <div class="point point3"></div>
            <div class="intro_li right">
              <div class="right_2">
                <div class="icon icon4"></div>
                <div class="text">{{ $t('lanchpad.cont6.text6') }}</div>
              </div>
              <div class="right_1">
                <p class="text">{{ $t('lanchpad.cont6.desc5') }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="cont cont7">
        <div class="title">{{ $t('lanchpad.cont7.title1') }}</div>
        <div class="desc">{{ $t('lanchpad.cont7.desc1') }}</div>
        <div class="intro">
          <div class="icon icon1"></div>
          <div class="icon icon2"></div>
          <div class="left">
            <div class="top_text">{{ $t('lanchpad.cont7.title2') }}</div>
            <p class="text">{{ $t('lanchpad.cont7.text1') }}</p>
            <p class="text">{{ $t('lanchpad.cont7.text2') }}</p>
            <p class="text">{{ $t('lanchpad.cont7.text3') }}</p>
          </div>
          <div class="right">
            <div class="top_text">{{ $t('lanchpad.cont7.title3') }}</div>
            <p class="text">{{ $t('lanchpad.cont7.text4') }}</p>
            <p class="text">{{ $t('lanchpad.cont7.text5') }}</p>
            <p class="text">{{ $t('lanchpad.cont7.text6') }}</p>
          </div>
        </div>
      </div>
      <div class="cont cont8">
        <div class="title">{{ $t('lanchpad.cont8.title1') }}</div>
        <div class="icon"></div>
        <div class="intro">
          <div class="ele1 line_text">{{ $t('lanchpad.cont8.text1') }}</div>
          <div class="ele2 line_text">{{ $t('lanchpad.cont8.text2') }}</div>
          <!-- <div class="ele3 line_text">{{ $t('lanchpad.cont8.text3') }}</div> -->
          <!-- <div class="ele4 line_text">{{ $t('lanchpad.cont8.text4') }}</div> -->
          <!-- <div class="ele5 line_text">{{ $t('lanchpad.cont8.text5') }}</div> -->
          <div class="ele6 line_text">{{ $t('lanchpad.cont8.text6') }}</div>
          <div class="ele7">
            <div class="icon1"></div>
            <div class="text">{{ $t('lanchpad.cont8_new.text7') }}</div>
          </div>
          <div class="ele8">
            <div class="text">{{ $t('lanchpad.cont8.text8') }}</div>
            <div class="text1">{{ $t('lanchpad.cont8.text9') }}</div>
            <div class="list">
              <p>{{ $t('lanchpad.cont8.text10') }}</p>
              <p>{{ $t('lanchpad.cont8.text11') }}</p>
              <p style="width: 100%">{{ $t('lanchpad.cont8_new.text12') }}</p>
              <!-- <p>{{ $t('lanchpad.cont8.text13') }}</p> -->
            </div>
          </div>
          <!-- <div class="ele9">
            <div class="text">{{ $t('lanchpad.cont8.text8') }}</div>
            <div class="text1">{{ $t('lanchpad.cont8.text14') }}</div>
            <div class="buy">{{ $t('lanchpad.cont8.text15') }}</div>
          </div> -->
          <div class="ele10">
            <div class="text">{{ $t('lanchpad.cont8.text16') }}</div>
            <div class="text1">{{ $t('lanchpad.cont8.text17') }}</div>
          </div>
          <div class="ele11">
            <div class="icon1"></div>
            <div class="text">{{ $t('lanchpad.cont8.text18') }}</div>
          </div>
          <!-- <div class="ele12">
            <div class="text">{{ $t('lanchpad.cont8.text19') }}</div>
            <div class="text1">{{ $t('lanchpad.cont8.text20') }}</div>
          </div> -->
          <div class="ele13">
            <div class="icon1"></div>
            <div class="text">{{ $t('lanchpad.cont8_new.text21') }}</div>
          </div>
          <div class="ele14 line_text">{{ $t('lanchpad.cont8.text4') }}</div>
          <div class="ele15">
            <div class="text">{{ $t('lanchpad.cont8_new.text22') }}</div>
            <div class="text1">{{ $t('lanchpad.cont8.text9') }}</div>
            <div class="list">
              <p>{{ $t('lanchpad.cont8_new.text23') }}</p>
              <p>{{ $t('lanchpad.cont8_new.text24') }}</p>
              <!-- <p>{{ $t('lanchpad.cont8.text13') }}</p> -->
            </div>
          </div>
        </div>
      </div>
      <div class="cont cont9">
        <div class="title">{{ $t('lanchpad.cont9.title1') }}</div>
        <div class="intro">
          <div class="left"></div>
          <div class="right">
            <div class="topcont">
              <div class="user">
                <!-- <div class="icon1"></div> -->
                <div class="text">{{ $t('lanchpad.cont9_new.text1') }}</div> 
              </div>
              <div class="text_line">{{ $t('lanchpad.cont9_new.text2') }}<span class="arrow"></span></div>
              <div class="block">
                <div class="text">{{ $t('lanchpad.cont9_new.text3') }}</div>
                <!-- <div class="text1">{{ $t('lanchpad.cont9.text4') }}</div> -->
              </div>
            </div>
            <div class="list">
              <div class="li">
                <div class="num">01</div>
                <div class="text">{{ $t('lanchpad.cont9_new.text5') }}</div>
              </div>
              <div class="li">
                <div class="num">02</div>
                <div class="text">{{ $t('lanchpad.cont9_new.text6') }}</div>
              </div>
              <div class="li width80">
                <div class="num">03</div>
                <div class="text">{{ $t('lanchpad.cont9_new.text7') }}</div>
              </div>
              <!-- <div class="li">
                <div class="num">04</div>
                <div class="text">{{ $t('lanchpad.cont9.text8') }}</div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="cont cont10">
        <div class="title">{{ $t('lanchpad.cont10.title') }}</div>
        <div class="intro show">
          <div class="left_li">
            <div class="li">
              <div class="li_title">{{ $t('lanchpad.cont10.desc1') }}</div>
              <div class="li_text">{{ $t('lanchpad.cont10.text1') }}</div>
            </div>
            <div class="li">
              <div class="li_title">{{ $t('lanchpad.cont10.desc2') }}</div>
              <div class="li_text">{{ $t('lanchpad.cont10.text2') }}</div>
            </div>
            <div class="li">
              <div class="li_title">{{ $t('lanchpad.cont10.desc11') }}</div>
              <div class="li_text">{{ $t('lanchpad.cont10.text11') }}</div>
            </div>
            <div class="li">
              <div class="li_title">{{ $t('lanchpad.cont10.desc4') }}</div>
              <div class="li_text">{{ $t('lanchpad.cont10.text4') }}</div>
            </div>
            <div class="li">
              <div class="li_title">{{ $t('lanchpad.cont10.desc5') }}</div>
              <div class="li_text">{{ $t('lanchpad.cont10.text5') }}</div>
            </div>
            <div class="li">
              <div class="li_title">{{ $t('lanchpad.cont10.desc6') }}</div>
              <div class="li_text">{{ $t('lanchpad.cont10.text6') }}</div>
            </div>
            <div class="li">
              <div class="li_title">{{ $t('lanchpad.cont10.desc7') }}</div>
              <div class="li_text">{{ $t('lanchpad.cont10.text7') }}</div>
            </div>
            <div class="li">
              <div class="li_title">{{ $t('lanchpad.cont10.desc8') }}</div>
              <div class="li_text">{{ $t('lanchpad.cont10.text8') }}</div>
            </div>
            <div class="li">
              <div class="li_title">{{ $t('lanchpad.cont10.desc9') }}</div>
              <div class="li_text">{{ $t('lanchpad.cont10.text9') }}</div>
            </div>
          </div>
          <div class="line1"><img src="../assets/line1.svg" alt=""></div>
          <div class="right_li">
            <div class="li">
              <div class="li_title">{{ $t('lanchpad.cont10.desc10') }}</div>
              <div class="li_text">{{ $t('lanchpad.cont10.text10') }}</div>
            </div>
            <div class="li">
              <div class="li_title">{{ $t('lanchpad.cont10.desc3') }}</div>
              <div class="li_text">{{ $t('lanchpad.cont10.text3') }}</div>
            </div>
            <div class="li">
              <div class="li_title">{{ $t('lanchpad.cont10.desc12') }}</div>
              <div class="li_text">{{ $t('lanchpad.cont10.text12') }}</div>
            </div>
            <div class="li">
              <div class="li_title">{{ $t('lanchpad.cont10.desc13') }}</div>
              <div class="li_text">{{ $t('lanchpad.cont10.text13') }}</div>
            </div>
            <div class="li">
              <div class="li_title">{{ $t('lanchpad.cont10.desc14') }}</div>
              <div class="li_text">{{ $t('lanchpad.cont10.text14') }}</div>
            </div>
            <div class="li">
              <div class="li_title">{{ $t('lanchpad.cont10.desc15') }}</div>
              <div class="li_text">{{ $t('lanchpad.cont10.text15') }}</div>
            </div>
            <div class="li">
              <div class="li_title">{{ $t('lanchpad.cont10.desc16') }}</div>
              <div class="li_text">{{ $t('lanchpad.cont10.text16') }}</div>
            </div>
            <div class="li">
              <div class="li_title">{{ $t('lanchpad.cont10.desc17') }}</div>
              <div class="li_text">{{ $t('lanchpad.cont10.text17') }}</div>
            </div>
          </div>
        </div>
        <div class="intro hide">
          <div class="line1"></div>
          <div class="list">
            <div class="li">
              <div class="li_title">{{ $t('lanchpad.cont10.desc1') }}</div>
              <div class="li_text">{{ $t('lanchpad.cont10.text1') }}</div>
            </div>
            <div class="li">
              <div class="li_title">{{ $t('lanchpad.cont10.desc10') }}</div>
              <div class="li_text">{{ $t('lanchpad.cont10.text10') }}</div>
            </div>
            <div class="li">
              <div class="li_title">{{ $t('lanchpad.cont10.desc2') }}</div>
              <div class="li_text">{{ $t('lanchpad.cont10.text2') }}</div>
            </div>
            <div class="li">
              <div class="li_title">{{ $t('lanchpad.cont10.desc11') }}</div>
              <div class="li_text">{{ $t('lanchpad.cont10.text11') }}</div>
            </div>
            <div class="li">
              <div class="li_title">{{ $t('lanchpad.cont10.desc3') }}</div>
              <div class="li_text">{{ $t('lanchpad.cont10.text3') }}</div>
            </div>
            <div class="li">
              <div class="li_title">{{ $t('lanchpad.cont10.desc12') }}</div>
              <div class="li_text">{{ $t('lanchpad.cont10.text12') }}</div>
            </div>
            <div class="li">
              <div class="li_title">{{ $t('lanchpad.cont10.desc4') }}</div>
              <div class="li_text">{{ $t('lanchpad.cont10.text4') }}</div> 
            </div>
            <div class="li">
              <div class="li_title">{{ $t('lanchpad.cont10.desc13') }}</div>
              <div class="li_text">{{ $t('lanchpad.cont10.text13') }}</div>
            </div>
            <div class="li">
              <div class="li_title">{{ $t('lanchpad.cont10.desc5') }}</div>
              <div class="li_text">{{ $t('lanchpad.cont10.text5') }}</div>
            </div>
            <div class="li">
              <div class="li_title">{{ $t('lanchpad.cont10.desc14') }}</div>
              <div class="li_text">{{ $t('lanchpad.cont10.text14') }}</div>
            </div>
            <div class="li">
              <div class="li_title">{{ $t('lanchpad.cont10.desc6') }}</div>
              <div class="li_text">{{ $t('lanchpad.cont10.text6') }}</div>
            </div>
            <div class="li">
              <div class="li_title">{{ $t('lanchpad.cont10.desc15') }}</div>
              <div class="li_text">{{ $t('lanchpad.cont10.text15') }}</div>
            </div>
            <div class="li">
              <div class="li_title">{{ $t('lanchpad.cont10.desc7') }}</div>
              <div class="li_text">{{ $t('lanchpad.cont10.text7') }}</div>
            </div>
            <div class="li">
              <div class="li_title">{{ $t('lanchpad.cont10.desc16') }}</div>
              <div class="li_text">{{ $t('lanchpad.cont10.text16') }}</div>
            </div>
            <div class="li">
              <div class="li_title">{{ $t('lanchpad.cont10.desc8') }}</div>
              <div class="li_text">{{ $t('lanchpad.cont10.text8') }}</div>
            </div>
            <div class="li">
              <div class="li_title">{{ $t('lanchpad.cont10.desc17') }}</div>
              <div class="li_text">{{ $t('lanchpad.cont10.text17') }}</div>
            </div>
            <div class="li">
              <div class="li_title">{{ $t('lanchpad.cont10.desc9') }}</div>
              <div class="li_text">{{ $t('lanchpad.cont10.text9') }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="cont cont11">
        <div class="title">{{ $t('lanchpad.cont11.title') }}</div>
        <div class="intro">
          <div class="userimg"></div>
          <div class="scan" :class="{active: scanShow}"></div>
          <div class="scan1"></div>
          <div class="datalist">
            <div class="datali">
              <div class="point"></div>
              <div class="data">
                <div class="top">{{ $t('lanchpad.cont11.desc1') }}</div>
                <div class="text">{{ $t('lanchpad.cont11.text1') }}</div>
              </div>
            </div>
            <div class="datali">
              <div class="point"></div>
              <div class="data">
                <div class="top">{{ $t('lanchpad.cont11.desc2') }}</div>
                <div class="text">{{ $t('lanchpad.cont11.text2') }}</div>
              </div>
            </div>
            <div class="datali">
              <div class="point"></div>
              <div class="data">
                <div class="top">{{ $t('lanchpad.cont11.desc3') }}</div>
                <div class="text">{{ $t('lanchpad.cont11.text3') }}</div>
              </div>
            </div>
            <div class="datali">
              <div class="point"></div>
              <div class="data">
                <div class="top">{{ $t('lanchpad.cont11.desc4') }}</div>
                <div class="text">{{ $t('lanchpad.cont11.text4') }}</div>
              </div>
            </div>
            <div class="datali">
              <div class="point"></div>
              <div class="data">
                <div class="top">{{ $t('lanchpad.cont11.desc5') }}</div>
                <div class="text">{{ $t('lanchpad.cont11.text5') }}</div>
              </div>
            </div>
            <div class="datali">
              <div class="point"></div>
              <div class="data">
                <div class="top">{{ $t('lanchpad.cont11.desc6') }}</div>
                <div class="text">{{ $t('lanchpad.cont11.text6') }}</div>
              </div>
            </div>
            <div class="datali">
              <div class="point"></div>
              <div class="data">
                <div class="top">{{ $t('lanchpad.cont11.desc7') }}</div>
                <div class="text">{{ $t('lanchpad.cont11.text7') }}</div>
              </div>
            </div>
            <div class="datali">
              <div class="point"></div>
              <div class="data">
                <div class="top">{{ $t('lanchpad.cont11.desc8') }}</div>
                <div class="text">{{ $t('lanchpad.cont11.text8') }}</div>
              </div>
            </div>
            <div class="datali">
              <div class="point"></div>
              <div class="data">
                <div class="top">{{ $t('lanchpad.cont11.desc9') }}</div>
                <div class="text">{{ $t('lanchpad.cont11.text9') }}</div>
              </div>
            </div>
            <div class="datali">
              <div class="point"></div>
              <div class="data">
                <div class="top">{{ $t('lanchpad.cont11.desc10') }}</div>
                <div class="text">{{ $t('lanchpad.cont11.text10') }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="cont cont12" id="economic">
        <div class="intro">
          <div class="echarDom" ref="EcharRef"></div>
          <div class="details Total" v-if="!showDraw">
            <div class="title">{{ $t('lanchpad.cont12.text8') }}</div>
            <div class="block">
              <div class="text">{{ $t('lanchpad.cont12.text9') }}</div>
              <div class="desc">{{ $t('lanchpad.cont12.text13') }}</div>
            </div>
            <!-- <div class="block">
              <div class="text">Not in circulation (billion) ：</div>
              <div class="desc">100 billion</div>
            </div> -->
            <div class="block">
              <div class="text">{{ $t('lanchpad.cont12.text10') }}</div>
              <div class="desc">{{ $t('lanchpad.cont12.text') }}</div>
            </div>
          </div>
          <div class="details Single" :class="{ font12: drawData.datalist && drawData.datalist.length }" v-else>
            <div class="title">{{ drawData.name }}</div>
            <div class="dataList" v-if="drawData.datalist && drawData.datalist.length" v-for="(item, index) in drawData.datalist">
              <div class="data_top"><span></span> {{ $t('lanchpad.cont12.text1') }} - {{ item.name }}: {{ item.quantity }}%</div>
              <div class="text"><span>{{ $t('lanchpad.cont12.text9') }}</span> {{ item.quantity }} </div>
              <div class="text"><span>{{ $t('lanchpad.cont12.text11') }}</span> 0 </div>
              <div class="text"><span>{{ $t('lanchpad.cont12.text12') }}</span> {{ item.quantity }} </div>
              <div class="text"><span>{{ $t('lanchpad.cont12.text10') }}</span> {{ item.desc }} </div>
            </div>
            <div class="dataList" v-else>
              <div class="text"><span>{{ $t('lanchpad.cont12.text9') }}</span> {{ drawData.value }} </div>
              <div class="text"><span>{{ $t('lanchpad.cont12.text11') }}</span> 0 </div>
              <div class="text"><span>{{ $t('lanchpad.cont12.text12') }}</span> {{ drawData.value }} </div>
              <div class="text"><span>{{ $t('lanchpad.cont12.text10') }}</span> {{ drawData.desc }} </div>
            </div>
          </div>
        </div>
      </div>
      <div class="cont cont13" id="team">
        <div class="title">{{ $t('lanchpad.cont13.title') }}</div>
        <div class="teamlist">
          <div class="teamli" @mouseover="showInfo(1)" @mouseout="showdetail = false">
            <div class="userimg"><img src="../assets/lanch_cont13img1.jpg" alt=""></div>
            <div class="name">YONG HE</div>
          </div>
          <div class="teamli" @mouseover="showInfo(2)" @mouseout="showdetail = false">
            <div class="userimg"><img src="../assets/lanch_cont13img4.jpg" alt=""></div>
            <div class="name">GUANGHUI LUO</div>
          </div>
          <div class="teamli" @mouseover="showInfo(3)" @mouseout="showdetail = false">
            <div class="userimg"><img src="../assets/lanch_cont13img2.jpg" alt=""></div>
            <div class="name">PARK JI HYE</div>
          </div>
          <div class="teamli" @mouseover="showInfo(4)" @mouseout="showdetail = false">
            <div class="userimg"><img src="../assets/lanch_cont13img3.png" alt=""></div>
            <div class="name">VARUN</div>
          </div>
          <div class="teamli" @mouseover="showInfo(5)" @mouseout="showdetail = false">
            <div class="userimg"><img src="../assets/lanch_cont13img5.png" alt=""></div>
            <div class="name">Mr. YANG</div>
          </div>
          <div class="teamli" @mouseover="showInfo(6)" @mouseout="showdetail = false">
            <div class="userimg"><img src="../assets/lanch_cont13img6.jpg" alt=""></div>
            <div class="name">DEREK PARK</div>
          </div>
          <div class="teamli" @mouseover="showInfo(7)" @mouseout="showdetail = false">
            <div class="userimg"><img src="../assets/lanch_cont13img7.png" alt=""></div>
            <div class="name">Joseph Alexander</div>
          </div>
        </div>
        <div v-if="showdetail" class="detailsInfo show" :class="{ top1: (showIndex > 4)}">
          <div class="name">{{ userInfo[showIndex].name }}</div>
          <div class="synopsis">{{ userInfo[showIndex].intro }}</div>
          <div class="text" v-for="(item, index) in userInfo[showIndex].experience" :key="index">{{ item }} </div>
        </div>
        <div v-if="showdetail" class="detailsInfo hide" :class="{ top1: (showIndex > 3 && showIndex <= 6), top2: (showIndex > 6)}">
          <div class="name">{{ userInfo[showIndex].name }}</div>
          <div class="synopsis">{{ userInfo[showIndex].intro }}</div>
          <div class="text" v-for="(item, index) in userInfo[showIndex].experience" :key="index">{{ item }} </div>
        </div>
      </div>
      <div class="cont cont14">
        <div class="title">{{ $t('lanchpad.cont14.title') }}</div>
        <div class="organList">
          <img @mouseover="showInfo1(0)" @mouseout="organdetail = false" src="../assets/lanch_cont14img1.png" alt="">
          <img @mouseover="showInfo1(1)" @mouseout="organdetail = false" src="../assets/lanch_cont14img2.png" alt="">
          <img @mouseover="showInfo1(2)" @mouseout="organdetail = false" src="../assets/lanch_cont14img3.png" alt="">
          <img @mouseover="showInfo1(3)" @mouseout="organdetail = false" src="../assets/lanch_cont14img4.png" alt="">
          <!-- <div @mouseover="showInfo1(4)" @mouseout="organdetail = false" class="img_pack"><img src="../assets/lanch_cont14img5.jpg" alt=""></div> -->
        </div>
        <div v-if="organdetail" class="organInfo">{{ organInfo[organIndex] }}</div>
      </div>
      <div class="cont cont15">
        <div class="title">{{ $t('lanchpad.cont15.title') }}</div>
        <div class="communityList hide">
          <div class="list"  @scroll.passive="handleScroll1" ref="progressList">
            <div class="community" @click="linkHref('https://twitter.com/deepbrainfeng/status/1737496574278725733?t=3HftJUj9ee-3cEkjYnDr1w&s=19')">
              <div class="act_img">
                <img src="../assets/lanch_cont15img12.jpg" alt="">
              </div>
              <div class="act_name ft_16">{{ $t('lanchpad.cont15.name12') }}</div>
              <div class="act_time">20/12 2023</div>
            </div>
            <div class="community">
              <div class="act_img">
                <img src="../assets/lanch_cont15img11.jpg" alt="">
              </div>
              <div class="act_name ft_16">{{ $t('lanchpad.cont15.name11') }}</div>
              <div class="act_time">12/10 2023</div>
            </div>
            <div class="community" @click="linkHref('https://twitter.com/DeepLinkVietNam/status/1710551891061756038')">
              <div class="act_img">
                <img src="../assets/lanch_cont15img10.jpg" alt="">
              </div>
              <div class="act_name">{{ $t('lanchpad.cont15.name10') }}</div>
              <div class="act_time">06/10 2023</div>
            </div>
            <div class="community" @click="linkHref('https://twitter.com/DeepLinkGlobal/status/1666819765401456645?s=20')">
              <div class="act_img">
                <img src="../assets/lanch_cont15img1.png" alt="">
              </div>
              <div class="act_name">{{ $t('lanchpad.cont15.name1') }}</div>
              <div class="act_time">08/06 2023</div>
            </div>
            <div class="community" @click="linkHref('https://twitter.com/DeepLinkGlobal/status/1655215428111855616?s=20')">
              <div class="act_img">
                <img src="../assets/lanch_cont15img2.png" alt="">
              </div>
              <div class="act_name">{{ $t('lanchpad.cont15.name2') }}</div>
              <div class="act_time">07/05 2023</div>
            </div>
            <div class="community" @click="linkHref('https://twitter.com/deepbrainfeng/status/1647529443945226241?s=20')">
              <div class="act_img">
                <img src="../assets/lanch_cont15img3.png" alt="">
              </div>
              <div class="act_name">{{ $t('lanchpad.cont15.name3') }}</div>
              <div class="act_time">13/04 2023</div>
            </div>
            <div class="community" @click="linkHref('https://twitter.com/DeepLinkGlobal/status/1645438888696061961?s=20')">
              <div class="act_img">
                <img src="../assets/lanch_cont15img4.png" alt="">
              </div>
              <div class="act_name">{{ $t('lanchpad.cont15.name4') }}</div>
              <div class="act_time">10/04 2023</div>
            </div>
            <div class="community" @click="linkHref('https://twitter.com/DeepLinkGlobal/status/1638801572325982211?s=20')">
              <div class="act_img">
                <img src="../assets/lanch_cont15img5.png" alt="">
              </div>
              <div class="act_name">{{ $t('lanchpad.cont15.name5') }}</div>
              <div class="act_time">23/03 2023</div>
            </div>
            <div class="community" @click="linkHref('https://twitter.com/DeepLinkGlobal/status/1638800911022604288?s=20')">
              <div class="act_img">
                <img src="../assets/lanch_cont15img6.png" alt="">
              </div>
              <div class="act_name">{{ $t('lanchpad.cont15.name6') }}</div>
              <div class="act_time">22/03 2023</div>
            </div>
            <div class="community" @click="linkHref('https://twitter.com/DeepLinkGlobal/status/1636052278850338849?s=20')">
              <div class="act_img">
                <img src="../assets/lanch_cont15img7.png" alt="">
              </div>
              <div class="act_name">{{ $t('lanchpad.cont15.name7') }}</div>
              <div class="act_time">16/03 2023</div>
            </div>
            <div class="community" @click="linkHref('https://twitter.com/deepbrainfeng/status/1626592760806670340?s=20')">
              <div class="act_img">
                <img src="../assets/lanch_cont15img8.png" alt="">
              </div>
              <div class="act_name">{{ $t('lanchpad.cont15.name8') }}</div>
              <div class="act_time">17/02 2023</div>
            </div>
            <div class="community" @click="linkHref('https://twitter.com/deepbrainfeng/status/1615370872252137473?s=20')">
              <div class="act_img">
                <img src="../assets/lanch_cont15img9.png" alt="">
              </div>
              <div class="act_name">{{ $t('lanchpad.cont15.name9') }}</div>
              <div class="act_time">17/01 2023</div>
            </div>
          </div>
          <div class="progress"><div class="scrollBar" ref="scrollBar"></div>></div>
        </div>
        <div class="communityList show">
          <div class="community" @click="linkHref('https://x.com/DeepLinkGlobal/status/1782837730356912583')">
            <div class="act_img">
              <img src="../assets/lanch_cont15img19.png" alt="">
            </div>
            <div class="act_name ft_16">{{ $t('lanchpad.cont15.name19') }}</div>
            <div class="act_time">24/04 2024</div>
          </div>
          <div class="community" @click="linkHref('https://x.com/DeepLinkGlobal/status/1782136865912750084')">
            <div class="act_img">
              <img src="../assets/lanch_cont15img18.png" alt="">
            </div>
            <div class="act_name ft_16">{{ $t('lanchpad.cont15.name18') }}</div>
            <div class="act_time">22/04 2024</div>
          </div>
          <div class="community" @click="linkHref('https://x.com/deepbrainfeng/status/1782119869405909398')">
            <div class="act_img">
              <img src="../assets/lanch_cont15img17.png" alt="">
            </div>
            <div class="act_name ft_16">{{ $t('lanchpad.cont15.name17') }}</div>
            <div class="act_time">22/04 2024</div>
          </div>
          <div class="community" @click="linkHref('https://x.com/DeepLinkGlobal/status/1781775141006868562')">
            <div class="act_img">
              <img src="../assets/lanch_cont15img16.png" alt="">
            </div>
            <div class="act_name ft_16">{{ $t('lanchpad.cont15.name16') }}</div>
            <div class="act_time">21/04 2024</div>
          </div>
          <div class="community" @click="linkHref('https://x.com/DeepLinkGlobal/status/1781536846264279143')">
            <div class="act_img">
              <img src="../assets/lanch_cont15img15.png" alt="">
            </div>
            <div class="act_name ft_16">{{ $t('lanchpad.cont15.name15') }}</div>
            <div class="act_time">20/04 2024</div>
          </div>
          <div class="community" @click="linkHref('https://x.com/DeepLinkGlobal/status/1780990818133926167')">
            <div class="act_img">
              <img src="../assets/lanch_cont15img14.png" alt="">
            </div>
            <div class="act_name ft_16">{{ $t('lanchpad.cont15.name14') }}</div>
            <div class="act_time">19/04 2024</div>
          </div>
          <div class="community" @click="linkHref('https://x.com/DeepLinkGlobal/status/1779328676653674607')">
            <div class="act_img">
              <img src="../assets/lanch_cont15img13.png" alt="">
            </div>
            <div class="act_name ft_16">{{ $t('lanchpad.cont15.name13') }}</div>
            <div class="act_time">14/04 2024</div>
          </div>

          <div class="community" @click="linkHref('https://twitter.com/deepbrainfeng/status/1737496574278725733?t=3HftJUj9ee-3cEkjYnDr1w&s=19')">
            <div class="act_img">
              <img src="../assets/lanch_cont15img12.jpg" alt="">
            </div>
            <div class="act_name ft_16">{{ $t('lanchpad.cont15.name12') }}</div>
            <div class="act_time">20/12 2023</div>
          </div>
          <div class="community">
            <div class="act_img">
              <img src="../assets/lanch_cont15img11.jpg" alt="">
            </div>
            <div class="act_name ft_16">{{ $t('lanchpad.cont15.name11') }}</div>
            <div class="act_time">12/10 2023</div>
          </div>
          <div class="community" @click="linkHref('https://twitter.com/DeepLinkVietNam/status/1710551891061756038')">
            <div class="act_img">
              <img src="../assets/lanch_cont15img10.jpg" alt="">
            </div>
            <div class="act_name">{{ $t('lanchpad.cont15.name10') }}</div>
            <div class="act_time">06/10 2023</div>
          </div>
          <div class="community" @click="linkHref('https://twitter.com/DeepLinkGlobal/status/1666819765401456645?s=20')">
            <div class="act_img">
              <img src="../assets/lanch_cont15img1.png" alt="">
            </div>
            <div class="act_name">{{ $t('lanchpad.cont15.name1') }}</div>
            <div class="act_time">08/06 2023</div>
          </div>
          <div class="community" @click="linkHref('https://twitter.com/DeepLinkGlobal/status/1655215428111855616?s=20')">
            <div class="act_img">
              <img src="../assets/lanch_cont15img2.png" alt="">
            </div>
            <div class="act_name">{{ $t('lanchpad.cont15.name2') }}</div>
            <div class="act_time">07/05 2023</div>
          </div>
          <div class="community" @click="linkHref('https://twitter.com/deepbrainfeng/status/1647529443945226241?s=20')">
            <div class="act_img">
              <img src="../assets/lanch_cont15img3.png" alt="">
            </div>
            <div class="act_name">{{ $t('lanchpad.cont15.name3') }}</div>
            <div class="act_time">13/04 2023</div>
          </div>
          <div class="community" @click="linkHref('https://twitter.com/DeepLinkGlobal/status/1645438888696061961?s=20')">
            <div class="act_img">
              <img src="../assets/lanch_cont15img4.png" alt="">
            </div>
            <div class="act_name">{{ $t('lanchpad.cont15.name4') }}</div>
            <div class="act_time">10/04 2023</div>
          </div>
          <div class="community" @click="linkHref('https://twitter.com/DeepLinkGlobal/status/1638801572325982211?s=20')">
            <div class="act_img">
              <img src="../assets/lanch_cont15img5.png" alt="">
            </div>
            <div class="act_name">{{ $t('lanchpad.cont15.name5') }}</div>
            <div class="act_time">23/03 2023</div>
          </div>
          <div class="community" @click="linkHref('https://twitter.com/DeepLinkGlobal/status/1638800911022604288?s=20')">
            <div class="act_img">
              <img src="../assets/lanch_cont15img6.png" alt="">
            </div>
            <div class="act_name">{{ $t('lanchpad.cont15.name6') }}</div>
            <div class="act_time">22/03 2023</div>
          </div>
          <div class="community" @click="linkHref('https://twitter.com/DeepLinkGlobal/status/1636052278850338849?s=20')">
            <div class="act_img">
              <img src="../assets/lanch_cont15img7.png" alt="">
            </div>
            <div class="act_name">{{ $t('lanchpad.cont15.name7') }}</div>
            <div class="act_time">16/03 2023</div>
          </div>
          <div class="community" @click="linkHref('https://twitter.com/deepbrainfeng/status/1626592760806670340?s=20')">
            <div class="act_img">
              <img src="../assets/lanch_cont15img8.png" alt="">
            </div>
            <div class="act_name">{{ $t('lanchpad.cont15.name8') }}</div>
            <div class="act_time">17/02 2023</div>
          </div>
          <div class="community" @click="linkHref('https://twitter.com/deepbrainfeng/status/1615370872252137473?s=20')">
            <div class="act_img">
              <img src="../assets/lanch_cont15img9.png" alt="">
            </div>
            <div class="act_name">{{ $t('lanchpad.cont15.name9') }}</div>
            <div class="act_time">17/01 2023</div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog v-model="dialogTableVisible" @opened="openDialog" @closed="closeDialog" :lock-scroll="false" :modal="false" title="DeepLink video" :close-on-click-modal="false" width="45%" draggable>
      <div class="dialogVideo">
        <video ref="videoRef" src="https://download.deeplink.cloud/vedio.mp4"
          loop="loop" 
          autoplay
          controls="controls"
          controlsList="noplaybackrate, nopip">
        </video>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { defineComponent, computed, ref, onBeforeMount, onBeforeUnmount, onMounted, getCurrentInstance, watch } from "vue";
  import { useI18n } from "vue-i18n";
  import { useStore } from "vuex"
  import * as echarts from 'echarts';
  export default defineComponent({
    name: 'Lanchpad',
    setup() {
      const store = useStore()
      let lan = computed(() => {
        return store.state.lan
      })
      const { t, locale } = useI18n();
      const instance = getCurrentInstance()
      const timer = ref(null)
      const box = ref('')
      const CurrentHeight = ref(0)
      const scanShow = ref(false)
      const options = ref({
        legend: {
          // top: '5%',
          bottom: '5%',
          left: 'center'
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 30,
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: [
              { value: 15, name: t('lanchpad.cont12.text2'), desc: t('lanchpad.cont12.desc2') },
              { value: 30, name: t('lanchpad.cont12.text1') , 
                datalist: [
                  { name: t('lanchpad.cont12.text1_1'), quantity: '10', desc: t('lanchpad.cont12.desc1_1') },
                  { name: t('lanchpad.cont12.text1_2'), quantity: '10', desc: t('lanchpad.cont12.desc1_2') },
                  { name: t('lanchpad.cont12.text1_3'), quantity: '5', desc: t('lanchpad.cont12.desc1_3') },
                  { name: t('lanchpad.cont12.text1_4'), quantity: '10', desc: t('lanchpad.cont12.desc1_4') },
                ]
              },
              { value: 12, name: t('lanchpad.cont12.text3'), desc: t('lanchpad.cont12.desc3') },
              { value: 5, name: t('lanchpad.cont12.text4'), desc: t('lanchpad.cont12.desc4') },
              { value: 20, name: t('lanchpad.cont12.text5'), desc: t('lanchpad.cont12.desc5') },
              { value: 10, name: t('lanchpad.cont12.text6'), desc: t('lanchpad.cont12.desc6') },
              { value: 3, name: t('lanchpad.cont12.text7'), desc: t('lanchpad.cont12.desc7') }
            ]
          }
        ]
      })
      const showDraw = ref(false)
      const drawData = ref(null)
      const userInfo = ref({
        1: { 
          name: 'HE YONG', 
          intro: t('lanchpad.cont13.intro1'), 
          experience: [
            t('lanchpad.cont13.user1_1'),
            t('lanchpad.cont13.user1_2'),
            t('lanchpad.cont13.user1_3'),
            t('lanchpad.cont13.user1_4'),
            t('lanchpad.cont13.user1_5'),
          ]
        },
        2: { 
          name: 'LUO GUANGHUI', 
          intro: t('lanchpad.cont13.intro2'), 
          experience: [
            t('lanchpad.cont13.user2_1'),
            t('lanchpad.cont13.user2_2'),
            t('lanchpad.cont13.user2_3'),
            t('lanchpad.cont13.user2_4')
          ]
        },
        3: { 
          name: 'PARK JI HYE', 
          intro: t('lanchpad.cont13.intro3'), 
          experience: [
            t('lanchpad.cont13.user3_1'),
            t('lanchpad.cont13.user3_2'),
            t('lanchpad.cont13.user3_3'),
            t('lanchpad.cont13.user3_4'),
            t('lanchpad.cont13.user3_5')
          ]
        },
        4: { 
          name: 'VARUN', 
          intro: t('lanchpad.cont13.intro4'), 
          experience: [
            t('lanchpad.cont13.user4_1'),
            t('lanchpad.cont13.user4_2'),
            t('lanchpad.cont13.user4_3'),
            t('lanchpad.cont13.user4_4'),
            t('lanchpad.cont13.user4_5'),
            t('lanchpad.cont13.user4_6'),
            t('lanchpad.cont13.user4_7'),
            t('lanchpad.cont13.user4_8'),
            t('lanchpad.cont13.user4_9'),
            t('lanchpad.cont13.user4_10'),
            t('lanchpad.cont13.user4_11'),
            t('lanchpad.cont13.user4_12'),
            t('lanchpad.cont13.user4_13'),
          ]
        },
        5: { 
          name: 'Mr. YANG', 
          intro: t('lanchpad.cont13.intro5'), 
          experience: [
            t('lanchpad.cont13.user5_1'),
            t('lanchpad.cont13.user5_2'),
            t('lanchpad.cont13.user5_3'),
            t('lanchpad.cont13.user5_4'),
            t('lanchpad.cont13.user5_5')
          ]
        },
        6: { 
          name: 'DEREK PARK', 
          intro: t('lanchpad.cont13.intro6'), 
          experience: [
            t('lanchpad.cont13.user6_1'),
            t('lanchpad.cont13.user6_2'),
            t('lanchpad.cont13.user6_3'),
            t('lanchpad.cont13.user6_4'),
            t('lanchpad.cont13.user6_5'),
            t('lanchpad.cont13.user6_6'),
            t('lanchpad.cont13.user6_7'),
            t('lanchpad.cont13.user6_8'),
            t('lanchpad.cont13.user6_9')
          ]
        },
        7: { 
          name: 'Joseph Alexander', 
          intro: t('lanchpad.cont13.intro7'), 
          experience: [
            t('lanchpad.cont13.user7_1'),
            t('lanchpad.cont13.user7_2'),
            t('lanchpad.cont13.user7_3')
          ]
        }
      })
      const showIndex = ref(1)
      const showdetail = ref(false)
      const showInfo = (el) => {
        showIndex.value = el
        showdetail.value = true
      }
      const organInfo = ref([
        t('lanchpad.cont14.organ1'),
        t('lanchpad.cont14.organ2'),
        t('lanchpad.cont14.organ3'),
        t('lanchpad.cont14.organ4'),
        t('lanchpad.cont14.organ5')
      ])
      const organIndex = ref(1)
      const organdetail = ref(false)
      const showInfo1 = (el) => {
        organIndex.value = el
        organdetail.value = true
      }

      const dialogTableVisible = ref(false)
      const openDialog = () => {
        instance.refs.videoRef.play()
      }
      const closeDialog = () => {
        instance.refs.videoRef.pause()
      }
      const drawChart = () => {
        const chart = echarts.init(instance.refs.EcharRef, null, { renderer: 'svg' })
        chart.setOption(options.value);
        chart.on('mouseover', function (params) {
          drawData.value = params.data
          showDraw.value = true
        });
        chart.on('mouseout', function (params) {
          showDraw.value = false
        });
      }
      const init = () => {
        if (instance.refs.warp_cont.clientWidth <= instance.refs.wrapper.clientWidth) {
          instance.refs.blank.style.width = instance.refs.wrapper.clientWidth - instance.refs.warp_cont.clientWidth + 10 + 'px'
          instance.refs.blank1.style.width = instance.refs.wrapper.clientWidth - instance.refs.warp_cont.clientWidth + 10 + 'px'
        }
        box.value = instance.refs.wrapper;
        timer.value = setInterval(() => {
          move();
        }, 1);
      }
      const move = () => {
        let curLeft = box.value.scrollLeft;
        let scrollWidth = instance.refs.marquee.scrollWidth / 2 + 50;
        box.value.scrollLeft = curLeft + 1;
        if (curLeft > scrollWidth) {
          box.value.scrollLeft = 0;
        }
      }
      //鼠标悬停
      const mouseover = () => {
        clearInterval(timer.value);
        timer.value = null;
      }
      //鼠标离开，继续滚动
      const mouseout = () => {
        init();
      }
      const pointList = ref([
        { top: '2740', active: false },
        { top: '2910', active: false },
        { top: '4050', active: false },
        { top: '4320', active: false },
        { top: '4460', active: false },
        { top: '4600', active: false },
        { top: '5035', active: false },
        { top: '5345', active: false },
        { top: '5885', active: false },
        { top: '6050', active: false },
        { top: '6400', active: false },
        { top: '6625', active: false },
        { top: '6700', active: false },
        { top: '6795', active: false, samll: true },
        { top: '6880', active: false, samll: true },
        { top: '6950', active: false, samll: true },
        { top: '7040', active: false, samll: true },
        { top: '7125', active: false, samll: true },
        { top: '7165', active: false, samll: true },
        { top: '7220', active: false, samll: true },
        { top: '7255', active: false, samll: true },
        { top: '7310', active: false, samll: true },
        { top: '7400', active: false }
      ])
      const downLoad = (el) => {
        if (el == 'white') {
          // if (lan.value == 'ko') {
          //   window.open('https://www.deeplink.cloud/DeepLink_Whitepaper_KO.pdf', 'target')
          // } else {
            window.open('https://www.deeplink.cloud/DeepLink_Whitepaper.pdf', 'target')
          // }
        } else {
          if (lan.value == 'ko') {
            window.open('https://www.deeplink.cloud/DeepLink_Protocol_KO.pdf', 'target')
          } else if (lan.value == 'ja') {
            window.open('https://www.deeplink.cloud/DeepLink_Protocol_JA.pdf', 'target')
          } else {
            window.open('https://www.deeplink.cloud/DeepLink_Protocol.pdf', 'target')
          }
        }
      }
      const linkHref = (el) => {
        window.open(el, 'target')
      }
      const progressList = ref(null)
      const handleScroll = () => {
        CurrentHeight.value = document.body.scrollTop || document.documentElement.scrollTop
        if (CurrentHeight.value - 1800 + window.innerHeight > 0) {
          pointList.value.map((el, index) => {
            if (CurrentHeight.value + window.innerHeight/1.5 > Number(el.top)) {
              el.active = true
              instance.refs.lineRef.style.height = el.top - 2700 + 'px'
            } else {
              el.active = false
            }
          })
        }
        if (CurrentHeight.value > 6550) {
          scanShow.value = true
        } else {
          scanShow.value = false
        }
      }
      const handleScroll1 = () => {
        instance.refs.scrollBar.style.left = `${instance.refs.progressList.scrollLeft / 50 }%`
      }
      onMounted(() => {
        init()
        drawChart()
        window.addEventListener('scroll', handleScroll)
        progressList.value = instance.refs.progressList
      })
      onBeforeUnmount(() => {
        window.removeEventListener('scroll', handleScroll)
        clearInterval(timer.value);
        timer.value = null;
      })
      watch(
        () => locale.value,
        () => {
          organInfo.value = [
            t('lanchpad.cont14.organ1'),
            t('lanchpad.cont14.organ2'),
            t('lanchpad.cont14.organ3'),
            t('lanchpad.cont14.organ4'),
            t('lanchpad.cont14.organ5')
          ]
          userInfo.value = {
            1: { 
              name: 'HE YONG', 
              intro: t('lanchpad.cont13.intro1'), 
              experience: [
                t('lanchpad.cont13.user1_1'),
                t('lanchpad.cont13.user1_2'),
                t('lanchpad.cont13.user1_3'),
                t('lanchpad.cont13.user1_4'),
                t('lanchpad.cont13.user1_5'),
              ]
            },
            2: { 
              name: 'LUO GUANGHUI', 
              intro: t('lanchpad.cont13.intro2'), 
              experience: [
                t('lanchpad.cont13.user2_1'),
                t('lanchpad.cont13.user2_2'),
                t('lanchpad.cont13.user2_3'),
                t('lanchpad.cont13.user2_4')
              ]
            },
            3: { 
              name: 'PARK JI HYE', 
              intro: t('lanchpad.cont13.intro3'), 
              experience: [
                t('lanchpad.cont13.user3_1'),
                t('lanchpad.cont13.user3_2'),
                t('lanchpad.cont13.user3_3'),
                t('lanchpad.cont13.user3_4'),
                t('lanchpad.cont13.user3_5')
              ]
            },
            4: { 
              name: 'VARUN', 
              intro: t('lanchpad.cont13.intro4'), 
              experience: [
                t('lanchpad.cont13.user4_1'),
                t('lanchpad.cont13.user4_2'),
                t('lanchpad.cont13.user4_3'),
                t('lanchpad.cont13.user4_4'),
                t('lanchpad.cont13.user4_5'),
                t('lanchpad.cont13.user4_6'),
                t('lanchpad.cont13.user4_7'),
                t('lanchpad.cont13.user4_8'),
                t('lanchpad.cont13.user4_9'),
                t('lanchpad.cont13.user4_10'),
                t('lanchpad.cont13.user4_11'),
                t('lanchpad.cont13.user4_12'),
                t('lanchpad.cont13.user4_13'),
              ]
            },
            5: { 
              name: 'Mr. YANG', 
              intro: t('lanchpad.cont13.intro5'), 
              experience: [
                t('lanchpad.cont13.user5_1'),
                t('lanchpad.cont13.user5_2'),
                t('lanchpad.cont13.user5_3'),
                t('lanchpad.cont13.user5_4'),
                t('lanchpad.cont13.user5_5')
              ]
            },
            6: { 
              name: 'DEREK PARK', 
              intro: t('lanchpad.cont13.intro6'), 
              experience: [
                t('lanchpad.cont13.user6_1'),
                t('lanchpad.cont13.user6_2'),
                t('lanchpad.cont13.user6_3'),
                t('lanchpad.cont13.user6_4'),
                t('lanchpad.cont13.user6_5'),
                t('lanchpad.cont13.user6_6'),
                t('lanchpad.cont13.user6_7'),
                t('lanchpad.cont13.user6_8'),
                t('lanchpad.cont13.user6_9')
              ]
            },
            7: { 
              name: 'Joseph Alexander', 
              intro: t('lanchpad.cont13.intro7'), 
              experience: [
                t('lanchpad.cont13.user7_1'),
                t('lanchpad.cont13.user7_2'),
                t('lanchpad.cont13.user7_3')
              ]
            }
          }
          options.value = {
            legend: {
              // top: '5%',
              bottom: '5%',
              left: 'center'
            },
            series: [
              {
                name: '',
                type: 'pie',
                radius: ['40%', '70%'],
                avoidLabelOverlap: false,
                label: {
                  show: false,
                  position: 'center'
                },
                emphasis: {
                  label: {
                    show: true,
                    fontSize: 30,
                    fontWeight: 'bold'
                  }
                },
                labelLine: {
                  show: false
                },
                data: [
                  { value: 15, name: t('lanchpad.cont12.text2'), desc: t('lanchpad.cont12.desc2') },
                  { value: 30, name: t('lanchpad.cont12.text1') , 
                    datalist: [
                      { name: t('lanchpad.cont12.text1_1'), quantity: '10', desc: t('lanchpad.cont12.desc1_1') },
                      { name: t('lanchpad.cont12.text1_2'), quantity: '10', desc: t('lanchpad.cont12.desc1_2') },
                      { name: t('lanchpad.cont12.text1_3'), quantity: '5', desc: t('lanchpad.cont12.desc1_3') },
                      { name: t('lanchpad.cont12.text1_4'), quantity: '10', desc: t('lanchpad.cont12.desc1_4') },
                    ]
                  },
                  { value: 12, name: t('lanchpad.cont12.text3'), desc: t('lanchpad.cont12.desc3') },
                  { value: 5, name: t('lanchpad.cont12.text4'), desc: t('lanchpad.cont12.desc4') },
                  { value: 20, name: t('lanchpad.cont12.text5'), desc: t('lanchpad.cont12.desc5') },
                  { value: 10, name: t('lanchpad.cont12.text6'), desc: t('lanchpad.cont12.desc6') },
                  { value: 3, name: t('lanchpad.cont12.text7'), desc: t('lanchpad.cont12.desc7') }
                ]
              }
            ]
          }
          drawChart()
        }
      )
      return {
        lan,
        scanShow,
        showDraw,
        drawData,
        pointList,
        userInfo,
        showIndex,
        showdetail,
        dialogTableVisible,
        openDialog,
        closeDialog,
        showInfo,
        organInfo,
        organIndex,
        organdetail,
        showInfo1,
        mouseover,
        mouseout,
        handleScroll,
        handleScroll1,
        linkHref,
        downLoad
      };
    }
  })
</script> 

<style lang="scss" scoped>
.Lanchpad {
  width: 100%;
  font-style: normal;
  // font-family: MiSans;
  .content {
    width: 100%;
    font-size: 0;
    line-height: 0;
    min-width: 1200px;
    position: relative;
    &.content1 {
      width: 100%;
      height: 11035px;
      overflow: hidden;
      background: url("../assets/lanchpadbg.png") no-repeat center;
      background-size: 100% 11035px;
    }
    .bg1 {
      position: absolute;
      top: -250px;
      left: -150px;
      width: 750px;
      height: 750px;
      background: url(../assets/lanch_bg1.png) no-repeat 100%/100%;
    }
    .bg2 {
      position: absolute;
      z-index: 11;
      top: -100px;
      right: -100px;
      width: 400px;
      height: 400px;
      background: url(../assets/lanch_bg2.png) no-repeat 100%/100%;
    }
    .bg3 {
      position: absolute;
      z-index: 1;
      top: 1300px;
      right: -300px;
      width: 720px;
      height: 720px;
      animation: spin 10s linear infinite;
      background: url(../assets/lanch_bg3.png) no-repeat 100%/100%;
    }
    .bg4 {
      z-index: 11;
      position: absolute;
      top: 6320px;
      right: 120px;
      width: 410px;
      height: 410px;
      background: url(../assets/lanch_bg4.png) no-repeat 100%/100%;
    }
    .bg5 {
      z-index: 11;
      position: absolute;
      top: 2100px;
      right: -180px;
      width: 380px;
      height: 380px;
      animation: shake2 10s linear infinite;
      background: url(../assets/lanch_iocn9.png) no-repeat 100%/100%;
    }
    .bg6 {
      z-index: 11;
      position: absolute;
      top: 3100px;
      left: -120px;
      width: 300px;
      height: 300px;
      animation: shake2 6s linear infinite;
      background: url(../assets/lanch_iocn7.png) no-repeat 100%/100%;
    }
    
    .bg7 {
      z-index: 1;
      position: absolute;
      top: 5400px;
      left: -100px;
      width: 280px;
      height: 280px;
      animation: shake2 8s linear infinite;
      background: url(../assets/lanch_iocn4.png) no-repeat 100%/100%;
    }
    
    .bg8 {
      z-index: 11;
      position: absolute;
      top: 3900px;
      right: -100px;
      width: 240px;
      height: 240px;
      animation: shake2 12s linear infinite;
      background: url(../assets/lanch_iocn5.png) no-repeat 100%/100%;
    }
    
    .bg9 {
      z-index: 11;
      position: absolute;
      top: 5400px;
      left: -120px;
      width: 300px;
      height: 300px;
      animation: shake2 20s linear infinite;
      background: url(../assets/lanch_iocn6.png) no-repeat 100%/100%;
    }
    .bg10 {
      z-index: 11;
      position: absolute;
      top: 8230px;
      right: 0;
      width: 286px;
      height: 466px;
      background: url(../assets/lanch_iocn10.png) no-repeat 100%/100%;
    }
    .bg11 {
      z-index: 11;
      position: absolute;
      top: 9280px;
      left: -120px;
      width: 304px;
      height: 304px;
      animation: shake2 15s linear infinite;
      background: url(../assets/lanch_iocn8.png) no-repeat 100%/100%;
    }


    .line {
      position: absolute;
      z-index: 5;
      width: 228px;
      top: 2750px;
      left: 0;
      right: 0;
      margin: auto;
      height: 0;
      overflow: hidden;
      transition: all 1s ease-in-out;
    }
    .line2 {
      position: absolute;
      z-index: 5;
      width: 4px;
      height: 190px;
      top: 5900px;
      left: 0;
      right: 0;
      margin: auto;
      background: url(../assets/lanch_line.png) no-repeat 100%/100%;
    }
    .line3 {
      position: absolute;
      z-index: 5;
      width: 4px;
      height: 244px;
      top: 6380px;
      left: 0;
      right: 0;
      margin: auto;
      background: url(../assets/lanch_line.png) no-repeat 100%/100%;
    }
    .line_point {
      position: absolute;
      z-index: 11;
      top: 1840px;
      left: 0;
      right: 0;
      margin: auto;
      width: 14px;
      height: 14px;
      background: url(../assets/lanch_icon2.png) no-repeat 100%/100%;
      &.active {
        width: 28px;
        height: 28px;
        transition: all .5s ease-in-out;
        background: url(../assets/lanch_icon1.png) no-repeat 100%/100%;
      }
      &.small {
        width: 20px;
        height: 20px;
        transition: all .5s ease-in-out;
        background: url(../assets/lanch_icon1.png) no-repeat 100%/100%;
      }
    }
    .meteor {
      position: absolute;
      width: 130px;
      height: 130px;
      animation: meteorAnim 6s linear infinite;
      background: url(../assets/lanch_start1.png) no-repeat 100%/100%;
    }
    .meteor1 {
      position: absolute;
      width: 134px;
      height: 133px;
      animation: meteorAnim1 8s linear infinite;
      background: url(../assets/lanch_start2.png) no-repeat 100%/100%;
    }
    .meteor2 {
      position: absolute;
      width: 116px;
      height: 116px;
      animation: meteorAnim2 4s linear infinite;
      background: url(../assets/lanch_start3.png) no-repeat 100%/100%;
    }
    @keyframes meteorAnim {
      0% {
        top: -130px;
        left: 900px;
      }
      100% {
        top: 900px;
        left: -130px;
      }
    }
    @keyframes meteorAnim1 {
      0% {
        top: -134px;
        left: 600px;
      }
      100% {
        top: 600px;
        left: -134px;
      }
    }
    @keyframes meteorAnim2 {
      0% {
        top: -116px;
        left: 750px;
      }
      100% {
        top: 750px;
        left: -116px;
      }
    }
    @keyframes spin {
      from {
        transform: rotate(360deg);
      }
      to {
        transform: rotate(0);
      }
    }
    @keyframes shake2 {
      0%, 100% {
        // transform: translateX(0);
        transform:rotate(0)
      }
      10%, 30%, 50%, 70%, 90% {
        // transform: translateX(-20px);
        transform:rotate(9deg)
      }
      20%, 40%, 60%, 80% {
        // transform: translateX(20px);
        transform:rotate(-9deg)
      }
    }

    
    @keyframes run{
      0%{
        left:50%;
        top:0
      }
      12.5%{
        left:0;
        top:0;
        transform:translate(-50%, -50%) rotate(0deg);
      }
      12.51%{
        transform:translate(-50%, -50%) rotate(270deg);
      }
      25%{
        top:50%;
        left: 0;
      }
      37.5%{
        top:100%;
        left: 0;
        transform:translate(-50%, -50%) rotate(270deg);
      }
      37.51%{
        transform:translate(-50%, -50%) rotate(180deg);
      }

      50%{
        top:100%;
        left: 50%;
      }
      62.5%{
        top: 100%;
        left: 100%;
        transform:translate(-50%, -50%) rotate(180deg);
      }
      62.51%{
        transform:translate(-50%, -50%) rotate(90deg);
      }
      75%{
        top: 50%;
        left:100%;
      }
      87.5%{
        top:0;
        left: 100%;
        transform:translate(-50%, -50%) rotate(90deg);
      }
      87.51%{
        transform:translate(-50%, -50%) rotate(0deg);
      }
      100%{
        left:50%;
        top:0
      }
    }
    @keyframes run2{
      0%{
        left:50%;
        top:0
      }
      12.5%{
        left:100%;
        top:0;
        transform:translate(-50%, -50%) rotate(0deg);
      }
      12.51%{
        transform:translate(-50%, -50%) rotate(90deg);
      }
      25%{
        top:50%;
        left: 100%;
      }
      37.5%{
        top:100%;
        left: 100%;
        transform:translate(-50%, -50%) rotate(90deg);
      }
      37.51%{
        transform:translate(-50%, -50%) rotate(180deg);
      }
      50%{
        top:100%;
        left: 50%;
      }
      62.5%{
        top: 100%;
        left: 0%;
        transform:translate(-50%, -50%) rotate(180deg);
      }
      62.51%{
        transform:translate(-50%, -50%) rotate(270deg);
      }
      75%{
        top: 50%;
        left:0%;
      }
      87.5%{
        top:0;
        left: 0%;
        transform:translate(-50%, -50%) rotate(270deg);
      }
      87.51%{
        transform:translate(-50%, -50%) rotate(0deg);
      }
      100%{
        left:50%;
        top:0
      }
    }
    .cont {
      z-index: 10;
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      width: 1200px;
      box-sizing: border-box;
    }
    .cont1 {
      top: 0;
      .title {
        text-align: left;
        font-size: 36px;
        color: #363F5E;
        font-weight: 600;
        line-height: 45px;
        margin: 126px 0 50px;
      }
      .desc {
        max-width: 700px;
        font-size: 26px;
        line-height: normal;
        margin-bottom: 50px;
        color: rgba(54, 63, 94, 0.68);
      }
      .btnList {
        display: flex;
        align-items: center;
        .btn {
          cursor: pointer;
          line-height: 1;
          font-size: 18px;
          font-weight: 600;
          color: #2AB26A;
          margin-right: 20px;
          padding: 12px 26px;
          border-radius: 58px;
          // font-family: PingFang SC;
          background: rgba(255, 255, 255, 0.80);
        }
      }
      .tips {
        margin-top: 20px;
        font-size: 18px;
        line-height: 1;
        color: rgba(54, 63, 94, 0.68);
      }
      .gif {
        position: absolute;
        top: 60px;
        right: 0;
        width: 435px;
        height: 400px;
        img {
          width: 435px;
          height: 400px;
        }
      }
    }
    .cont2 {
      top: 520px;
      .videoPaly {
        width: 818px;
        height: 460px;
        margin: auto;
        video {
          width: 818px;
          height: 460px;
        }
      }
      .imgbg {
        position: relative;
        width: 1200px;
        height: 440px;
        background: url(../assets/cont2bg.png) no-repeat 100%/100%;
        .video1 {
          position: absolute;
          top: 115px;
          left: 10px;
          width: 490px;
          height: 300px;
          overflow: hidden;
          border-radius: 20px 50px;
          border: 10px solid #fff;
          video {
            width: 490px;
            height: 300px;
          }
        }
        .video2 {
          position: absolute;
          top: 115px;
          right: 10px;
          width: 490px;
          height: 300px;
          overflow: hidden;
          border-radius: 50px 20px;
          border: 10px solid #fff;
          video {
            width: 490px;
            height: 300px;
          }
        }
        .text {
          position: absolute;
          top: 342px;
          left: 0;
          right: 0;
          margin: auto;
          color: #FFF;
          font-size: 14px;
          font-weight: 600;
          text-align: center;
          line-height: normal;
          // font-family: PingFang SC;
        }
      }
      .videoBtn {
        position: absolute;
        top: 460px;
        left: 0;
        right: 0;
        cursor: pointer;
        line-height: 1;
        color: #FFF;
        display: table;
        margin: auto;
        font-size: 24px;
        padding: 16px 50px;
        border-radius: 58px;
        // font-family: PingFang SC;
        background: linear-gradient(99deg, #2AB26A 0%, rgba(104, 204, 79, 0.80) 100%);
      }
    }
    .cont3 {
      top: 1510px;
      width: 100%;
      .warp-data {
        width: 100%;
        overflow: hidden;
        .data_ul {
          width: max-content;
          display: flex;
          .data_li {
            margin-right: 50px;
            display: inline-flex;
            align-items: center;
            border-radius: 89.5px;
            padding: 8px 30px 8px 8px;
            backdrop-filter: blur(6px);
            background: linear-gradient(90deg, rgba(59, 221, 150, 0.40) 0%, rgba(59, 221, 150, 0.06) 100%);
            &.blank {
              width: 400px;
              background: transparent;
              backdrop-filter: none;
            }
            .left {
              width: 98px;
              height: 40px;
              font-size: 24px;
              font-weight: 600;
              color: #363F5E;
              line-height: 40px;
              font-style: normal;
              text-align: center;
              margin-right: 15px;
              border-radius: 89.5px;
              backdrop-filter: blur(6px);
              background: rgba(55, 197, 135, 0.60);
            }
            span {
              display: inline-block;
              font-size: 18px;
              font-weight: 600;
              color: rgba(54, 63, 94, 0.68);
            }
          }
        }
      }
      .text_desc {
        width: 100%;
        height: 140px;
        display: flex;
        margin-top: 30px;
        text-align: center;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        backdrop-filter: blur(4px);
        background: linear-gradient(90deg, rgba(158, 227, 154, 0.41) 3.39%, rgba(42, 178, 106, 0.68) 49.35%, rgba(158, 227, 154, 0.41) 97.54%);
        .title {
          font-size: 32px;
          font-weight: 600;
          color: #363F5E;
          line-height: 50px;
          margin-bottom: 12px;
        }
        .text {
          line-height: 1;
          font-size: 16px;
          color: rgba(54, 63, 94, 0.60);
        }
      }
    }
    .cont4 {
      top: 2300px;
      .title {
        font-size: 32px;
        font-weight: 600;
        color: #363F5E;
        text-align: center;
        line-height: normal;
        margin-bottom: 12px;
      }
      .desc {
        font-size: 16px;
        font-weight: 500;
        text-align: center;
        line-height: normal;
        margin-bottom: 40px;
        color: rgba(54, 63, 94, 0.60);
      }
      .inlet {
        width: 100%;
        display: flex;
        margin-bottom: 50px;
        align-items: center;
        justify-content: space-between;
        &.show {
          display: flex; 
        }
        &.hide {
          display: none;
        }
        .round {
          display: flex;
          width: 168px;
          height: 168px;
          text-align: center;
          margin-top: 130px;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          background: url(../assets/lanch_round.png) no-repeat 100%/100%;
          .top {
            width: 100%;
            font-size: 36px;
            color: #358C66;
            font-weight: 600;
            margin-bottom: 5px;
            line-height: normal;
          }
          .text {
            width: 80%;
            font-size: 20px;
            line-height: normal;
            color: rgba(54, 63, 94, 0.60);
            &.text1 {
              font-size: 12px;
            }
          }
        }
        .center {
          position: relative;
          width: 405px;
          height: 360px;
          color: #FFF;
          font-size: 24px;
          line-height: 30px;
          text-align: center;
          background: url(../assets/lanch_cont4.png) no-repeat 100%/100%;
          .block1 {
            width: 100px;
            top: 40px;
            left: 0;
            right: 0;
            margin: auto;
            position: absolute;
          }
          .block2 {
            position: absolute;
            width: 100px;
            top: 250px;
            left: 32px;
          }
          .block3 {
            position: absolute;
            width: 100px;
            top: 250px;
            right: 32px;
          }
        }
      }
      
      .viewpoint {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        .li {
          cursor: pointer;
          color: #2AB26A;
          font-size: 16px;
          font-weight: 400;
          padding: 12px 30px;
          line-height: normal;
          border-radius: 58px;
          display: inline-block;
          // font-family: PingFang SC;
          background: rgba(255, 255, 255, 0.50);
        }
      }
    }
    .cont5 {
      // top: 2640px;
      top: 3500px;
      .title {
        font-size: 32px;
        font-weight: 600;
        color: #363F5E;
        text-align: center;
        line-height: normal;
        margin-bottom: 12px;
      }
      .intro {
        position: relative;
        margin: auto;
        width: 1200px;
        height: 480px;
        overflow: hidden;
        background: url(../assets/lanch_cont5bg.png) no-repeat 100%/100%;
        .topcenter {
          width: 300px;
          display: flex;
          font-size: 16px;
          font-weight: 400;
          margin: 100px auto;
          line-height: 22px; 
          // font-family: PingFang SC;
          color: rgba(54, 63, 94, 0.80);
          justify-content: space-between;
        }
        .left, .right {
          position: absolute;
          top: 115px;
          left: 120px;
          width: 240px;
          .text1 {
            color: #363F5E;
            font-size: 28px;
            font-weight: 600;
            text-align: center;
            line-height: normal;
            margin-bottom: 12px;
          }
          .text2 {
            color: rgba(54, 63, 94, 0.60);
            text-align: center;
            font-size: 20px;
            line-height: normal;
          }
        }
        .right {
          position: absolute;
          left: unset;
          top: 115px;
          right: 120px;
        }
        .details {
          position: absolute;
          top: 380px;
          left: 0;
          right: 0;
          margin: auto;
          width: 300px;
          font-size: 16px;
          font-weight: 400;
          line-height: 26px;
          text-align: center;
          // font-family: PingFang SC;
          color: rgba(54, 63, 94, 0.50);
        }
      }
    }
    .cont6 {
      top: 4120px;
      z-index: 4;
      .title {
        font-size: 32px;
        font-weight: 600;
        color: #363F5E;
        text-align: center;
        line-height: normal;
        margin-bottom: 12px;
      }
      .intro {
        width: 1200px;
        height: 420px;
        margin-top: 50px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        border-radius: 24px;
        box-sizing: border-box;
        border: 1px solid #fff;
        backdrop-filter: blur(6px);
        justify-content: space-around;
        background: rgba(255, 255, 255, 0.20);
        &.show {
          display: flex; 
        }
        &.hide {
          display: none;
        }
        .intro_li {
          width: 420px;
          height: 100px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          &.left {
            background: url(../assets/lanch_cont6li2.png) no-repeat 100%/100%;
          }
          &.right {
            background: url(../assets/lanch_cont6li1.png) no-repeat 100%/100%;
          }
          .left_1 {
            width: 310px;
            display: flex;
            padding: 0 10px;
            align-items: center;
            box-sizing: border-box;
            .text {
              width: 100%;
              font-size: 14px;
              font-weight: 400;
              line-height: 24px;
              text-align: right;
              color: rgba(54, 63, 94, 0.68);
            }
          }
          .left_2 {
            width: 100px;
            display: flex;
            margin-right: 10px;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            .icon {
              width: 30px;
              height: 30px;
            }
            .text {
              font-size: 20px;
              line-height: normal;
              color: rgba(255, 255, 255, 0.80);
            }
          }
          .right_1 {
            width: 260px;
            display: flex;
            padding: 0 10px;
            align-items: center;
            box-sizing: border-box;
            .text {
              width: 100%;
              font-size: 14px;
              font-weight: 400;
              line-height: 24px;
              text-align: left;
              color: rgba(54, 63, 94, 0.68);
            }
          }
          .right_2 {
            width: 155px;
            display: flex;
            margin-left: 5px;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            .icon {
              width: 30px;
              height: 30px;
            }
            .text {
              font-size: 20px;
              line-height: normal;
              color: rgba(255, 255, 255, 0.80);
            }
          }
          .icon1 {
            background: url(../assets/lanch_cont6icon1.png) no-repeat 100%/100%;
          }
          .icon2 {
            background: url(../assets/lanch_cont6icon2.png) no-repeat 100%/100%;
          }
          .icon3 {
            background: url(../assets/lanch_cont6icon3.png) no-repeat 100%/100%;
          }
          .icon4 {
            background: url(../assets/lanch_cont6icon4.png) no-repeat 100%/100%;
          }
          .icon5 {
            background: url(../assets/lanch_cont6icon5.png) no-repeat 100%/100%;
          }
          .icon6 {
            background: url(../assets/lanch_cont6icon6.png) no-repeat 100%/100%;
          }
        }
      }
    }
    .cont7 {
      top: 4800px;
      .title {
        font-size: 32px;
        font-weight: 600;
        color: #363F5E;
        text-align: center;
        line-height: normal;
        margin-bottom: 12px;
      }
      .desc {
        font-size: 16px;
        font-weight: 500;
        text-align: center;
        line-height: normal;
        margin-bottom: 40px;
        color: rgba(54, 63, 94, 0.60);
      }
      .intro {
        position: relative;
        margin: auto;
        width: 1200px;
        height: 260px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: url(../assets/lanch_cont7bg.png) no-repeat 100%/100%;
        .left, .right {
          width: 550px;
          .top_text {
            font-size: 22px;
            font-weight: 600;
            line-height: normal;
            margin-bottom: 24px;
            letter-spacing: 0.88px;
            color: rgba(54, 63, 94, 0.86);
          }
          .text {
            font-size: 16px;
            line-height: normal;
            margin-bottom: 15px;
            color: rgba(54, 63, 94, 0.60);
          }
        }
        .left {
          text-align: right;
          .top_text {
            margin-right: 50px;
          }
          .text:nth-child(2) {
            margin-right: 35px;
          }
          .text:nth-child(3) {
            margin-right: 17px;
          }
          .text:nth-child(4) {
            margin-right: 0px;
          }
        }
        .right {
          width: 570px;
          .text:nth-child(2) {
            margin-left: 30px;
          }
          .text:nth-child(3) {
            margin-left: 50px;
          }
          .text:nth-child(4) {
            margin-left: 70px;
          }
        }
        .icon {
          position: absolute;
        }
        .icon1 {
          top: -80px;
          right: -30px;
          width: 160px;
          height: 176px;
          animation: spin 10s linear infinite;
          background: url(../assets/lanch_cont7icon1.png) no-repeat 100%/100%;
        }
        .icon2 {
          top: 130px;
          left: -50px;
          width: 180px;
          height: 180px;
          animation: spin 10s linear infinite;
          background: url(../assets/lanch_cont7icon2.png) no-repeat 100%/100%;
        }
      }
    }
    .cont8 {
      top: 5275px;
      .title {
        font-size: 32px;
        font-weight: 600;
        color: #363F5E;
        text-align: center;
        line-height: normal;
        margin-bottom: 43px;
      }
      .icon {
        position: absolute;
        top: -75px;
        right: 50px;
        width: 380px;
        height: 380px;
        z-index: 2;
        transform: translate(-50%, -50%);
        animation: shake 10s ease-in-out infinite;
        background: url(../assets/lanch_cont8icon.png) no-repeat 100%/100%;
      }
      @keyframes shake {
        0%, 100% {
          transform: translateX(0);
        }
        10%, 30%, 50%, 70%, 90% {
          transform: translateX(-20px);
        }
        20%, 40%, 60%, 80% {
          transform: translateX(20px);
        }
      }
      .intro {
        position: relative;
        margin: auto;
        width: 1200px;
        height: 540px;
        border-radius: 22px;
        overflow: hidden;
        background: url(../assets/lanch_cont8bg_new.png) no-repeat 100%/100%;
        // background: url(../assets/lanch_cont8bg.png) no-repeat 100%/100%;
        background-size: 1200px 540px;
        .line_text {
          font-size: 12px;
          line-height: normal;
          color: rgba(54, 63, 94, 0.60);
        }
        .ele1 {
          position: absolute;
          top: 118px;
          left: 160px;
        }
        .ele2 {
          position: absolute;
          top: 155px;
          left: 440px;
        }
        .ele3 {
          position: absolute;
          top: 275px;
          right: 430px;
        }
        .ele4 {
          position: absolute;
          top: 360px;
          left: 440px;
        }
        .ele5 {
          position: absolute;
          top: 435px;
          right: 365px;
        }
        .ele6 {
          position: absolute;
          bottom: 85px;
          left: 335px;
        }
        .ele7 {
          position: absolute;
          top: 55px;
          left: 350px;
          width: 224px;
          height: 88px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          .icon1 {
            width: 24px;
            height: 24px;
            margin-bottom: 8px;
            background: url(../assets/lanch_cont8icon1.png) no-repeat 100%/100%;
          }
          .text {
            font-size: 16px;
            line-height: normal;
            color: rgba(54, 63, 94, 0.80);
          }
        }
        .ele8 {
          position: absolute;
          width: 388px;
          height: 140px;
          top: 204px;
          left: 270px;
          padding: 15px 20px;
          box-sizing: border-box;
          .text {
            font-size: 20px;
            font-weight: 600;
            color: #363F5E;
            text-align: center;
            line-height: normal;
            margin-bottom: 2px;
          }
          .text1 {
            font-size: 13px;
            text-align: center;
            line-height: normal;
            margin-bottom: 10px;
            color: rgba(54, 63, 94, 0.66);
          }
          .list {
            display: flex;
            flex-wrap: wrap;
            font-size: 12px;
            font-weight: 400;
            line-height: normal;
            align-items: center;
            justify-content: space-between;
            color: rgba(54, 63, 94, 0.50);
            p {
              width: 50%;
              margin-bottom: 4px;
            }
          }
        }
        .ele9 {
          position: absolute;
          width: 320px;
          height: 115px;
          top: 226px;
          right: 50px;
          display: flex;
          padding: 15px 20px;
          align-items: center;
          flex-direction: column;
          box-sizing: border-box;
          justify-content: center;
          .text {
            font-size: 20px;
            font-weight: 600;
            color: #363F5E;
            line-height: normal;
            margin-bottom: 2px;
          }
          .text1 {
            font-size: 15px;
            line-height: normal;
            margin-bottom: 10px;
            color: rgba(54, 63, 94, 0.66);
          }
          .buy {
            font-size: 14px;
            font-weight: 400;
            line-height: normal;
            color: rgba(54, 63, 94, 0.60);
          }
        }
        .ele10 {
          position: absolute;
          width: 180px;
          height: 95px;
          top: 398px;
          left: 45px;
          display: flex;
          padding: 15px 20px;
          align-items: center;
          flex-direction: column;
          box-sizing: border-box;
          justify-content: center;
          .text {
            font-size: 20px;
            font-weight: 600;
            color: #363F5E;
            line-height: normal;
            margin-bottom: 2px;
          }
          .text1 {
            font-size: 15px;
            line-height: normal;
            color: rgba(54, 63, 94, 0.66);
          }
        }
        .ele11 {
          position: absolute;
          top: 402px;
          left: 420px;
          width: 88px;
          height: 88px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          .icon1 {
            width: 24px;
            height: 24px;
            margin-bottom: 8px;
            background: url(../assets/lanch_cont8icon1.png) no-repeat 100%/100%;
          }
          .text {
            font-size: 16px;
            line-height: normal;
            color: rgba(54, 63, 94, 0.80);
          }
        }
        .ele12 {
          position: absolute;
          width: 250px;
          height: 95px;
          top: 398px;
          right: 45px;
          display: flex;
          padding: 15px 20px;
          align-items: center;
          flex-direction: column;
          box-sizing: border-box;
          justify-content: center;
          .text {
            font-size: 20px;
            font-weight: 600;
            color: #363F5E;
            line-height: normal;
            margin-bottom: 2px;
          }
          .text1 {
            font-size: 15px;
            line-height: normal;
            color: rgba(54, 63, 94, 0.66);
          }
        }
        .ele13 {
          position: absolute;
          top: 230px;
          left: 750px;
          width: 224px;
          height: 88px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          .icon1 {
            width: 24px;
            height: 24px;
            margin-bottom: 8px;
            background: url(../assets/lanch_cont8icon1.png) no-repeat 100%/100%;
          }
          .text {
            font-size: 16px;
            line-height: normal;
            color: rgba(54, 63, 94, 0.80);
          }
        }
        .ele14 {
          position: absolute;
          top: 340px;
          left: 840px;
        }
        .ele15 {
          position: absolute;
          width: 328px;
          height: 114px;
          top: 395px;
          left: 695px;
          padding: 10px 15px;
          box-sizing: border-box;
          .text {
            font-size: 20px;
            font-weight: 600;
            color: #363F5E;
            text-align: center;
            line-height: normal;
            margin-bottom: 2px;
          }
          .text1 {
            font-size: 12px;
            text-align: center;
            line-height: normal;
            margin-bottom: 5px;
            color: rgba(54, 63, 94, 0.66);
          }
          .list {
            display: flex;
            flex-wrap: wrap;
            font-size: 12px;
            font-weight: 400;
            padding: 0 5px;
            line-height: normal;
            align-items: center;
            box-sizing: border-box;
            justify-content: space-between;
            color: rgba(54, 63, 94, 0.50);
            p {
              width: 100%;
              margin-bottom: 4px;
            }
          }
        }
      }
      .intro::after, .intro::before{
        content: '';
        width: 160px;
        height: 4px;
        position: absolute;
        top: 2px;
        left: 150px
      }
      .intro::before{
        left: 50px;
        animation: run 4s linear infinite;
        background: url(../assets/lanch_border2.png) no-repeat 100%/100%;
        transform: translate(-50%, -50%);
      }
      .intro::after{
        animation: run2 4s linear infinite;
        background: url(../assets/lanch_border1.png) no-repeat 100%/100%;
        transform: translate(-50%, -50%);
      }
    }
    .cont9 {
      top: 5990px;
      .title {
        font-size: 32px;
        font-weight: 600;
        color: #363F5E;
        text-align: center;
        line-height: normal;
        margin-bottom: 30px;
      }
      .intro {
        width: 1200px;
        height: 350px;
        display: flex;
        overflow: hidden;
        align-items: center;
        border-radius: 24px;
        box-sizing: border-box;
        border: 1px solid #fff;
        backdrop-filter: blur(6px);
        justify-content: space-around;
        background: rgba(255, 255, 255, 0.20);
        &::after, &::before{
          content: '';
          width: 160px;
          height: 4px;
          position: absolute;
          top: 2px;
          left: 150px
        }
        &::before{
          left: 50px;
          animation: run 4s linear infinite;
          background: url(../assets/lanch_border2.png) no-repeat 100%/100%;
          transform: translate(-50%, -50%);
        }
        &::after{
          animation: run2 4s linear infinite;
          background: url(../assets/lanch_border1.png) no-repeat 100%/100%;
          transform: translate(-50%, -50%);
        }
        .left {
          width: 268px;
          height: 268px;
          transform: translate(-50%, -50%);
          animation: shake1 20s ease-in-out infinite;
          background: url(../assets/lanch_cont9img.png) no-repeat 100%/100%;
        }
        @keyframes shake1 {
          0%, 100% {
            transform: translateX(0);
            transform: translatey(0);
          }
          10%, 30%, 50%, 70%, 90% {
            transform: translateX(-20px);
            transform: translatey(-20px);
          }
          20%, 40%, 60%, 80% {
            transform: translatey(20px);
            transform: translateX(20px);
          }
        }
        .right {
          width: 840px;
          .topcont {
            width: 800px;
            height: 94px;
            margin: auto;
            display: flex;
            align-items: center;
            justify-content: space-around;
            .user {
              width: 250px;
              height: 94px;
              display: flex;
              padding: 0 10px;
              border-radius: 20px;
              align-items: center;
              flex-direction: column;
              justify-content: center;
              backdrop-filter: blur(6px);
              background: rgba(255, 255, 255, 0.40);
              .icon1 {
                width: 24px;
                height: 24px;
                margin-bottom: 8px;
                background: url(../assets/lanch_cont8icon1.png) no-repeat 100%/100%;
              }
              .text {
                font-size: 16px;
                line-height: normal;
                color: rgba(54, 63, 94, 0.80);
              }
            }
            .text_line {
              display: flex;
              font-size: 12px;
              line-height: normal;
              align-items: center;
              color: rgba(54, 63, 94, 0.50);
              .arrow {
                width: 76px;
                height: 27px;
                margin-left: 5px;
                display: inline-block;
                background: url(../assets/lanch_cont9arrow.png) no-repeat 100%/100%;
              }
            }
            .block {
              width: 250px;
              height: 94px;
              display: flex;
              padding: 15px 20px;
              align-items: center;
              border-radius: 24px;
              flex-direction: column;
              box-sizing: border-box;
              justify-content: center;
              background: rgba(255, 255, 255, 0.40);
              .text {
                font-size: 20px;
                font-weight: 600;
                color: #363F5E;
                line-height: normal;
                margin-bottom: 2px;
              }
              .text1 {
                font-size: 15px;
                line-height: normal;
                color: rgba(54, 63, 94, 0.66);
              }
            }
          }
          .list {
            width: 840px;
            height: 160px;
            display: flex;
            padding: 10px 0;
            flex-wrap: wrap;
            margin-top: 30px;
            border-radius: 20px;
            align-items: center;
            box-sizing: border-box;
            justify-content: space-around;
            background: rgba(255, 255, 255, 0.30);
            .li{
              width: 45%;
              height: 60px;
              text-align: center;
              line-height: normal;
              &.width80 {
                width: 80%;
              }
              .num {
                font-size: 18px;
                font-weight: 600;
                color: rgba(54, 63, 94, 0.16);
              }
              .text{
                font-size: 14px;
                font-weight: 400;
                color: rgba(54, 63, 94, 0.56);
              }
            }
          }
        }
      }
    }
    .cont10 {
      top: 6530px;
      .title {
        font-size: 32px;
        font-weight: 600;
        color: #363F5E;
        text-align: center;
        line-height: normal;
        margin-bottom: 30px;
      }
      .intro {
        width: 100%;
        display: flex;
        margin-top: 50px;
        justify-content: space-between;
        &.show {
          display: flex;
        }
        &.hide {
          display: none;
        }
        .left_li, .right_li {
          width: 540px;
          line-height: normal;
          .li {
            margin-bottom: 35px;
            .li_title {
              font-size: 18px;
              font-weight: 600;
              color: #363F5E;
            }
            .li_text {
              font-size: 14px;
              line-height: normal;
              color: rgba(54, 63, 94, 0.66);
            }
          }
        }
        .left_li {
          text-align: right;
        }
        .right_li {
          padding-top: 65px;
          text-align: left;
        }
      }
    }
    .cont11 {
      top: 7440px;
      .title {
        font-size: 32px;
        font-weight: 600;
        color: #363F5E;
        text-align: center;
        line-height: normal;
        margin-bottom: 40px;
      }
      .intro {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .userimg {
          z-index: 2;
          width: 300px;
          height: 300px;
          background: url(../assets/lanch_cont11img.png) no-repeat 100%/100%;
        }
        .scan {
          position: absolute;
          top: 0;
          left: 160px;
          width: 0;
          height: 335px;
          &.active {
            width: 234px;
            transition:width 1s;
            background: url(../assets/lanch_cont11img1.png) no-repeat 100%/100%;
          }
        }
        .scan1 {
          display: none;
        }
        .datalist {
          width: 840px;
          display: flex;
          flex-wrap: wrap;
          .datali {
            width: 50%;
            height: 80px;
            display: flex;
            .point {
              width: 20px;
              height: 20px;
              margin: 2px 10px 0 0;
              background: url(../assets/lanch_icon1.png) no-repeat 100%/100%;
            }
            .data {
              flex: 1;
              padding-right: 10px;
              box-sizing: border-box;
              .top {
                color: #363F5E;
                font-size: 18px;
                font-weight: 600;
                margin-bottom: 4px;
                line-height: normal;
              }
              .text {
                font-size: 14px;
                line-height: normal;
                color: rgba(54, 63, 94, 0.66);
              }
            }
          }
        }
      }
    }
    .cont12 {
      top: 7980px;
      .intro {
        width: 1200px;
        height: 600px;
        display: flex;
        align-items: center;
        border-radius: 20px;
        justify-content: space-around;
        background: linear-gradient(180deg, rgba(152, 217, 182, 0.18) 6.92%, rgba(200, 227, 213, 0.16) 100%);
        .echarDom {
          width: 600px;
          height: 600px;
        }
        .details {
          width: 480px;
          height: 440px;
          overflow: hidden;
          padding: 24px 20px;
          line-height: normal;
          border-radius: 24px;
          box-sizing: border-box;
          backdrop-filter: blur(6px);
          background: rgba(255, 255, 255, 0.30);
          &.Total {
            text-align: center;
            padding: 24px 80px;
            .title {
              font-size: 20px;
              font-weight: 600;
              color: #363F5E;
              margin-bottom: 16px;
            }
            .block {
              text-align: center;
              font-size: 14px;
              margin-bottom: 24px;
              .text {
                color: rgba(54, 63, 94, 0.50);
              }
              .desc {
                color: #363F5E;
              }
            }
            
          }
          &.Single {
            .title {
              font-size: 20px;
              font-weight: 600;
              color: #363F5E;
              text-align: center;
              margin-bottom: 16px;
            }
            .dataList {
              .data_top {
                display: flex;
                font-size: 16px;
                color: #363F5E;
                align-items: center;
                span {
                  width: 8px;
                  height: 8px;
                  margin-right: 8px;
                  border-radius: 100%;
                  display: inline-block;
                  background: #363F5E;
                }
              }
              .text {
                font-size: 14px;
                color: rgba(54, 63, 94, 0.66);
                span {
                  font-weight: 400;
                  color: rgba(54, 63, 94, 0.40);
                }
              }
            }
          }
          &.font12 {
            padding: 12px 10px;
            .title {
              font-size: 16px;
              font-weight: 600;
              color: #363F5E;
              text-align: center;
              margin-bottom: 8px;
            }
            .dataList {
              margin-bottom: 5px;
              .data_top {
                display: flex;
                font-size: 14px;
                color: #363F5E;
                align-items: center;
                span {
                  width: 8px;
                  height: 8px;
                  margin-right: 8px;
                  border-radius: 100%;
                  display: inline-block;
                  background: #363F5E;
                }
              }
              .text {
                font-size: 12px;
                color: rgba(54, 63, 94, 0.66);
                span {
                  font-weight: 400;
                  color: rgba(54, 63, 94, 0.40);
                }
              }
            }
          }
        }
      }
    }
    .cont13 {
      top: 8650px;
      width: 1100px;
      .title {
        font-size: 32px;
        font-weight: 600;
        color: #363F5E;
        text-align: center;
        line-height: normal;
        margin-bottom: 40px;
      }
      .teamlist {
        display: flex;
        flex-wrap: wrap;
        .teamli {
          position: relative;
          width: 220px;
          height: 295px;
          overflow: hidden;
          border-radius: 24px;
          margin: 0 72px 24px 0;
          transition: all .3s ease-in-out;
          background: rgba(255, 255, 255, 0.60);
          &:hover {
            box-shadow: 0 0 4px 4px rgba(42, 178, 106, 0.50);
            -webkit-transform: translateY(-10px);
            -ms-transform: translateY(-10px);
            transform: translateY(-10px);
            .name {
              background: rgba(42, 178, 106, 0.50);
            }
          }
          &:nth-child(4n){
            margin-right: 0;
          }
          .userimg {
            width: 220px;
            height: 295px;
            img {
              width: 220px;
              min-height: 295px;
              object-fit: cover;
            }
          }
          .name {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 50px;
            display: flex;
            color: #FFF;
            font-size: 16px;
            text-align: center;
            line-height: normal;
            align-items: center;
            justify-content: center;
            background: rgba(255, 255, 255, 0.6);
            text-shadow: 0px 2px 16px rgba(54, 63, 94, 0.6)
          }
        }
      }
      .detailsInfo {
        position: absolute;
        top: 385px;
        left: 0;
        width: 1100px;
        padding: 30px;
        text-align: center;
        background: #FFF;
        border-radius: 24px;
        line-height: normal;
        box-sizing: border-box;
        &.show {
          display: block;
        }
        &.hide {
          display: none;
        }
        &.top1 {
          top: 710px;
          left: 0;
          right: 0;
          margin: auto;
          z-index: 12;
        }
        .name {
          font-size: 20px;
          font-weight: 600;
          color: #363F5E;
          margin-bottom: 5px;
        }
        .synopsis {
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 15px;
          color: rgba(78, 89, 105, 0.80);
        }
        .text {
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          text-align: center;
          color: rgba(78, 89, 105, 0.80);
        }
      }
    }
    .cont14 {
      // top: 7930px;
      z-index: 5;
      top: 9430px;
      .title {
        font-size: 32px;
        font-weight: 600;
        color: #363F5E;
        text-align: center;
        line-height: normal;
        margin-bottom: 30px;
      }
      .organList {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        .img_pack {
          display: flex;
          width: 220px;
          height: 80px;
          background: #FFF;
          align-items: center;
          border-radius: 10px;
          justify-content: center;
          transition: all .3s ease-in-out;
          box-shadow: 0px 1px 10px 3px rgba(5, 139, 204, 0.06);
          &:hover {
            -webkit-transform: translateY(-10px);
            -ms-transform: translateY(-10px);
            transform: translateY(-10px);
          }
          img {
            width: 70px;
            height: 70px;
            &:hover {
              -webkit-transform: translateY(0);
              -ms-transform: translateY(0);
              transform: translateY(0);
            }
          }
        }
        img {
          width: 240px;
          height: 100px;
          transition: all .3s ease-in-out;
          &:hover {
            -webkit-transform: translateY(-10px);
            -ms-transform: translateY(-10px);
            transform: translateY(-10px);
          }
        }
      }
      .organInfo {
        position: absolute;
        top: 170px;
        left: 0;
        width: 1200px;
        padding: 30px;
        font-size: 14px;
        background: #FFF;
        border-radius: 24px;
        line-height: normal;
        box-sizing: border-box;
        color: rgba(78, 89, 105, 0.80);
      }
    }
    .cont15 {
      // top: 8200px;
      top: 9650px;
      z-index: 1;
      .title {
        font-size: 32px;
        font-weight: 600;
        color: #363F5E;
        text-align: center;
        line-height: normal;
        margin-bottom: 30px;
      }
      .communityList {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        &.show {
          display: flex;
        }
        &.hide {
          display: none;
        }
        .community {
          width: 380px;
          cursor: pointer;
          margin: 0 20px 50px 0;
          transition: all .3s ease-in-out;
          &:hover {
            -webkit-transform: translateY(-10px);
            -ms-transform: translateY(-10px);
            transform: translateY(-10px);
          }
          &:nth-child(3n) {
            margin-right: 0;
          }
          .act_img {
            width: 380px;
            height: 220px;
            display: flex;
            line-height: 0;
            overflow: hidden;
            align-items: center;
            img {
              width: 380px;
              min-height: 220px;
            }
          }
          .act_name {
            width: 100%;
            height: 24px;
            font-size: 18px;
            font-weight: 500;
            overflow: hidden;
            margin: 10px 0 5px;
            white-space: nowrap;
            line-height: normal;
            box-sizing: border-box;
            text-overflow: ellipsis;
            color: rgba(54, 63, 94, 0.88);
            &.ft_16 {
              font-size: 16px;
            }
          }
          .act_time {
            font-size: 14px;
            font-weight: 400;
            line-height: normal;
            box-sizing: border-box;
            color: rgba(54, 63, 94, 0.60);
          }
        }
      }
    }
    .cont16 {
      top: 1800px;
      .title {
        font-size: 32px;
        font-weight: 600;
        color: #363F5E;
        text-align: center;
        line-height: normal;
        margin-bottom: 12px;
      }
      .desc {
        font-size: 16px;
        font-weight: 500;
        text-align: center;
        line-height: normal;
        margin-bottom: 40px;
        color: rgba(54, 63, 94, 0.60);
      }
      .product {
        width: 1100px;
        display: flex;
        margin: auto;
        align-items: center;
        justify-content: space-between;
        .pro_cont { 
          width: 240px;
          height: 300px;
          display: flex;
          align-items: center;
          padding: 24px 20px;
          border-radius: 20px;
          flex-direction: column;
          box-sizing: border-box;
          border: 2px solid #FFF;
          backdrop-filter: blur(6px);
          background: rgba(255, 255, 255, 0.60);
          .title {
            width: 100%;
            height: 50px;
            display: flex;
            line-height: 1;
            font-size: 20px;
            font-weight: 600;
            text-align: center;
            // font-family: MiSans;
            align-items: center;
            margin-bottom: 15px;
            justify-content: center; 
            color: rgba(54, 63, 94, 0.80);
          }
          .span {
            color: #22A560;
            // font-family: PingFang SC;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            gap: 12px;
            display: flex;
            margin-bottom: 8px;
            padding: 8px 30px;
            align-items: center;
            border-radius: 58px;
            background: #E0E6F3;
            justify-content: center;
          }
          .img {
            gap: 12px;
            display: flex;
            width: 160px;
            padding: 4px 24px;
            background: #FFF;
            border-radius: 58px;
            align-items: center;
            margin-bottom: 8px;
            justify-content: center;
            img {
              max-width: 148px;
              max-height: 40px;
            }
          }
        }
      }
    }
    .cont17 {
      top: 2980px;
      .title {
        font-size: 32px;
        font-weight: 600;
        color: #363F5E;
        text-align: center;
        line-height: normal;
        margin-bottom: 40px;
      }
      .mode {
        width: 750px;
        margin: auto;
        height: 300px;
        position: relative;
        .modetype {
          position: absolute;
          width: 400px;
          height: 300px;
          flex-shrink: 0;
          line-height: normal;  
          text-align: center;
          // font-family: MiSans;
          padding: 70px 70px 0;
          box-sizing: border-box;
          backdrop-filter: blur(3px);
          border-radius: 200px / 150px;
          .title {
            font-size: 28px;
            font-weight: 600;
            color: #363F5E;
            margin-bottom: 12px;
          }
          .desc {
            font-size: 20px;
            font-weight: 500;
            letter-spacing: 0.4px;
            color: rgba(54, 63, 94, 0.60);
          }
        }
        .mode1 {
          left: 0;
          background: linear-gradient(129deg, rgba(92, 200, 207, 0.50) 14.99%, rgba(131, 232, 189, 0.20) 86.16%);
        }
        .mode2 {
          right: 0;
          background: linear-gradient(235deg, rgba(92, 200, 207, 0.50) 13.78%, rgba(131, 232, 189, 0.20) 89.16%);
        }
      }
    }
  }
  .dialogVideo {
    width: 100%;
    
    video {
      width: 100%;
    }
  }
}
.lan_En.Lanchpad {
  .content  {
    &.content1 {
      width: 100%;
      height: 11035px;
      overflow: hidden;
      background: url("../assets/lanchpadbg.png") no-repeat center;
      background-size: 100% 11035px;
    }
    .cont3 {
      .text_desc {
        .title {
          font-size: 26px;
          line-height: 1.1;
        }
      }
    }
    .cont4 {
      .inlet {
        .center {
          font-size: 18px;
          .block1 {
            width: 120px;
          }
          .block3 {
            width: 120px;
          }
        }
        .round {
          .top {
            font-size: 28px;
          }
          .text {
            font-size: 16px;
            &.text1 {
              font-size: 12px;
            }
          }
        }
      }
    }
    .cont5 {
      .intro {
        .left {
          width: 280px;
          left: 101px;
          .text1 {
            font-size: 24px;
          }
        }
        .right {
          width: 280px;
          right: 99px;
        }
      }
    }
    .cont6 {
      // top: 4180px;
      .intro {
        .intro_li {
          text-align: center;
          .lineH1 {
            .text {
              line-height: 1;
            }
          }
        }
      }
    }
    .cont7 {
      top: 4745px;
      .intro {
        .left {
          .top_text {
            font-size: 20px;
          }
          .text {
            font-size: 14px;
          }
        }
        .right {
          .top_text {
            font-size: 20px;
          }
          .text {
            font-size: 14px;
            margin-bottom: 10px;
          }
        }
      }
    }
    .cont8 {
      // top: 5340px;
      .title {
        position: relative;
        z-index: 5;
      }
      .intro {
        .ele5 {
          top: 440px;
        }
        .ele6 {
          bottom: 80px;
        }
      }
    }
    .cont9 {
      // top: 6080px;
      .intro {
        .right {
          .topcont {
            .block {
              padding: 15px 10px;
              .text {
                font-size: 16px;
              }
              .text1 {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
    .cont11 {
      top: 7520px;
      .intro {
        .userimg {
          height: 335px;
        }
        .scan.active {
          height: 380px;
          background-size: 234px 380px;
        }
        .datalist {
          .datali {
            height: 75px;
            .data {
              .top {
                font-size: 16px;
              }
              .text {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
    .cont12 {
      top: 8020px;
      .intro {
        .details{
          height: 300px;
          &.font12 {
            height: 500px;
          }
        }
      }
    }
    .cont13 {
      top: 8675px;
    }
    .cont17 {
      .mode {
        .modetype {
          .title {
            font-size: 24px;
          }
          .desc {
            font-size: 16px;
          }
        }
      }
    }
  }
  
}

@media screen and (max-width: 1200px){
  .Lanchpad {
    min-width: 600px;
    .content {
      min-width: unset;
      &.content1 {
        width: 100%;
        min-width: 600px;
        height: 14050px;
        overflow: hidden;
        background: url("../assets/lanchpadbg.png") no-repeat center;
        background-size: 100% 14050px;
      }
      .bg1 {
        position: absolute;
        top: -250px;
        left: -150px;
        width: 750px;
        height: 750px;
        background: url(../assets/lanch_bg1.png) no-repeat 100%/100%;
      }
      .bg2 {
        position: absolute;
        z-index: 11;
        top: -100px;
        right: -100px;
        width: 400px;
        height: 400px;
        background: url(../assets/lanch_bg2.png) no-repeat 100%/100%;
      }
      .bg3 {
        position: absolute;
        z-index: 1;
        top: 872px;
        right: -300px;
        width: 720px;
        height: 720px;
        animation: spin 10s linear infinite;
        background: url(../assets/lanch_bg3.png) no-repeat 100%/100%;
      }
      .bg4 {
        z-index: 11;
        position: absolute;
        top: 9210px;
        right: 42px;
        width: 200px;
        height: 200px;
        background: url(../assets/lanch_bg4.png) no-repeat 100%/100%;
      }
      .bg5 {
        z-index: 11;
        position: absolute;
        top: 2700px;
        right: -125px;
        width: 270px;
        height: 270px;
        animation: shake2 10s linear infinite;
        background: url(../assets/lanch_iocn9.png) no-repeat 100%/100%;
      }
      .bg6 {
        z-index: 11;
        position: absolute;
        top: 3500px;
        left: -120px;
        width: 300px;
        height: 300px;
        animation: shake2 6s linear infinite;
        background: url(../assets/lanch_iocn7.png) no-repeat 100%/100%;
      }
      
      .bg7 {
        z-index: 1;
        position: absolute;
        top: 6800px;
        left: -100px;
        width: 280px;
        height: 280px;
        animation: shake2 8s linear infinite;
        background: url(../assets/lanch_iocn4.png) no-repeat 100%/100%;
      }
      
      .bg8 {
        z-index: 11;
        position: absolute;
        top: 4460px;
        right: -100px;
        width: 240px;
        height: 240px;
        animation: shake2 12s linear infinite;
        background: url(../assets/lanch_iocn5.png) no-repeat 100%/100%;
      }
      
      .bg9 {
        z-index: 11;
        position: absolute;
        top: 5270px;
        left: -120px;
        width: 300px;
        height: 300px;
        animation: shake2 20s linear infinite;
        background: url(../assets/lanch_iocn6.png) no-repeat 100%/100%;
      }
      .bg10 {
        z-index: 11;
        position: absolute;
        top: 11800px; 
        right: 0;
        width: 170px;
        height: 210px;
        background: url(../assets/lanch_iocn10.png) no-repeat 100%/100%;
      }
      .bg11 {
        z-index: 11;
        position: absolute;
        top: 10360px;
        left: -71px;
        width: 200px;
        height: 200px;
        animation: shake2 15s linear infinite;
        background: url(../assets/lanch_iocn8.png) no-repeat 100%/100%;
      }


      .line {
        display: none;
        position: absolute;
        z-index: 5;
        width: 228px;
        top: 1812px;
        left: 0;
        right: 0;
        margin: auto;
        height: 0;
        overflow: hidden;
        transition: all 1s ease-in-out;
      }
      .line2 {
        display: none;
        position: absolute;
        z-index: 5;
        width: 4px;
        height: 190px;
        top: 4960px;
        left: 0;
        right: 0;
        margin: auto;
        background: url(../assets/lanch_line.png) no-repeat 100%/100%;
      }
      .line3 {
        display: none;
        position: absolute;
        z-index: 5;
        width: 4px;
        height: 244px;
        top: 5490px;
        left: 0;
        right: 0;
        margin: auto;
        background: url(../assets/lanch_line.png) no-repeat 100%/100%;
      }
      .line_point {
        display: none;
        position: absolute;
        z-index: 11;
        top: 1800px;
        left: 0;
        right: 0;
        margin: auto;
        width: 14px;
        height: 14px;
        background: url(../assets/lanch_icon2.png) no-repeat 100%/100%;
        &.active {
          width: 28px;
          height: 28px;
          transition: all .5s ease-in-out;
          background: url(../assets/lanch_icon1.png) no-repeat 100%/100%;
        }
        &.small {
          width: 20px;
          height: 20px;
          transition: all .5s ease-in-out;
          background: url(../assets/lanch_icon1.png) no-repeat 100%/100%;
        }
      }
      .meteor {
        position: absolute;
        width: 130px;
        height: 130px;
        animation: meteorAnim 6s linear infinite;
        background: url(../assets/lanch_start1.png) no-repeat 100%/100%;
      }
      .meteor1 {
        position: absolute;
        width: 134px;
        height: 133px;
        animation: meteorAnim1 8s linear infinite;
        background: url(../assets/lanch_start2.png) no-repeat 100%/100%;
      }
      .meteor2 {
        position: absolute;
        width: 116px;
        height: 116px;
        animation: meteorAnim2 4s linear infinite;
        background: url(../assets/lanch_start3.png) no-repeat 100%/100%;
      }
      @keyframes meteorAnim {
        0% {
          top: -130px;
          left: 900px;
        }
        100% {
          top: 900px;
          left: -130px;
        }
      }
      @keyframes meteorAnim1 {
        0% {
          top: -134px;
          left: 600px;
        }
        100% {
          top: 600px;
          left: -134px;
        }
      }
      @keyframes meteorAnim2 {
        0% {
          top: -116px;
          left: 750px;
        }
        100% {
          top: 750px;
          left: -116px;
        }
      }
      @keyframes spin {
        from {
          transform: rotate(360deg);
        }
        to {
          transform: rotate(0);
        }
      }
      @keyframes shake2 {
        0%, 100% {
          // transform: translateX(0);
          transform:rotate(0)
        }
        10%, 30%, 50%, 70%, 90% {
          // transform: translateX(-20px);
          transform:rotate(9deg)
        }
        20%, 40%, 60%, 80% {
          // transform: translateX(20px);
          transform:rotate(-9deg)
        }
      }

      
      @keyframes run{
        0%{
          left:50%;
          top:0
        }
        12.5%{
          left:0;
          top:0;
          transform:translate(-50%, -50%) rotate(0deg);
        }
        12.51%{
          transform:translate(-50%, -50%) rotate(270deg);
        }
        25%{
          top:50%;
          left: 0;
        }
        37.5%{
          top:100%;
          left: 0;
          transform:translate(-50%, -50%) rotate(270deg);
        }
        37.51%{
          transform:translate(-50%, -50%) rotate(180deg);
        }

        50%{
          top:100%;
          left: 50%;
        }
        62.5%{
          top: 100%;
          left: 100%;
          transform:translate(-50%, -50%) rotate(180deg);
        }
        62.51%{
          transform:translate(-50%, -50%) rotate(90deg);
        }
        75%{
          top: 50%;
          left:100%;
        }
        87.5%{
          top:0;
          left: 100%;
          transform:translate(-50%, -50%) rotate(90deg);
        }
        87.51%{
          transform:translate(-50%, -50%) rotate(0deg);
        }
        100%{
          left:50%;
          top:0
        }
      }
      @keyframes run2{
        0%{
          left:50%;
          top:0
        }
        12.5%{
          left:100%;
          top:0;
          transform:translate(-50%, -50%) rotate(0deg);
        }
        12.51%{
          transform:translate(-50%, -50%) rotate(90deg);
        }
        25%{
          top:50%;
          left: 100%;
        }
        37.5%{
          top:100%;
          left: 100%;
          transform:translate(-50%, -50%) rotate(90deg);
        }
        37.51%{
          transform:translate(-50%, -50%) rotate(180deg);
        }
        50%{
          top:100%;
          left: 50%;
        }
        62.5%{
          top: 100%;
          left: 0%;
          transform:translate(-50%, -50%) rotate(180deg);
        }
        62.51%{
          transform:translate(-50%, -50%) rotate(270deg);
        }
        75%{
          top: 50%;
          left:0%;
        }
        87.5%{
          top:0;
          left: 0%;
          transform:translate(-50%, -50%) rotate(270deg);
        }
        87.51%{
          transform:translate(-50%, -50%) rotate(0deg);
        }
        100%{
          left:50%;
          top:0
        }
      }
      .cont {
        width: 100%;
        z-index: 10;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        min-width: 600px;
        box-sizing: border-box;
      }
      .cont1 {
        top: 0;
        .title {
          font-size: 30px;
          text-align: center;
        }
        .desc {
          font-size: 24px;
          text-align: center;
        }
        .btnList {
          display: flex;
          align-items: center;
          justify-content: center;
          .btn {
            font-size: 16px;
            &:last-child {
              margin-right: 0;
            }
          }
        }
        .tips {
          text-align: center;
        }
        .gif {
          display: none;
        }
      }
      .cont2 {
        top: 520px;
        .videoPaly {
          width: 600px;
          height: 360px;
          margin: auto;
          video {
            width: 600px;
            height: 300px;
          }
        }
        .imgbg {
          position: relative;
          width: 100%;
          height: 700px;
          background: url(../assets/lanch_cont2bg_phone.png) no-repeat;
          background-size: 315px 296px;
          background-position: 100% 188px;
          video {
            width: 490px;
            height: 300px;
          }
          .video1 {
            top: -25px;
            left: 50px;
          }
          .video2 {
            top: 415px;
            left: 50px;
            right: unset;
          }
          .text {
            left: 250px;
          }
        }
        .videoBtn {
          position: unset;
          top: 770px;
          margin: 75px auto 0;
        }
      }
      .cont3 {
        top: 1760px;
        width: 100%;
        .warp-data {
          width: 100%;
          overflow: hidden;
          .data_ul {
            width: max-content;
            display: flex;
            .data_li {
              margin-right: 50px;
              display: inline-flex;
              align-items: center;
              border-radius: 89.5px;
              padding: 8px 30px 8px 8px;
              backdrop-filter: blur(6px);
              background: linear-gradient(90deg, rgba(59, 221, 150, 0.40) 0%, rgba(59, 221, 150, 0.06) 100%);
              &.blank {
                width: 400px;
                background: transparent;
                backdrop-filter: none;
              }
              .left {
                width: 98px;
                height: 40px;
                font-size: 24px;
                font-weight: 600;
                color: #363F5E;
                line-height: 40px;
                font-style: normal;
                text-align: center;
                margin-right: 15px;
                border-radius: 89.5px;
                backdrop-filter: blur(6px);
                background: rgba(55, 197, 135, 0.60);
              }
              span {
                display: inline-block;
                font-size: 18px;
                font-weight: 600;
                color: rgba(54, 63, 94, 0.68);
              }
            }
          }
        }
        .text_desc {
          width: 100%;
          height: 140px;
          display: flex;
          margin-top: 30px;
          text-align: center;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          backdrop-filter: blur(4px);
          background: linear-gradient(90deg, rgba(158, 227, 154, 0.41) 3.39%, rgba(42, 178, 106, 0.68) 49.35%, rgba(158, 227, 154, 0.41) 97.54%);
          .title {
            font-size: 30px;
            font-weight: 600;
            color: #363F5E;
            line-height: 40px;
            margin-bottom: 12px;
          }
          .text {
            line-height: 1;
            font-size: 16px;
            color: rgba(54, 63, 94, 0.60);
          }
        }
      }
      .cont16 {
        top: 2040px;
        .title {
          font-size: 28px;
        }
        .product {
          width: 600px;
          flex-wrap: wrap;
          .pro_cont {
            width: 290px;
            margin-bottom: 20px;
          }
        }
      }
      .cont4 {
        top: 2870px;
        .title {
          padding: 0 30px;
          font-size: 30px;
          font-weight: 600;
          color: #363F5E;
          text-align: center;
          line-height: normal;
          margin-bottom: 12px;
          box-sizing: border-box;
        }
        .desc {
          font-size: 16px;
          font-weight: 500;
          text-align: center;
          line-height: normal;
          margin-bottom: 40px;
          color: rgba(54, 63, 94, 0.60);
        }
        .inlet {
          width: 100%;
          display: flex;
          margin-bottom: 50px;
          align-items: center;
          justify-content: space-between;
          &.show {
            display: none; 
          }
          &.hide {
            max-width: 600px;
            display: flex;
            flex-wrap: wrap; 
            margin: auto;
            justify-content:center;
          }
          .inletFlex {
            display: flex;
            flex-wrap: wrap;
            padding: 0 60px;
            justify-content: space-around;
            .round {
              display: flex;
              width: 240px;
              height: 240px;
              margin-top: 20px;
              text-align: center;
              align-items: center;
              flex-direction: column;
              justify-content: center;
              background: url(../assets/lanch_round.png) no-repeat 100%/100%;
              .top {
                width: 100%;
                font-size: 36px;
                color: #358C66;
                font-weight: 600;
                margin-bottom: 5px;
                line-height: normal;
              }
              .text {
                width: 80%;
                font-size: 20px;
                line-height: normal;
                color: rgba(54, 63, 94, 0.60);
              }
            }
          }
          .center {
            position: relative;
            width: 405px;
            height: 360px;
            color: #FFF;
            font-size: 24px;
            line-height: 30px;
            text-align: center;
            background: url(../assets/lanch_cont4.png) no-repeat 100%/100%;
            .block1 {
              width: 100px;
              top: 40px;
              left: 0;
              right: 0;
              margin: auto;
              position: absolute;
            }
            .block2 {
              position: absolute;
              width: 100px;
              top: 250px;
              left: 32px;
            }
            .block3 {
              position: absolute;
              width: 100px;
              top: 250px;
              right: 32px;
            }
          }
        }
        .viewpoint {
          width: 600px;
          display: flex;
          flex-wrap: wrap;
          margin: 30px auto 0;
          align-items: center;
          justify-content: space-around;
          .li {
            width: 280px;
            cursor: pointer;
            color: #2AB26A;
            font-size: 16px;
            font-weight: 400;
            text-align: center;
            padding: 12px 30px;
            line-height: normal;
            border-radius: 58px;
            margin-bottom: 20px;
            display: inline-block;
            box-sizing: border-box;
            // font-family: PingFang SC;
            background: rgba(255, 255, 255, 0.50);
          }
        }
      }
      .cont17 {
        top: 4130px;
        .mode {
          width: 600px;
          margin: auto;
          height: 840px;
          position: relative;
          .modetype {
            position: absolute;
            width: 500px;
            height: 340px;
            flex-shrink: 0;
            line-height: normal;
            text-align: center;
            // font-family: MiSans;
            padding: 70px 70px 0;
            box-sizing: border-box;
            -webkit-backdrop-filter: blur(3px);
            backdrop-filter: blur(3px);
            border-radius: 250px/170px;
          }
          .mode1 {
            left: 50px;
            top: 0;
            background: linear-gradient(159deg, rgba(92, 200, 207, 0.50) 13.2%, rgba(131, 232, 189, 0.20) 84.2%);
          }
          .mode2 {
            right: 50px;
            top: 290px;
            background: linear-gradient(313deg, rgba(92, 200, 207, 0.50) 16.48%, rgba(131, 232, 189, 0.20) 79.05%);
          }
        }
      }
      .cont5 {
        top: 4940px;
        width: 600px;
        margin: auto;
        overflow: hidden;
        height: 1010px;
        background: url(../assets/lanch_cont5bg_phone.png) no-repeat;
        background-size: 1008px 1150px;
        background-position: center -110px;
        .title {
          font-size: 32px;
          font-weight: 600;
          color: #363F5E;
          text-align: center;
          line-height: normal;
          margin-bottom: 12px;
        }
        .intro {
          position: relative;
          margin: auto;
          width: 100%;
          overflow: unset;
          background: unset;
          .topcenter {
            width: 550px;
            display: flex;
            font-size: 16px;
            font-weight: 400;
            margin: 45px auto 0;
            line-height: 22px; 
            // font-family: PingFang SC;
            color: rgba(54, 63, 94, 0.80);
            justify-content: space-between;
          }
          .left, .right {
            position: absolute;
            top: 570px;
            width: 240px;
            .text1 {
              color: #363F5E;
              font-size: 28px;
              font-weight: 600;
              text-align: center;
              line-height: normal;
              margin-bottom: 12px;
            }
            .text2 {
              color: rgba(54, 63, 94, 0.60);
              text-align: center;
              font-size: 20px;
              line-height: normal;
            }
          }
          .left {
            left: 15px !important;
          }
          .right {
            position: absolute;
            left: unset;
            top: 570px;
            right: 0 !important;
          }
          .details {
            position: absolute;
            top: 450px;
            left: 0;
            right: 0;
            margin: auto;
            width: 300px;
            font-size: 16px;
            font-weight: 400;
            line-height: 26px;
            text-align: center;
            // font-family: PingFang SC;
            color: rgba(54, 63, 94, 0.50);
          }
        }
      }
      .cont6 {
        top: 6040px;
        z-index: 4;
        .title {
          font-size: 32px;
          font-weight: 600;
          color: #363F5E;
          text-align: center;
          line-height: normal;
          margin-bottom: 12px;
        }
        .intro {
          width: 600px;
          margin: auto;
          height: 710px;
          display: flex;
          flex-wrap: wrap;
          padding: 20px 0;
          margin-top: 50px;
          align-items: center;
          border-radius: 24px;
          box-sizing: border-box;
          border: 1px solid #fff;
          backdrop-filter: blur(6px);
          justify-content: space-around;
          background: rgba(255, 255, 255, 0.20);
          .point {
            width: 28px;
            height: 28px;
            margin: 0 10px;
            background: url(../assets/lanch_icon1.png) no-repeat 100%/100%;
            &.point1 {
              width: 28px;
              height: 28px;
              margin: 0 17px;
            }
            &.point2 {
              width: 20px;
              height: 20px;
              margin: 0 14px;
            }
            &.point3 {
              width: 14px;
              height: 14px;
              margin: 0 17px;
            }
          }
          &.show {
            display: none; 
          }
          &.hide {
            display: flex;
          }
          .intro_desc {
            display: flex;
            align-items: center;
            &:nth-child(4) {
              margin-top: 30px;
            }
            .intro_li {
              width: 420px;
              height: 100px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              &.left {
                background: url(../assets/lanch_cont6li2.png) no-repeat 100%/100%;
              }
              &.right {
                background: url(../assets/lanch_cont6li1.png) no-repeat 100%/100%;
              }
              .left_1 {
                width: 310px;
                display: flex;
                padding: 0 10px;
                align-items: center;
                box-sizing: border-box;
                .text {
                  width: 100%;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 1;
                  text-align: right;
                  color: rgba(54, 63, 94, 0.68);
                }
              }
              .left_2 {
                width: 100px;
                display: flex;
                margin-right: 10px;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                .icon {
                  width: 30px;
                  height: 30px;
                }
                .text {
                  font-size: 20px;
                  line-height: normal;
                  color: rgba(255, 255, 255, 0.80);
                }
              }
              .right_1 {
                width: 260px;
                display: flex;
                padding: 0 10px;
                align-items: center;
                box-sizing: border-box;
                .text {
                  width: 100%;
                  line-height: 1;
                  font-size: 14px;
                  font-weight: 400;
                  text-align: left;
                  color: rgba(54, 63, 94, 0.68);
                }
              }
              .right_2 {
                width: 155px;
                display: flex;
                margin-left: 5px;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                .icon {
                  width: 30px;
                  height: 30px;
                }
                .text {
                  font-size: 20px;
                  line-height: normal;
                  color: rgba(255, 255, 255, 0.80);
                }
              }
              .icon1 {
                background: url(../assets/lanch_cont6icon1.png) no-repeat 100%/100%;
              }
              .icon2 {
                background: url(../assets/lanch_cont6icon2.png) no-repeat 100%/100%;
              }
              .icon3 {
                background: url(../assets/lanch_cont6icon3.png) no-repeat 100%/100%;
              }
              .icon4 {
                background: url(../assets/lanch_cont6icon4.png) no-repeat 100%/100%;
              }
              .icon5 {
                background: url(../assets/lanch_cont6icon5.png) no-repeat 100%/100%;
              }
              .icon6 {
                background: url(../assets/lanch_cont6icon6.png) no-repeat 100%/100%;
              }
            }
          }
          
        }
      }
      .cont7 {
        top: 6970px;
        .title {
          width: 550px;
          margin: auto;
          font-size: 26px;
          font-weight: 600;
          color: #363F5E;
          text-align: center;
          line-height: normal;
          margin-bottom: 12px;
        }
        .desc {
          width: 600px;
          margin: auto;
          font-size: 16px;
          font-weight: 500;
          text-align: center;
          line-height: normal;
          margin-bottom: 40px;
          color: rgba(54, 63, 94, 0.60);
        }
        .intro {
          position: relative;
          margin: auto;
          width: 100%;
          height: 550px;
          display: flex;
          background: unset;
          align-items: center;
          flex-direction: column;
          justify-content: space-between;
          .left, .right {
            width: 580px;
            height: 250px;
            padding: 12px 50px 0;
            box-sizing: border-box;
          }
          .left, .right {
            .top_text {
              font-size: 22px;
              font-weight: 600;
              line-height: normal;
              margin-bottom: 15px;
              color: rgba(54, 63, 94, 0.86);
            }
            .text {
              font-size: 14px;
              line-height: normal;
              margin-bottom: 10px;
              color: rgba(54, 63, 94, 0.60);
            }
          }
          .left {
            text-align: right;
            padding: 30px 50px 0 5px;
            background: url(../assets/lanch_cont7bg_phone1.png) no-repeat 100%/100%;
            .top_text {
              margin-right: 50px;
            }
            .text:nth-child(2) {
              margin-right: 35px;
            }
            .text:nth-child(3) {
              margin-right: 17px;
            }
            .text:nth-child(4) {
              margin-right: 0px;
            }
          }
          .right {
            width: 570px;
            padding-right: 5px;
            background: url(../assets/lanch_cont7bg_phone2.png) no-repeat 100%/100%;
            .text:nth-child(2) {
              margin-left: 30px;
            }
            .text:nth-child(3) {
              margin-left: 50px;
            }
            .text:nth-child(4) {
              margin-left: 70px;
            }
          }
          .icon {
            position: absolute;
          }
          .icon1 {
            top: 380px;
            left: 65px;
            right: unset;
            width: 160px;
            height: 176px;
            animation: spin 10s linear infinite;
            background: url(../assets/lanch_cont7icon1.png) no-repeat 100%/100%;
          }
          .icon2 {
            top: 10px;
            left: unset;
            right: 50px;
            width: 180px;
            height: 180px;
            animation: spin 10s linear infinite;
            background: url(../assets/lanch_cont7icon2.png) no-repeat 100%/100%;
          }
        }
      }
      .cont8 {
        top: 7770px;
        .title {
          font-size: 32px;
          font-weight: 600;
          color: #363F5E;
          text-align: center;
          line-height: normal;
          margin-bottom: 43px;
        }
        .icon {
          position: absolute;
          top: 0;
          right: 50px;
          width: 180px;
          height: 180px;
          z-index: 2;
          transform: translate(-50%, -50%);
          animation: shake 10s ease-in-out infinite;
          background: url(../assets/lanch_cont8icon.png) no-repeat 100%/100%;
        }
        @keyframes shake {
          0%, 100% {
            transform: translateX(0);
          }
          10%, 30%, 50%, 70%, 90% {
            transform: translateX(-20px);
          }
          20%, 40%, 60%, 80% {
            transform: translateX(20px);
          }
        }
        .intro {
          position: relative;
          margin: auto;
          width: 600px;
          height: 625px;
          overflow: hidden;
          border-radius: 22px;
          // background: url(../assets/lanch_cont8bg_phone.png) no-repeat 100%/100%;
          background: url(../assets/lanch_cont8bg_phone_new.png) no-repeat 100%/100%;
          .line_text {
            font-size: 12px;
            line-height: normal;
            color: rgba(54, 63, 94, 0.60);
          }
          .ele1 {
            position: absolute;
            top: 90px;
            left: 95px;
          }
          .ele2 {
            position: absolute;
            top: 121px;
            left: 340px;
          }
          .ele3 {
            position: absolute;
            top: 451px;
            right: 165px;
          }
          .ele4 {
            position: absolute;
            top: 300px;
            left: 351px;
          }
          .ele5 {
            position: absolute;
            top: 452px;
            right: 365px;
          }
          .ele6 {
            position: absolute;
            bottom: 250px;
            left: 275px;
          }
          .ele7 {
            position: absolute;
            top: 25px;
            left: 300px;
            width: 225px;
            height: 95px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            .icon1 {
              width: 24px;
              height: 24px;
              margin-bottom: 8px;
              background: url(../assets/lanch_cont8icon1.png) no-repeat 100%/100%;
            }
            .text {
              font-size: 16px;
              line-height: normal;
              color: rgba(54, 63, 94, 0.80);
            }
          }
          .ele8 {
            position: absolute;
            width: 388px;
            height: 140px;
            top: 150px;
            left: 135px;
            padding: 15px 20px;
            box-sizing: border-box;
            .text {
              font-size: 20px;
              font-weight: 600;
              color: #363F5E;
              text-align: center;
              line-height: normal;
              margin-bottom: 2px;
            }
            .text1 {
              font-size: 13px;
              text-align: center;
              line-height: normal;
              margin-bottom: 10px;
              color: rgba(54, 63, 94, 0.66);
            }
            .list {
              display: flex;
              flex-wrap: wrap;
              font-size: 12px;
              font-weight: 400;
              line-height: normal;
              align-items: center;
              justify-content: space-between;
              color: rgba(54, 63, 94, 0.50);
              p {
                width: 50%;
                margin-bottom: 4px;
              }
            }
          }
          .ele9 {
            position: absolute;
            width: 320px;
            height: 115px;
            top: 485px;
            right: 30px;
            display: flex;
            padding: 15px 20px;
            align-items: center;
            flex-direction: column;
            box-sizing: border-box;
            justify-content: center;
            .text {
              font-size: 20px;
              font-weight: 600;
              color: #363F5E;
              line-height: normal;
              margin-bottom: 2px;
            }
            .text1 {
              font-size: 15px;
              line-height: normal;
              margin-bottom: 10px;
              color: rgba(54, 63, 94, 0.66);
            }
            .buy {
              font-size: 14px;
              font-weight: 400;
              line-height: normal;
              color: rgba(54, 63, 94, 0.60);
            }
          }
          .ele10 {
            position: absolute;
            width: 180px;
            height: 95px;
            top: 320px;
            left: 22px;
            display: flex;
            padding: 15px;
            align-items: center;
            flex-direction: column;
            box-sizing: border-box;
            justify-content: center;
            .text {
              font-size: 20px;
              font-weight: 600;
              color: #363F5E;
              line-height: normal;
              margin-bottom: 2px;
            }
            .text1 {
              font-size: 12px;
              line-height: normal;
              color: rgba(54, 63, 94, 0.66);
            }
          }
          .ele11 {
            position: absolute;
            top: 330px;
            left: 330px;
            width: 88px;
            height: 88px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            .icon1 {
              width: 24px;
              height: 24px;
              margin-bottom: 8px;
              background: url(../assets/lanch_cont8icon1.png) no-repeat 100%/100%;
            }
            .text {
              font-size: 16px;
              line-height: normal;
              color: rgba(54, 63, 94, 0.80);
            }
          }
          .ele12 {
            position: absolute;
            width: 230px;
            height: 95px;
            top: 485px;
            right: 350px;
            display: flex;
            padding: 15px 20px;
            align-items: center;
            flex-direction: column;
            box-sizing: border-box;
            justify-content: center;
            .text {
              font-size: 14px;
              font-weight: 600;
              color: #363F5E;
              line-height: normal;
              margin-bottom: 2px;
            }
            .text1 {
              font-size: 12px;
              line-height: normal;
              color: rgba(54, 63, 94, 0.66);
            }
          }

          .ele13 {
            position: absolute;
            top: 478px;
            left: 10px;
            width: 182px;
            height: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            .icon1 {
              width: 24px;
              height: 24px;
              margin-bottom: 8px;
              background: url(../assets/lanch_cont8icon1.png) no-repeat 100%/100%;
            }
            .text {
              font-size: 16px;
              line-height: normal;
              color: rgba(54, 63, 94, 0.80);
            }
          }
          .ele14 {
            position: absolute;
            top: 520px;
            left: 185px;
          }
          .ele15 {
            position: absolute;
            width: 328px;
            height: 114px;
            top: 475px;
            left: 245px;
            padding: 10px 15px;
            box-sizing: border-box;
            .text {
              font-size: 20px;
              font-weight: 600;
              color: #363F5E;
              text-align: center;
              line-height: normal;
              margin-bottom: 2px;
            }
            .text1 {
              font-size: 12px;
              text-align: center;
              line-height: normal;
              margin-bottom: 5px;
              color: rgba(54, 63, 94, 0.66);
            }
            .list {
              display: flex;
              flex-wrap: wrap;
              font-size: 12px;
              font-weight: 400;
              padding: 0 5px;
              line-height: normal;
              align-items: center;
              box-sizing: border-box;
              justify-content: space-between;
              color: rgba(54, 63, 94, 0.50);
              p {
                width: 100%;
                margin-bottom: 4px;
              }
            }
          }
        }
        .intro::after, .intro::before{
          content: '';
          width: 160px;
          height: 4px;
          position: absolute;
          top: 2px;
          left: 150px
        }
        .intro::before{
          left: 50px;
          animation: run 4s linear infinite;
          background: url(../assets/lanch_border2.png) no-repeat 100%/100%;
          transform: translate(-50%, -50%);
        }
        .intro::after{
          animation: run2 4s linear infinite;
          background: url(../assets/lanch_border1.png) no-repeat 100%/100%;
          transform: translate(-50%, -50%);
        }
      }
      .cont9 {
        top: 8550px;
        .title {
          font-size: 32px;
          font-weight: 600;
          color: #363F5E;
          text-align: center;
          line-height: normal;
          margin-bottom: 30px;
        }
        .intro {
          position: relative;
          width: 600px;
          height: 480px;
          display: flex;
          margin: auto;
          overflow: hidden;
          align-items: center;
          border-radius: 24px;
          box-sizing: border-box;
          border: 1px solid #fff;
          backdrop-filter: blur(6px);
          justify-content: space-around;
          background: rgba(255, 255, 255, 0.20);
          &::after, &::before{
            content: '';
            width: 160px;
            height: 4px;
            position: absolute;
            top: 2px;
            left: 150px
          }
          &::before{
            left: 50px;
            animation: run 4s linear infinite;
            background: url(../assets/lanch_border2.png) no-repeat 100%/100%;
            transform: translate(-50%, -50%);
          }
          &::after{
            animation: run2 4s linear infinite;
            background: url(../assets/lanch_border1.png) no-repeat 100%/100%;
            transform: translate(-50%, -50%);
          }
          .left {
            position: absolute;
            left: -20px;
            bottom: -20px;
            width: 120px;
            height: 120px;
            transform: translate(-50%, -50%);
            animation: shake1 20s ease-in-out infinite;
            background: url(../assets/lanch_cont9img.png) no-repeat 100%/100%;
          }
          @keyframes shake1 {
            0%, 100% {
              transform: translateX(0);
              transform: translatey(0);
            }
            10%, 30%, 50%, 70%, 90% {
              transform: translateX(-20px);
              transform: translatey(-20px);
            }
            20%, 40%, 60%, 80% {
              transform: translatey(20px);
              transform: translateX(20px);
            }
          }
          .right {
            width: 100%;
            .topcont {
              margin: auto;
              width: 580px;
              height: 94px;
              display: flex;
              align-items: center;
              justify-content: space-around;
              .user {
                width: 150px;
                height: 94px;
                display: flex;
                border-radius: 20px;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                backdrop-filter: blur(6px);
                background: rgba(255, 255, 255, 0.40);
                .icon1 {
                  width: 24px;
                  height: 24px;
                  margin-bottom: 8px;
                  background: url(../assets/lanch_cont8icon1.png) no-repeat 100%/100%;
                }
                .text {
                  font-size: 16px;
                  line-height: normal;
                  color: rgba(54, 63, 94, 0.80);
                }
              }
              .text_line {
                display: flex;
                font-size: 12px;
                line-height: normal;
                align-items: center;
                color: rgba(54, 63, 94, 0.50);
                .arrow {
                  width: 76px;
                  height: 27px;
                  margin-left: 5px;
                  display: inline-block;
                  background: url(../assets/lanch_cont9arrow.png) no-repeat 100%/100%;
                }
              }
              .block {
                width: 150px;
                height: 94px;
                display: flex;
                padding: 15px 20px;
                align-items: center;
                border-radius: 24px;
                flex-direction: column;
                box-sizing: border-box;
                justify-content: center;
                background: rgba(255, 255, 255, 0.40);
                .text {
                  font-size: 20px;
                  font-weight: 600;
                  color: #363F5E;
                  line-height: normal;
                  margin-bottom: 2px;
                }
                .text1 {
                  font-size: 15px;
                  line-height: normal;
                  color: rgba(54, 63, 94, 0.66);
                }
              }
            }
            .list {
              width: 90%;
              height: 290px;
              display: flex;
              padding: 10px 0;
              flex-wrap: wrap;
              margin: 30px auto 0;
              border-radius: 20px;
              align-items: center;
              box-sizing: border-box;
              justify-content: space-around;
              background: rgba(255, 255, 255, 0.30);
              .li{
                width: 80%;
                height: 70px;
                text-align: center;
                line-height: normal;
                .num {
                  font-size: 18px;
                  font-weight: 600;
                  color: rgba(54, 63, 94, 0.16);
                }
                .text{
                  font-size: 14px;
                  font-weight: 400;
                  color: rgba(54, 63, 94, 0.56);
                }
              }
            }
          }
        }
      }
      .cont10 {
        z-index: 12;
        top: 9190px;
        .title {
          font-size: 32px;
          font-weight: 600;
          color: #363F5E;
          text-align: center;
          line-height: normal;
          margin-bottom: 30px;
        }
        .intro {
          width: 600px;
          display: flex;
          padding: 0 10px;
          margin: 50px auto 0;
          box-sizing: border-box;
          justify-content: space-between;
          &.show {
            display: none;
          }
          &.hide {
            display: flex;
          }
          .line1 {
            width: 0px;
            height: 2260px;
            margin-top: 7px;
            border: 1px dashed #2AB26A;
          }
          .list {
            line-height: normal;
            .li {
              position: relative;
              margin: 0 0 35px 20px;
              &::after {
                position: absolute;
                top: 0;
                left: -31px;
                content: '';
                width: 20px;
                height: 20px;
                background: url(../assets/lanch_icon1.png) no-repeat 100%/100%;
              }
              .li_title {
                font-size: 18px;
                font-weight: 600;
                color: #363F5E;
              }
              .li_text {
                font-size: 14px;
                line-height: normal;
                color: rgba(54, 63, 94, 0.66);
              }
            }
          }
        }
      }
      .cont11 {
        z-index: 12;
        top: 10730px;
        .title {
          font-size: 32px;
          font-weight: 600;
          color: #363F5E;
          text-align: center;
          line-height: normal;
          margin-bottom: 40px;
        }
        .intro {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .userimg {
            position: absolute;
            right: 0;
            top: -183px;
            z-index: 2;
            width: 200px;
            height: 200px;
            transform: rotateY(180deg);
            background: url(../assets/lanch_cont11img.png) no-repeat 100%/100%;
          }
          .scan {
            display: none;
          }
          .scan1 {
            display: block;
            position: absolute;
            top: -125px;
            right: 110px;
            width: 140px;
            height: 250px;
            transform: rotateY(180deg);
            background: url(../assets/lanch_cont11img1.png) no-repeat 100%/100%;
            background-size: 140px 250px;
            &.active {
              transition:none;
              background-size: 140px 250px;
            }
          }
          .datalist {
            width: 600px;
            display: flex;
            flex-wrap: wrap;
            margin: auto;
            .datali {
              width: 100%;
              height: 80px;
              display: flex;
              .point {
                width: 20px;
                height: 20px;
                margin: 3px 10px 0 0;
                background: url(../assets/lanch_icon1.png) no-repeat 100%/100%;
              }
              .data {
                flex: 1;
                padding-right: 10px;
                box-sizing: border-box;
                .top {
                  color: #363F5E;
                  font-size: 18px;
                  font-weight: 600;
                  margin-bottom: 4px;
                  line-height: normal;
                }
                .text {
                  font-size: 16px;
                  line-height: normal;
                  color: rgba(54, 63, 94, 0.66);
                }
              }
            }
          }
        }
      }
      .cont12 {
        top: 11700px;
        .intro {
          position: relative;
          width: 600px;
          height: 600px;
          display: flex;
          margin: auto;
          align-items: center;
          border-radius: 20px;
          justify-content: space-around;
          background: linear-gradient(180deg, rgba(152, 217, 182, 0.18) 6.92%, rgba(200, 227, 213, 0.16) 100%);
          .echarDom {
            width: 600px;
            height: 600px;
          }
          .details {
            position: absolute;
            top: 600px;
            height: 0;
            width: 480px;
            height: 420px;
            overflow: hidden;
            padding: 24px 20px;
            line-height: normal;
            border-radius: 24px;
            box-sizing: border-box;
            backdrop-filter: blur(6px);
            background: rgba(255, 255, 255, 0.9);
            &.Total {
              display: none;
              text-align: center;
              padding: 24px 80px;
              .title {
                font-size: 20px;
                font-weight: 600;
                color: #363F5E;
                margin-bottom: 16px;
              }
              .block {
                text-align: center;
                font-size: 14px;
                margin-bottom: 24px;
                .text {
                  color: rgba(54, 63, 94, 0.50);
                }
                .desc {
                  color: #363F5E;
                }
              }
              
            }
            &.Single {
              .title {
                font-size: 20px;
                font-weight: 600;
                color: #363F5E;
                text-align: center;
                margin-bottom: 16px;
              }
              .dataList {
                .data_top {
                  display: flex;
                  font-size: 16px;
                  color: #363F5E;
                  align-items: center;
                  span {
                    width: 8px;
                    height: 8px;
                    margin-right: 8px;
                    border-radius: 100%;
                    display: inline-block;
                    background: #363F5E;
                  }
                }
                .text {
                  font-size: 14px;
                  color: rgba(54, 63, 94, 0.66);
                  span {
                    font-weight: 400;
                    color: rgba(54, 63, 94, 0.40);
                  }
                }
              }
            }
            &.font12 {
              padding: 12px 10px;
              .title {
                font-size: 16px;
                font-weight: 600;
                color: #363F5E;
                text-align: center;
                margin-bottom: 8px;
              }
              .dataList {
                margin-bottom: 5px;
                .data_top {
                  display: flex;
                  font-size: 14px;
                  color: #363F5E;
                  align-items: center;
                  span {
                    width: 8px;
                    height: 8px;
                    margin-right: 8px;
                    border-radius: 100%;
                    display: inline-block;
                    background: #363F5E;
                  }
                }
                .text {
                  font-size: 12px;
                  color: rgba(54, 63, 94, 0.66);
                  span {
                    font-weight: 400;
                    color: rgba(54, 63, 94, 0.40);
                  }
                }
              }
            }
          }
        }
      }
      .cont13 {
        top: 12360px;
        z-index: 6;
        width: 100%;
        .title {
          font-size: 32px;
          font-weight: 600;
          color: #363F5E;
          text-align: center;
          line-height: normal;
          margin-bottom: 40px;
        }
        .teamlist {
          width: 600px;
          margin: auto;
          display: flex;
          flex-wrap: wrap;
          .teamli {
            position: relative;
            width: 180px;
            height: 260px;
            overflow: hidden;
            border-radius: 24px;
            margin: 0 30px 24px 0;
            transition: all .3s ease-in-out;
            background: rgba(255, 255, 255, 0.60);
            &:hover {
              box-shadow: 0 0 4px 4px rgba(42, 178, 106, 0.50);
              -webkit-transform: translateY(-10px);
              -ms-transform: translateY(-10px);
              transform: translateY(-10px);
              .name {
                background: rgba(42, 178, 106, 0.50);
              }
            }
            &:nth-child(3n){
              margin-right: 0;
            }
            &:nth-child(4n){
              margin-right: 30px;
            }
            .userimg {
              width: 180px;
              height: 260px;
              img {
                width: 180px;
                min-height: 260px;
              }
            }
            .name {
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 50px;
              display: flex;
              color: #FFF;
              font-size: 16px;
              text-align: center;
              line-height: normal;
              align-items: center;
              justify-content: center;
              background: rgba(255, 255, 255, 0.6);
              text-shadow: 0px 2px 16px rgba(54, 63, 94, 0.6)
            }
          }
        }
        .detailsInfo {
          position: absolute;
          top: 350px;
          left: 0;
          right: 0;
          margin: auto;
          width: 600px;
          padding: 30px;
          text-align: center;
          background: #FFF;
          border-radius: 24px;
          line-height: normal;
          box-sizing: border-box;
          &.show {
            display: none;
          }
          &.hide {
            display: block;
          }
          &.top1 {
            top: 630px;
            z-index: 15;
          }
          &.top2 {
            top: 400px;
            z-index: 15;
          }
          .name {
            font-size: 20px;
            font-weight: 600;
            color: #363F5E;
            margin-bottom: 5px;
          }
          .synopsis {
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 15px;
            color: rgba(78, 89, 105, 0.80);
          }
          .text {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            text-align: center;
            color: rgba(78, 89, 105, 0.80);
          }
        }
      }
      .cont14 {
        // top: 7930px;
        z-index: 5;
        top: 13330px;
        position: relative;
        .title {
          font-size: 32px;
          font-weight: 600;
          color: #363F5E;
          text-align: center;
          line-height: normal;
          margin-bottom: 30px;
        }
        .organList {
          width: 600px;
          margin: auto;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-around;
          img {
            width: 240px;
            height: 100px;
            transition: all .3s ease-in-out;
            &:hover {
              -webkit-transform: translateY(-10px);
              -ms-transform: translateY(-10px);
              transform: translateY(-10px);
            }
          }
        }
        .organInfo {
          position: absolute;
          top: 360px;
          left: 0;
          right: 0;
          margin: auto;
          width: 600px;
          padding: 30px;
          font-size: 14px;
          background: #FFF;
          border-radius: 24px;
          line-height: normal;
          box-sizing: border-box;
          color: rgba(78, 89, 105, 0.80);
        }
      }
      .cont15 {
        top: 13660px;
        z-index: 1;
        .title {
          font-size: 32px;
          font-weight: 600;
          color: #363F5E;
          text-align: center;
          line-height: normal;
          margin-bottom: 30px;
        }
        .communityList {
          width: 600px;
          height: 300px;
          margin: auto;
          &.show {
            display: none;
          }
          &.hide {
            display: block;
            overflow: hidden;
          }
          .progress {
            position: relative;
            width: 300px;
            height: 6px;
            overflow: hidden;
            background: rgba(255, 255, 255, .5);
            border-radius: 4px;
            margin: -35px auto 0;
            .scrollBar {
              position: absolute;
              top: 0;
              left: 0;
              width: 50px;
              height: 6px;
              border-radius: 4px;
              background: #fff;
            }
          }
          .list {
            width: 100%;
            display: flex;
            padding: 0 10px;
            overflow-x: auto;
            overflow-y: hidden;
            align-items: center;
            box-sizing: border-box;
            .community {
              width: 380px;
              cursor: pointer;
              margin: 0 20px 50px 0;
              transition: none;
              &:hover {
                -webkit-transform: none;
                -ms-transform: none;
                transform: none;
              }
              &:nth-child(3n) {
                margin-right: 20px;
              }
              &:last-child {
                margin-right: 0;
              }
              .act_img {
                width: 380px;
                height: 220px;
                display: flex;
                line-height: 0;
                overflow: hidden;
                align-items: center;
                img {
                  width: 380px;
                  min-height: 220px;
                }
              }
              .act_name {
                width: 100%;
                height: 20px;
                font-size: 18px;
                font-weight: 500;
                overflow: hidden;
                margin: 10px 0 5px;
                white-space: nowrap;
                line-height: normal;
                box-sizing: border-box;
                text-overflow: ellipsis;
                color: rgba(54, 63, 94, 0.88);


              }
              .act_time {
                font-size: 14px;
                font-weight: 400;
                line-height: normal;
                box-sizing: border-box;
                color: rgba(54, 63, 94, 0.60);
              }
            }
          }
        }
      }
    }
  }
  .lan_En.Lanchpad {
    .content  {
      &.content1 {
        width: 100%;
        min-width: 600px;
        height: 14400px;
        overflow: hidden;
        background: url("../assets/lanchpadbg.png") no-repeat center;
        background-size: 100% 14400px;
      }
      .cont1 {
        .desc {
          margin: 0 auto 50px;
        }
      }
      .cont3 {
        .text_desc {
          .title {
            font-size: 24px;
            line-height: 1;
          }
          .text {
            font-size: 14px;
          }
        }
      }
      .cont5 {
        .title {
          font-size: 22px;
        }
        .intro {
          .left {
            .text1 {
              font-size: 16px;
              text-align: left;
            }
            .text2 {
              font-size: 14px;
              text-align: left;
            }
          }
          .right {
            right: 15px !important;
            .text1 {
              font-size: 16px;
              text-align: right;
            }
            .text2 {
              font-size: 14px;
              text-align: right;
            }
          }
        }
      }
      .cont6 {
        .title {
          font-size: 24px;
        }
        .intro {
          margin-top: 40px;
        }
      }
      .cont7 {
        top: 6970px;
        .title {
          font-size: 24px;
        }
        .desc {
          margin-bottom: 20px;
        }
      }
      .cont8 {
        .title {
          position: relative;
          z-index: 5;
        }
        .intro {
          .ele5 {
            top: 452px;
          }
          .ele6 {
            bottom: 250px;
          }
        }
      }
      .cont9 {
        .title {
          font-size: 30px;
        }
        .intro {
          .right {
            .topcont {
              .block {
                padding: 15px 10px;
                .text {
                  font-size: 16px;
                }
                .text1 {
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
      .cont10 {
        .intro {
          .line1 {
            height: 2515px;
          }
        }
      }
      .cont11 {
        top: 10950px;
        .intro {
          .userimg {
            height: 335px;
          }
          .scan.active {
            height: 380px;
            background-size: 234px 380px;
          }
          .datalist {
            .datali {
              height: 85px;
              .data {
                .top {
                  font-size: 20px;
                }
                .text {
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
      .cont12 {
        top: 11930px;
        .intro {
          .details{
            height: 300px;
            &.font12 {
              height: 480px;
            }
          }
        }
      }
      .cont13 {
        top: 12600px;
      }
      .cont14 {
        top: 13580px;
      }
      .cont15 {
        top: 13970px;
      }
    }
  }
}

</style>

